import React from "react";
import { Row, Placeholder, Col } from "react-bootstrap";
import "./loadingBody.scss";
import ContentCardLoading from "modules/website/components/ContentCard/ContentCardLoading/ContentCardLoading";

export default function LoadingBody() {
  return (
    <div>
      {/* <div className="d-flex justify-content-between p-0">
        <Placeholder xs={4} className="plHol" />
        <Placeholder xs={4} className="plHol" />
      </div> */}
      <Row className="bod">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9,10].map((val) => {
          return (
            <Col md={12} lg={6} key={val}>
              <ContentCardLoading key={val} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
