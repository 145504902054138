import axios from "axios";
import { refreshTokenAPI } from "services/api/authAPI";
const BASE_URL = "https://library.local.glifos.net/api";
export const FILE_BACK_URL = `${BASE_URL}/filesystem/v1/files/`;

export const glAuthAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: 2000,
});

export const glAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
  timeout: 2000,
});

// Request interceptor for API calls
glAxios.interceptors.request.use(
  async (config) => {
    // geting user token from localStorage
    let userToken = localStorage.getItem("gl-admin-accessTokenGlifos")
      ? localStorage.getItem("gl-admin-accessTokenGlifos")
      : null;
    // adding Bearer token if there is any
    if (userToken) {
      config.headers = {
        Authorization: `Bearer ${userToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for refresh token
glAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      localStorage.removeItem("gl-admin-accessTokenGlifos");
      if (originalRequest.url === "/sso/v1/auth/authenticate")
        return Promise.reject(error);
      if (originalRequest.url === "/sso/v1/auth/refresh") {
        localStorage.removeItem("gl-admin-refreshTokenGlifos");
        return Promise.reject(error);
      }
      originalRequest._retry = true;
      const access_token = await refreshTokenAPI();
      if (access_token) return glAxios(originalRequest);

      localStorage.removeItem("gl-admin-authInfo");
      window.location.href =
        "/gl/auth/login?returnTo=" +
        encodeURIComponent(window.location.pathname);
    }
    return Promise.reject(error);
  }
);

export const glAxiosRequest = async (method, endpoint, payload) => {
  try {
    const response = await glAxios[method](endpoint, payload);
    return { ...response.data, success: true };
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return {
      success: false,
      status: error?.response?.status,
      message: message,
    };
  }
};
