import React, { useState } from 'react'
import OrderBy from 'modules/website/components/Research/OrderBy/OrderBy'
import { useCategoryTreePage } from 'modules/website/hooks/useCategoryTreePage';
import Alert from 'modules/website/components/Alert/Alert';
import { Col, Row } from 'react-bootstrap';
import styles from './VideosView.module.scss'
import OptionCard from 'modules/website/components/Category/OptionCard/OptionCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
export default function VideosView() {
  const tid = 4;
  const [order,setOrder] = useState('sorttitle')
  const { isLoading, isError, payload, error } = useCategoryTreePage({
    category: 'category-' + tid,
    page: 1,
    tid: tid,
    sortby: order
  });
  //console.log(payload)
  return (
    <div>
      <div>
        <OrderBy 
          order = {order}
          setOrder={setOrder}
        />
      </div>
      {
        isLoading && <div>Loading ...</div>
      }
      {
        isError && <Alert variant="error">{error}</Alert>
      }
      {
        payload && !isLoading && !isError && (

          <Row className={`${styles.rowCards}`}>
            {payload?.solr_content?.records?.record?.map((option, index) => (
              <Col lg={4} md={12} key={index}>
                <OptionCard image={ApiURLs.baseURL +option.thumbnail} title={option.title} link={option.id} description={option.blurb}/>
              </Col>
            ))}
          </Row>
        )
      }
    </div>
  )
}
