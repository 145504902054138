import React from 'react'
import styles from './SuggestedNews.module.scss'
import { useLastNewsPage } from 'modules/website/hooks/useLastNewsPage';
import { Col, Row } from 'react-bootstrap';
import Alert from '../../Alert/Alert';
import SuggestedCard from './SuggestedCard/SuggestedCard';
import { formatDate, getFirstParagraph } from 'modules/website/utilities/utilities';
export default function SuggestedNews() {
    const { isLoading, isError, payload, error } = useLastNewsPage();
    //console.log(Object.values(payload));
    return (
        <div className={`${styles.MostR}`}>
            <div className={`${styles.title}`}>
                Suggested Stories
            </div>
            <div>
                {isLoading && ("Loading...")}
                {isError && (<Alert variant="danger">{error}</Alert>)}
                <Row>
                    {!isLoading && !isError && payload && (
                        Object.values(payload).map((item, index) => (
                            index === 1 ? (
                                <Col md={12} lg={6} key={index} className={`${styles.col}`}>
                                    <SuggestedCard title={item.title} date={formatDate(item.field_news_date)} description={getFirstParagraph(item.body)} nav={item.view_node.replace('/api/glifos','/news')} />
                                </Col>
                            ) : (
                                <Col md={12} lg={6} key={index} className={`${styles.nonFirst} ${styles.col}`}>
                                    <SuggestedCard title={item.title} date={formatDate(item.field_news_date)} description={getFirstParagraph(item.body)} nav={item.view_node.replace('/api/glifos','/news')} />
                                </Col>
                            )
                        ))
                    )}
                </Row>
            </div>
        </div>
    )
}
