import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CollectionBtn from './WelcomeMsg/CollectionBtn/CollectionBtn'
import IntVideoBtn from './IntVideoBtn/IntVideoBtn'
import SearchBar from './WelcomeMsg/SearchBar/SearchBar'
import HomeBg from 'assets/img/home-bg.png'
import styles from './MainPage.module.scss'
import WelcomeMsg from './WelcomeMsg/WelcomeMsg'
import NewsSlide from './NewsSlide/NewsSlide'
import Options from './Options/Options'
import InfoCarousel from './InfoCarousel/InfoCarousel'
import ContentCard from '../ContentCard/ContentCard'

export default function MainPage() {
    return (
        <div style={{ background: `url(${HomeBg}) no-repeat scroll center -53px / cover transparent`, boxShadow: '8px -200px 500px 238px black inset' }} className={`${styles.main}`}>
            <Row className={`${styles.welcome}`}>
                <Col md={12} lg={6}>
                    <WelcomeMsg />
                    <CollectionBtn />
                    <SearchBar placeholder="Search the Archive"/>
                </Col>
                <Col md={12} lg={6} className={`${styles.colNews}`}>
                    <NewsSlide/>
                </Col>
            </Row>
            <Row className={`${styles.videoRow}`}>
                <IntVideoBtn />
            </Row>
            <Row className={`${styles.options}`}>
                <Options />
            </Row>
            <Row>
                <InfoCarousel/>
            </Row>
        </div>
    )
}
