import ApiURLs from "services/BackendApi/BackendApiURLs";
import { glAuthAxios } from "services/glAxios/glAxios";

export const authencateAPI = async (payload) => {
  try {
    const response = await glAuthAxios.post(ApiURLs.baseURL + '/v1/website/authenticate', payload);
    return { success: true, data: response.data };
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return { success: false, message: message };
  }
};

export function saveAuthInformation(userInformation) {
  localStorage.setItem(
    "gl-admin-accessTokenGlifos",
    userInformation.access_token
  );
  localStorage.setItem(
    "gl-admin-refreshTokenGlifos",
    userInformation.refresh_token
  );
  localStorage.setItem("gl-admin-authInfo", JSON.stringify(userInformation));
}

export const refreshTokenAPI = async () => {
  try {
    let refreshToken = localStorage.getItem("gl-admin-refreshTokenGlifos")
      ? localStorage.getItem("gl-admin-refreshTokenGlifos")
      : null;
    const body = {
      refresh_token: refreshToken,
    };
    const response = await glAuthAxios.post("/sso/v1/auth/refresh", body);
    if (response.data?.access_token) {
      localStorage.setItem(
        "gl-admin-accessTokenGlifos",
        response.data.access_token
      );
      return true;
    }
  } catch (error) {
    console.log("Couldn't exchange refresh_token", error.message);
  }
};
