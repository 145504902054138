import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FolderFill, StarFill, Files, ChatLeft } from 'react-bootstrap-icons'
import Option from './Option/Option'

const Ops = [
    {
        icon: FolderFill,
        title: 'Testimonies',
        description: 'Browse through the testimonies given by survivors, perpetrators, rescuers and elders.',
        link: '/category/Testimonies'
    },
    {
        icon: StarFill,
        title: 'Featured',
        description: 'Explore  new  materials and suggested collection from our archive team.',
        link: '/Featured'
    },
    {
        icon: Files,
        title: 'Blog',
        description: 'Read about our experiences as we archive the genocide through the blog.',
        link: '/blog',
        redirect: true
    },
    {
        icon: ChatLeft,
        title: 'Get Involved',
        description: 'Learn more about the Genocide Archive of Rwanda and how you can contribute.',
        link: '/contact_us'
    }
]

export default function Options() {
  return (
        <Row>
            {Ops.map((op, i) => (
                <Col lg={3} md={6} s={12} key={i}>
                    <Option 
                    icon={op.icon} 
                    title={op.title} 
                    description={op.description} 
                    link={op.link} 
                    key={i} 
                    redirect={op.redirect}
                    />
                </Col>
            ))}
        </Row>
  )
}
