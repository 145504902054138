import React from 'react'
import styles from './TypeCard.module.scss'
import { useNavigate } from 'react-router-dom'
export default function TypeCard(props) {
  const { image, label, link } = props
  const navigate = useNavigate();
  return (
    <div className={`${styles.card}`} onClick={()=>navigate(link)}>
      <div className={`${styles.shadowEffect}`}></div>
      <img src={image} className={`${styles.image}`} alt='card' />
      <div className={`${styles.label}`}>{label}</div>
      <div className={`${styles.bar}`} />
    </div>
  )
}
