export const ComponentMap = [
        {
                "type": "rich_media_archive",
                "c": "modules/website/views/GlifosRichMediaView/GlifosRichMediaView",
                "l": "left",
        },
        {
                "type": "information",
                "c": "modules/website/views/InfromationView/InfromationView",
                "l": "central",
        },
        {
                "type": "glifos_digital_archive",
                "c": "modules/website/views/GlifosDigitalArchiveView/GlifosDigitalArchiveView",
                "l": "left",
        },
        {
                "type": "about_information",
                "c": "modules/website/views/SimpleAboutUsInfoView/SimpleAboutUsInfoView",
                "l": "central",
        },
        {
                "type": "glifos_photographs",
                "c": "modules/website/views/GlifosPhotographsView/GlifosPhotographsView",
                "l": "basic",
        },
        {
                "type": "glifos_memorial",
                "c": "modules/website/views/GlifosMemorialView/GlifosMemorialView",
                "l": "left",
        }
];