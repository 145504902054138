import React from "react";

function H1(props){
    return <h1>{props.children}</h1>;
}
function H2(props){
    return <h2>{props.children}</h2>;
}
function H3(props){
    return <h3>{props.children}</h3>;
}
function H4(props){
    return <h4>{props.children}</h4>;
}
function H5(props){
    return <h5>{props.children}</h5>;
}
function Override(props){
    return <div className={props.variant}>{props.children}</div>;
}

const HeadingVariants = {
    H1,
    H2,
    H3,
    H4,
    H5,
    Override
};

export default HeadingVariants;
