import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './ModalVideo.module.scss'

export default function ModalVideo(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`${styles.modal}`}
        >
            <Modal.Header closeButton  className={`${styles.header} btn-close-white`}/>
            <Modal.Body className={`${styles.body}`}>
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/UUAa4XntNn4"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className={`${styles.video}`}
                />
                <p className={`${styles.title}`}>The Genocide Archive of Rwanda</p>
                <p className={`${styles.description}`}>
                    The Genocide Archive of Rwanda website is managed by the Aegis Trust archive and documentation department in Rwanda.
                    The team is made up of dedicated and professional individuals in the fields of archiving and digital preservation.
                </p>
            </Modal.Body>
        </Modal>
    )
}
