import React from 'react'
import AboutBg from 'assets/img/AboutUs/about-bg.png'
import styles from './AboutUs.module.scss'
import { Button, Col, Row } from 'react-bootstrap'
import MessageCard from './MessageCard/MessageCard'
import JobOportunities from 'assets/img/AboutUs/Job-Oportunities.png'
import OurTeam from 'assets/img/AboutUs/Our-team.png'
import PressRoom from 'assets/img/AboutUs/Press-Room.png'
import WhatWeDo from 'assets/img/AboutUs/What-we-do.png'
import OptionCard from './OptionCard/OptionCard'
import AboutCarItem from './AboutCarItem/AboutCarItem'
import AboutAegis from 'assets/img/AboutUs/About-aegis.png'
import AboutGar from 'assets/img/AboutUs/About-gar.png'
import { ChevronRight } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
const options = [
    {
        image: WhatWeDo,
        title: 'WHAT WE DO',
        nav: '/what_we_do'
    },
    {
        image: PressRoom,
        title: 'PRESS ROOM',
        nav: '/Press_room'
    },
    {
        image: JobOportunities,
        title: 'JOB OPPORTUNITIES',
        nav: '/category/Job_Opportunities'
    },
   /*  {
        image: OurTeam,
        title: 'OUR TEAM',
    }, */
]

export default function AboutUs() {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const navigate = useNavigate();
    return (
        <>
            <div style={{ background: `url(${AboutBg}) no-repeat scroll center 0px / cover transparent` }} className={`${styles.container}`}>
                <div className={`${styles.title}`}>
                    ABOUT US
                </div>
                <div className={`${styles.content}`}>
                    <Row className={`${styles.main}`}>
                        <Col lg={4} md={12}>
                            <MessageCard title="History of the Aegis Trust archive and documentation" variant="red" link='/about_us/History_of_the_Aegis_trust_archive_and_documentation'>
                                The Genocide Archive of Rwanda was established by the archive and documentation department of the Aegis Trust Rwanda,
                                a non-governmental organisation that strives to prevent mass atrocity and genocide through education.
                            </MessageCard>
                            <MessageCard title="Genocide Archive of Rwanda" variant="orange" link='/about_us/Genocide_Archive_of_Rwanda'>
                                The Genocide Archive of Rwanda is the first of its kind in Rwanda. Its collections include  testimonies, audio and video materials,
                                photographs, physical objects, documents and publications as well as interactive mapping data.
                            </MessageCard>
                        </Col>
                        <Col lg={8} md={12}>
                            <Row>
                                {options.map((option, index) => (
                                    <Col xl={6} lg={12} md={6} s={12} key={index}>
                                        <OptionCard image={option.image} title={option.title} nav={option.nav} />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={`${styles.aboutItems}`}>
                <Row className={`${styles.main}`}>
                    <Col lg={4} md={12} style={{ backgroundColor: '#d55a00' }} className={`${styles.item}`}>
                    
                        <AboutCarItem title={<img src={`${AboutGar}`} alt='about'/>}>
                            The Kigali Genocide Memorial is the final resting place for 259,000 victims of the genocide against the Tutsi and is home to the archive's largest physical collection.
                        </AboutCarItem>
                        <Button className={`${styles.btn}`} onClick={() => openInNewTab('https://kgm.rw/')}>
                            <ChevronRight className={`${styles.icon}`} />
                            VISIT WEBSITE
                        </Button>
                    </Col>
                    <Col lg={4} md={12} style={{ backgroundColor: '#ff9200' }} className={`${styles.item}`}>
                        <AboutCarItem title={<img src={`${AboutAegis}`} alt='about'/>}>
                            Aegis Trust is an international organisation working to prevent genocide and mass atrocity and is the founder of the Genocide Archive of Rwanda.
                        </AboutCarItem>
                        <Button className={`${styles.btn}`} onClick={() => openInNewTab('https://www.aegistrust.org/')}>
                            <ChevronRight className={`${styles.icon}`} />
                            VISIT WEBSITE
                        </Button>
                    </Col>
                    <Col lg={4} md={12} style={{ backgroundColor: '#d55a00' }} className={`${styles.item}`}>
                        <AboutCarItem title="Partners & Donors">
                            The work of the archive is made possible by the generous support of our donors & partners.
                            Learn more about the organisations we work with and how they are preserving the memory of genocide around the world.
                        </AboutCarItem>
                        <Button className={`${styles.btn}`} onClick={() => navigate('/Our_Partners')}>
                            <ChevronRight className={`${styles.icon}`} />
                            VISIT PAGE
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
