import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import SideBarAudio from 'modules/glifos-rich-media/components/SideBarAudio/SideBarAudio';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo';
import Alert from 'modules/website/components/Alert/Alert';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import OrderBy from 'modules/website/components/Research/OrderBy/OrderBy';
import { useCategoryId, useCategoryTreePage } from 'modules/website/hooks/useCategoryTreePage';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './CategoryView.module.scss';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import SearchSquareForm from 'modules/glifos-rich-media/components/SearchSquareForm/SearchSquareForm';
import BrowseSquare from 'modules/website/components/BrowseSquare/BrowseSquare';
import OptionCard from 'modules/website/components/Category/OptionCard/OptionCard';
import Pagination from 'modules/website/components/Pagination/Pagination';
import LoadingBody from 'modules/website/components/loadingBody/loadingBody';

const secondStyleAlias = [
  {
    title: 'Genocide Memorials',
    alias: 'category/Memorials',
    noSideBar: true
  },
  {
    title: 'Unity and Reconciliation',
    alias: 'category/Unity_and_Reconciliation',
    noSideBar: true
  },
  {
    title: 'Unity and Reconciliation',
    alias: 'category/Unity_and_Reconciliation',
    noSideBar: true
  },
  {
    title: 'National Youth Council',
    alias: 'category/National_Youth_Council',
    noSideBar: false
  },
  {
    title: 'AERG',
    alias: 'category/AERG_programmes',
    noSideBar: false
  },
  {
    title: 'Peace and Love Proclaimers',
    alias: 'category/Peace_and_Love_Proclaimers',
    noSideBar: false
  },
  {
    title: 'Idebate',
    alias: 'category/Idebate',
    noSideBar: false
  },
  {
    title: 'Aegis Youth Department',
    alias: 'category/Aegis_Youth_Department',
    noSideBar: false
  },
  {
    title: 'GAERG',
    alias: 'category/GAERG',
    noSideBar: false
  },
  {
    title: 'Walk to Remember',
    alias: 'category/Walk_to_Remember',
    noSideBar: false
  }
]
export default function CategoryView() {
  const location = useLocation();
  const navigate = useNavigate();
  const alias = location.pathname.replace('/', '');
  //console.log(alias)
  //const { category } = useParams();
  //const tid = 8212;
  const [breadC, setBreadC] = useState(null);
  const [sideBar, setSideBar] = useState(null);
  const [order, setOrder] = useState('sorttitle')
  const [page, setPage] = useState(1);

  const { isLoading: isLoadingId, isError: isErrorId, payload: payloadId, error: errorId } = useCategoryId({
    //category: 'category-' + tid,
    alias: alias,
  });
  const { isLoading, isError, payload, error } = useCategoryTreePage({
    category: 'category-' + payloadId?.tid,
    page: 1,
    tid: payloadId?.tid,
    sortby: order,
    page: page,
  });

  const url = new URL(window.location.href);
	const pageUrl = url.searchParams.get('page');

	useEffect(() => {
		setPage(pageUrl);
	}, [pageUrl]);

  useEffect(() => {
    if (breadC && breadC.length > 1) { // Check if breadC exists and has at least 2 elements
      const secondElement = breadC[1];
      //console.log(breadC)
      if (secondElement && secondElement.title) {
        // Perform your action using secondElement.title here
        switch (secondElement.title) {
          // Handle different cases based on the value of secondElement.title
          case 'Videos':
            setSideBar(<SideBarVideo />)
            break;
          case 'Audio':
            // Action for case2
            setSideBar(<SideBarAudio />)
            break;
          // Add more cases as needed
          case 'Documents':
            // Action for case2
            setSideBar(<SideBarDocument />)
            break;
          case 'Testimonies':
            // Action for case2
            setSideBar(
              <div>
                <SearchSquareForm
                  title='Search Testimonies '
                  description='Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
                  bkcolor='#bc1000'
                  btncolor='#ff9200'
                />
                <BrowseSquare
                  title='Browse Testimonies'
                  options={[
                    {
                      title: 'Places',
                      link: '/category/Places',
                    },
                    {
                      title: 'Topics',
                      link: '/category/Topics',
                    },
                    {
                      title: 'Keywords',
                      link: '/category/Keywords',
                    },
                    {
                      title: 'Key Events',
                      link: '/category/Key_Events',
                    },
                    {
                      title: 'People',
                      link: '/category/People',
                    },
                    {
                      title: 'Country Time Periods',
                      link: '/category/Country_Time_Periods',
                    },
                  ]}
                />
              </div>
            )
            break;
          case secondStyleAlias.find(item => item.title === secondElement.title)?.title:
            if (secondStyleAlias.find(item => item.title === secondElement.title)?.noSideBar === false) {
              setSideBar(
                <div>
                  <SearchSquareForm
                    title='Search'
                    description='Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
                    bkcolor='#25455a'
                    btncolor='#ff9200'
                  />
                  <BrowseSquare
                    title='Browse'
                    options={[
                      {
                        title: 'Places',
                        link: '/category/Places',
                      },
                      {
                        title: 'Topics',
                        link: '/category/Topics',
                      },
                      {
                        title: 'Keywords',
                        link: '/category/Keywords',
                      },
                      {
                        title: 'Key Events',
                        link: '/category/Key_Events',
                      },
                      {
                        title: 'People',
                        link: '/category/People',
                      },
                      {
                        title: 'Country Time Periods',
                        link: '/category/Country_Time_Periods',
                      },
                    ]}
                  />
                </div>
              )
            }
            break;
          default:
            // Default action
            setSideBar(
              <div>
                <SearchSquareForm
                  title='Search'
                  description='Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
                  bkcolor='#25455a'
                  btncolor='#ff9200'
                />
                <BrowseSquare
                  title='Browse'
                  options={[
                    {
                      title: 'Places',
                      link: '/category/Places',
                    },
                    {
                      title: 'Topics',
                      link: '/category/Topics',
                    },
                    {
                      title: 'Keywords',
                      link: '/category/Keywords',
                    },
                    {
                      title: 'Key Events',
                      link: '/category/Key_Events',
                    },
                    {
                      title: 'People',
                      link: '/category/People',
                    },
                    {
                      title: 'Country Time Periods',
                      link: '/category/Country_Time_Periods',
                    },
                  ]}
                />
              </div>
            )
            break;
        }
      }
    }
  }, [breadC]);

  useEffect(() => {
    let bread = [
      {
        title: 'Collections',
        path: '/collections',
      }
    ];
    if (payload) {
      payload.parent_category.map((item) => {
        bread.push({
          title: item.name,
          path: item.tid,
        });
      });
      bread.push({
        title: payload.title,
        path: '',
      });

    }
    setBreadC(bread);
  }, [payload])

  if (isLoading || isLoadingId) return (
    <BasicLayout>

    </BasicLayout>
  )

  //console.log(isErrorId)
  //console.log(isError)

  if (isErrorId) {
    navigate('/404')
  }
  if (isError) {
    navigate('/404')
  }

  if (!isLoading && !isError && payload && breadC && !isLoadingId && !isErrorId && payloadId) {
    return (
      <SideBarLayout
        title={payload.title}
        breadC={breadC}
        description={payload.description}
        leftBar={sideBar}
        broCategory={payload?.parent_category[payload?.parent_category.length - 1]?.id}
      >
        {isLoading && <LoadingBody/>}
        {!isLoading && isError && <Alert variant='danger'>{error}</Alert>}
        {!isLoading && !isError && payload && (
          <div>
            <div>
              <OrderBy
                order={order}
                setOrder={setOrder}
              />
              <Pagination result_header={payload?.solr_content?.result_header} justify='end'/>
            </div>
            <Row className={`${styles.rowCards}`}>

              {/* {payload?.solr_content?.records?.record?.map((option, index) => (
                  <Col lg={4} md={12} key={index}>
                    <ContentCard title={option.title} link={option.id} description={option.blurb} image={option.image} />
                  </Col>
                ))} */}
              {payload?.solr_content?.records?.record &&
                (Array.isArray(
                  payload?.solr_content?.records?.record
                ) ? (
                  payload?.solr_content?.records?.record.length > 0 ? (
                    payload?.solr_content?.records?.record?.map(
                      (item, index) => {
                        return (
                          secondStyleAlias.find(item => item.alias === alias) ? (
                            <Col
                              key={index}
                              md={12}
                              lg={4}
                              xl={4}
                              className='mb-4'
                            >
                              {/* <ContentCard
                            key={index}
                            title={item.title}
                            image={
                              ApiURLs.baseURL +
                              item.thumbnail
                            }
                            description={item.blurb}
                            nav={item.id}
                            searchable={item.searchable}
                          /> */}
                              <OptionCard
                                image={
                                  ApiURLs.baseURL +
                                  item.thumbnail
                                }
                                title={item.title}
                                link={item.id}
                                description={item.blurb} />
                            </Col>
                          ) : (

                            <Col
                              key={index}
                              md={12}
                              lg={6}
                              xl={6}
                              className='mb-4'
                            >
                              <ContentCard
                                key={index}
                                title={item.title}
                                image={
                                  ApiURLs.baseURL +
                                  item.thumbnail
                                }
                                description={item.blurb}
                                nav={item.id}
                                searchable={item.searchable}
                              />
                            </Col>
                          ))
                      }
                    )
                  ) : (
                    <div className={`${styles.noFound}`}>
                      No results were found
                    </div>
                  )

                ) : (
                  <Col md={12} lg={4} className='mb-4'>
                    <ContentCard
                      title={
                        payload?.solr_content?.records
                          ?.record.title
                      }
                      image={
                        ApiURLs.baseURL +
                        payload?.solr_content?.records
                          ?.record.thumbnail
                      }
                      description={
                        payload?.solr_content?.records
                          ?.record.blurb
                      }
                      nav={
                        payload?.solr_content?.records
                          ?.record.id
                      }
                    />
                  </Col>
                ))}
            </Row>
            <Pagination result_header={payload?.solr_content?.result_header} justify='start'/>
          </div>

        )}
      </SideBarLayout>
    )
  }
}
