import React from 'react';
import { Pinterest } from 'react-bootstrap-icons';
import { Instagram } from 'react-bootstrap-icons';
import { Twitter } from 'react-bootstrap-icons';
import { Youtube } from 'react-bootstrap-icons';
import { Facebook } from 'react-bootstrap-icons';

import styles from './SocialMedia.module.scss';

export default function SocialMedia() {
	return (
		<div className='d-flex flex-row flex-wrap gap-5 justify-content-center'>
			<a
				href='https://www.facebook.com/genocidearchiverwanda'
				target='_blank'
				rel='noreferrer noopener'>
				<Facebook size={16} className={`${styles.icon} text-muted`} />
			</a>
			<a
				href='https://www.youtube.com/user/GenocideArchiveRwand'
				target='_blank'
				rel='noreferrer noopener'>
				<Youtube size={16} className={`${styles.icon} text-muted`} />
			</a>
			<a
				href='https://twitter.com/GenArchiveRw'
				target='_blank'
				rel='noreferrer noopener'>
				<Twitter size={16} className={`${styles.icon} text-muted`} />
			</a>
			<a
				href='https://www.pinterest.com/genocidearchive/'
				target='_blank'
				rel='noreferrer noopener'>
				<Pinterest size={16} className={`${styles.icon} text-muted`} />
			</a>
			<a
				href='https://www.instagram.com/genocidearchiverwanda/'
				target='_blank'
				rel='noreferrer noopener'>
				<Instagram size={16} className={`${styles.icon} text-muted`} />
			</a>
		</div>
	);
}
