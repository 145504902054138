import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '350px'
};

export default function MapItext(props) {
    const { item } = props;

    const center = {
        lat: item[0].lat,
        lng: item[0].lng
    };

    const zoom = item[0].zoom;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {
                item.map((location, index) => {
                    return (
                        <Marker key={index} position={{ lat: location.lat, lng: location.lng }} onClick={() => console.log("Lugar")} />
                    )
                })
            }

            <></>
        </GoogleMap>
    ) : <></>


}

