import { configureStore } from "@reduxjs/toolkit";
import authReducer from "services/redux/auth/authSlice";
import stationReducer from "services/redux/station/stationSlice.js";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    station: stationReducer,
  },
});
