import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useVideoInfoPage(props) {
    const { alias, stylesheet } = props;
    const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetVideoInfo({
				urlParams: {
					alias: alias,
					stylesheet: stylesheet,
				},
			})
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						metadata: response.data.metadatas,
                        itext: response.data.itext,
						breadC: response.data.breadC,
						video: response.data.video,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [alias]);

	return pageContent;
}