import { useState } from 'react';

import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { SearchIcon } from '@react-pdf-viewer/search';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import CustomToolbar from './CustomToolbar/CustomToolbar';

import styles from './GLIFOSPdfViewer.module.scss';
import Loader from './Loader/Loader';
import SearchPlugin from './SearchPlugin/SearchPlugin';

const WORKER_URL =
	'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

export function GLIFOSPDFViewer({ fileUrl }) {
	const [theme, setTheme] = useState('dark');
	const handleSwitchTheme = (new_theme) => {
		setTheme(new_theme);
	};
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		sidebarTabs: (defaultTabs) => [
			{
				content: (
					<SearchPlugin
						searchPluginInstance={
							defaultLayoutPluginInstance.toolbarPluginInstance
								.searchPluginInstance
						}
						theme={theme}
					/>
				),
				icon: <SearchIcon />,
				title: 'Search',
			},
			defaultTabs[0],
		],
		renderToolbar: CustomToolbar,
		toolbarPlugin: {
			zoomPlugin: {
				enableShortcuts: true,
			},
		},
	});
	return (
		<Worker workerUrl={WORKER_URL}>
			<div className={`w-100 ${styles.cnt_viewer}`}>
				<Viewer
					onSwitchTheme={handleSwitchTheme}
					fileUrl={fileUrl}
					renderLoader={Loader}
					plugins={[defaultLayoutPluginInstance]}
					defaultScale={1}
					renderError={ErrorMessage}
					theme={{
						theme: 'dark',
					}}
				/>
			</div>
		</Worker>
	);
}
