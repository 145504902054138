import React from 'react'
import { Placeholder } from 'react-bootstrap'
import SocialMedia from '../../SocialMedia/SocialMedia'
import styles from './NewCardLoading.module.scss'
export default function NewCardLoading() {
    return (
        <div className={`${styles.card}`}>
            <div className={`${styles.date}`}>
                <Placeholder animation="glow">
                    <Placeholder xs={7} />
                </Placeholder>
            </div>
            <div className={`${styles.title}`}>
                <Placeholder animation="glow" >
                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                    <Placeholder xs={6} /> <Placeholder xs={8} />
                </Placeholder>
            </div>
            {/* <Button className={`${styles.btn}`}>
                <ChevronRight className={`${styles.icon}`} />
                READ MORE
            </Button> */}
            <Placeholder.Button aria-hidden="true" className={`${styles.btn}`} />
            <div className={`${styles.social}`}>
                <SocialMedia />
            </div>
        </div>
    )
}
