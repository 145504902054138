import ArticleLoading from 'modules/website/components/Article/ArticleLoading/ArticleLoading';
import { useInformationPage } from 'modules/website/hooks/useInformationPage';
import React from 'react'
import { Container } from 'react-bootstrap';
import Article from 'modules/website/components/Article/Article';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFoundView from '../NotFoundView/NotFoundView';

export default function InfromationView() {
    const location = useLocation();
    const alias = location.pathname.replace('/', '');
    const { isLoading, isError, payload, error } = useInformationPage({
        alias: alias,
    });
    const navigate = useNavigate();
    return (
        <>
            {isLoading &&
                <Container>
                    <ArticleLoading />
                </Container>
            }
            {!isLoading && isError && error && (<NotFoundView/>)}
            <Container>
                {!isLoading && !isError && payload && (
                    <Article title={payload.title} mediaSharing>
                        <div dangerouslySetInnerHTML={{ __html: payload.body }} />
                    </Article>
                )}
            </Container>
        </>
    )
}
