import React from 'react'
import styles from './MoreNewCard.module.scss'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default function MoreNewCard(props) {
    const { image, title,link } = props;
    {/* <Card className={`${styles.card}`}>
            <div className={`${styles.top}`}>
                <Card.Img variant="top" src={image} className={`${styles.imagess}`} />
            </div>
            <Card.Body className={`${styles.body}`}>
                <Card.Title className={`${styles.title}`}>{title}</Card.Title>
            </Card.Body>
        </Card> */}
    return (
        <Link className={`${styles.card}`} to={link}
            style={{textDecoration:'none',color:'white'}}
        >
            <div className={`${styles.imagess}`} style={{backgroundImage: `url(${image}`}}/>
            <div className={`${styles.title}`}>
            {title}
            </div>
        </Link>
        
    )
}
