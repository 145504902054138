import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../Photographs/ExtraStyle.scss'
import styles from './ChildCategoryListLoading.module.scss'
import { Placeholder } from 'react-bootstrap'
SwiperCore.use([Navigation, Mousewheel, Keyboard, Autoplay]);

export default function ChildCategoryListLoading() {
    return (
        <div>
            <Swiper
                spaceBetween={2}
                slidesPerView={'auto'}
                navigation
                pagination={{ clickable: true }}
                className={`${styles.swiper}`}>
                    <SwiperSlide className={`${styles.slide}`} 
                    >
                        <div
                            /* style={{
                                backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                            }} */
                            className={`d-flex justify-content-center`}
                        >
                            {/* <Link to={item.id}
                                        className={`${styles.categorie}`}
                                        style={{
                                            textDecoration: "none", color: "white",
                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                        }}
                                    >
                                        <ChevronRight className={`${styles.icon}`} />
                                        <div className={`${styles.title}`}>
                                            {item.title}
                                        </div>
                                    </Link> */}
                            <Placeholder animation="glow" style={{width: '100%'}}>
                                <Placeholder xs={12} className={`${styles.categorie}`}/>
                            </Placeholder>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.slide}`} 
                    >
                        <div
                            /* style={{
                                backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                            }} */
                            className={`d-flex justify-content-center`}
                        >
                            {/* <Link to={item.id}
                                        className={`${styles.categorie}`}
                                        style={{
                                            textDecoration: "none", color: "white",
                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                        }}
                                    >
                                        <ChevronRight className={`${styles.icon}`} />
                                        <div className={`${styles.title}`}>
                                            {item.title}
                                        </div>
                                    </Link> */}
                            <Placeholder animation="glow" style={{width: '100%'}}>
                                <Placeholder xs={12} className={`${styles.categorie}`}/>
                            </Placeholder>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.slide}`} 
                    >
                        <div
                            /* style={{
                                backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                            }} */
                            className={`d-flex justify-content-center`}
                        >
                            {/* <Link to={item.id}
                                        className={`${styles.categorie}`}
                                        style={{
                                            textDecoration: "none", color: "white",
                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                        }}
                                    >
                                        <ChevronRight className={`${styles.icon}`} />
                                        <div className={`${styles.title}`}>
                                            {item.title}
                                        </div>
                                    </Link> */}
                            <Placeholder animation="glow" style={{width: '100%'}}>
                                <Placeholder xs={12} className={`${styles.categorie}`}/>
                            </Placeholder>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.slide}`} 
                    >
                        <div
                            /* style={{
                                backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                            }} */
                            className={`d-flex justify-content-center`}
                        >
                            {/* <Link to={item.id}
                                        className={`${styles.categorie}`}
                                        style={{
                                            textDecoration: "none", color: "white",
                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                        }}
                                    >
                                        <ChevronRight className={`${styles.icon}`} />
                                        <div className={`${styles.title}`}>
                                            {item.title}
                                        </div>
                                    </Link> */}
                            <Placeholder animation="glow" style={{width: '100%'}}>
                                <Placeholder xs={12} className={`${styles.categorie}`}/>
                            </Placeholder>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={`${styles.slide}`} 
                    >
                        <div
                            /* style={{
                                backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                            }} */
                            className={`d-flex justify-content-center`}
                        >
                            {/* <Link to={item.id}
                                        className={`${styles.categorie}`}
                                        style={{
                                            textDecoration: "none", color: "white",
                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                        }}
                                    >
                                        <ChevronRight className={`${styles.icon}`} />
                                        <div className={`${styles.title}`}>
                                            {item.title}
                                        </div>
                                    </Link> */}
                            <Placeholder animation="glow" style={{width: '100%'}}>
                                <Placeholder xs={12} className={`${styles.categorie}`}/>
                            </Placeholder>
                        </div>
                    </SwiperSlide>
            </Swiper>

        </div>
    )
}
