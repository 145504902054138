import React from 'react'
import { Accordion, Card, Col, Row, useAccordionButton } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './AccordionOption.module.scss'

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log(eventKey),
    );

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className={`${styles.btn}`}
        >
            {children}
        </button>
    );
}

export default function AccordionOption(props) {
    const { options } = props
    return (
        <Row>
            {options.map((option, index) => {
                return (
                    <Col lg={6} md={12} key={index}>
                        <Accordion defaultActiveKey="1" className={`${styles.accordion}`}>
                            <Card className={`${styles.item}`}>
                                <Card.Header className={`${styles.header}`}>
                                    <CustomToggle eventKey="0">
                                        <ChevronRight />
                                        {option.title}
                                    </CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className={`${styles.collapse}`}>
                                    <Card.Body className={`${styles.body}`}>{option.description}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                )
            })}
        </Row>
    )
}
