import React, { useState } from 'react';
import { Navbar as NavbarBootstrap, Nav, Container, NavDropdown, Button } from 'react-bootstrap';
import logo from 'assets/img/GAR_logo.png';
import { Link, useNavigate } from 'react-router-dom';
import DropMenu from './DropMenu/DropMenu';
import styles from './Navbar.module.scss';
import './ExtraStyle/dropStyle.scss';
import SearchBar from './SearchBar/SearchBar';
import { ChevronRight } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { singout } from 'services/redux/auth/authActions';
export default function Navbar(props) {
	const navigate = useNavigate()
	const [seletedDropdown, setSelectedDropdown] = useState(null);
	const dispatch = useDispatch();
	const handleLogOut = async (e) => {
		e.preventDefault(); // Evita el comportamiento por defecto
		await dispatch(singout());
		// Si quieres redirigir después de logout, puedes hacerlo aquí usando history o un hook de navegación
	};
	const auth = useSelector((state) => state.auth);
	const isTokenExpired = !auth?.refresh_token_expires_on || new Date() > new Date(auth?.refresh_token_expires_on);
	return (
		<NavbarBootstrap bg='light' expand='lg' onMouseLeave={() => setSelectedDropdown(null)}>
			<Container fluid>
				<NavbarBootstrap.Brand onClick={() => navigate('/')}
					onMouseEnter={() =>
						setSelectedDropdown(null)
					}
					className={`${styles.brand}`}
				>
					<img
						src={logo}
						height='70'
						className='d-inline-block align-top'
						alt='React Bootstrap logo'
					/>
				</NavbarBootstrap.Brand>
				<NavbarBootstrap.Toggle
					aria-controls='basic-navbar-nav'
					className='ms-auto'
				/>
				<div className='d-flex flex-column w-100'>
					<div className={`d-flex justify-content-end ${styles.logbtns}`}>
						{/* <Link className={`${styles.login}`} to={`/login?returnTo=${window.location.pathname}`}>
							<ChevronRight className={`${styles.icon}`} />
							Login
						</Link>
						
						<div className={`${styles.login}`} to='/login' onClick={handleLogOut}>
							<ChevronRight className={`${styles.icon}`} />
							Log out
						</div> */}
						{isTokenExpired ? (
							// Si el token no existe o ha expirado, mostrar botones de Login y Register
							<>
							<Link className={`${styles.register}`} to='/register'>
								<ChevronRight className={`${styles.icon}`} />
								Register
							</Link>
							<Link className={`${styles.login}`} to={`/login?returnTo=${window.location.pathname}`}>
								<ChevronRight className={`${styles.icon}`} />
								Login
							</Link>
							</>
						) : (
							// Si el token es válido, mostrar el botón de Log out
							<Link className={`${styles.login}`} to={`/logout`}>
								<ChevronRight className={`${styles.icon}`} />
								Logout
							</Link>
						)}
					</div>
					<NavbarBootstrap.Collapse id='basic-navbar-nav'>
						<SearchBar />

						<Nav className='ms-auto my-2 my-lg-0'>
							<Nav.Link onClick={() => navigate('/')}
								onMouseEnter={() =>
									setSelectedDropdown(null)
								}>
								HOME
							</Nav.Link>
							<NavDropdown
								title="ABOUT US"
								id="basic-nav-dropdown-about-us"
								show={seletedDropdown === 'about_us'}
								onMouseEnter={() =>
									setSelectedDropdown('about_us')
								}
								onToggle={() => navigate('/about_us')}
								className={`${styles.navdd}`}>
								<DropMenu setSelectedDropdown={setSelectedDropdown} seletedDropdown={seletedDropdown} />
							</NavDropdown>
							<NavDropdown
								title="COLLECTIONS"
								id="basic-nav-dropdown"
								show={seletedDropdown === 'collections'}
								onMouseEnter={() =>
									setSelectedDropdown('collections')
								}
								onToggle={() => navigate('/collections')}
								className={`${styles.navdd}`}>
								<DropMenu setSelectedDropdown={setSelectedDropdown} seletedDropdown={seletedDropdown} />
							</NavDropdown>
							<NavDropdown
								title="RESEARCH"
								id="basic-nav-dropdown"
								show={seletedDropdown === 'research'}
								onMouseEnter={() =>
									setSelectedDropdown('research')
								}
								onToggle={() => navigate('/research')}
								className={`${styles.navdd}`}>
								<DropMenu setSelectedDropdown={setSelectedDropdown} seletedDropdown={seletedDropdown} />
							</NavDropdown>
							<Nav.Link onClick={() => navigate('/contact_us')}
								onMouseEnter={() =>
									setSelectedDropdown(null)
								}>
								GET INVOLVED
							</Nav.Link>
							<Nav.Link onClick={() => navigate('/login')}
								onMouseEnter={() =>
									setSelectedDropdown(null)
								}
								className={`${styles.loginop}`}
							>
								LOG IN
							</Nav.Link>
							<Nav.Link onClick={() => navigate('/register')}
								onMouseEnter={() =>
									setSelectedDropdown(null)
								}
								className={`${styles.loginop}`}
							>
								REGISTER
							</Nav.Link>
						</Nav>
					</NavbarBootstrap.Collapse>
				</div>

			</Container>
		</NavbarBootstrap>
	);
}
