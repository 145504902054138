import React from 'react'
import styles from './NotFound.module.scss'
import BecomeAfilliate from 'assets/img/Research/become-affiliate.jpg'

export default function NotFound() {
    return (
        <div className={`${styles.notfound} d-flex flex-column justify-content-center`} style={{ background: `url(${BecomeAfilliate}) no-repeat scroll center -75px / cover transparent` }}>
            <div className={`${styles.content}`}>
                <div className={`${styles.m404}`}>
                    404
                </div>
                <div className={`${styles.message}`}>
                    Oops! The page you are looking for does not exist.
                </div>
            </div>
        </div>
    )
}
