import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { singout } from "services/redux/auth/authActions";
import CheckMarkSuccess from "common/components/AnimatedIcons/CheckMarkSuccess/CheckMarkSuccess";
import { Link } from "react-router-dom";
import styles from "./Logout.module.scss";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singout());
  }, [dispatch]);

  return (
    <>
      <CheckMarkSuccess />
      <div id="mainContent">
        <p className="text-center mt-3">
          <b><h2>You are now logged out.</h2></b>
        </p>
        <p className="text-center">
          You can continue to use Genocide Archive Rwanda anonymously, or you can <Link to="/login" className={`${styles.link}`}>log in again</Link> as the same or as a different user.
          Note that some pages may continue to be displayed as if you were still logged in, until you clear your browser cache.
        </p>
        <p id="mw-returnto" className="text-center">Return to <Link className={`${styles.link}`}to="/" title="Welcome to Genocide Archive Rwanda">Home Page</Link>.</p>
        
      </div>
    </>
  );
}
