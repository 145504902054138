import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import ImageCardOption from './ImageCardOption/ImageCardOption'
import MenuOptions from './MenuOptions/MenuOptions'
import styles from './NavDropMenu.module.scss'
import { Link, useNavigate } from 'react-router-dom'

export default function NavDropMenu(props) {
    const { imageCards, menuOptions, extraOption } = props
    const navigate = useNavigate();
    return (
        <div className={`${styles.menu}`}>
            <Row className={`${styles.top}`}>
                <Col lg={7} md={12} className={`${styles.imageOptions}`} style={{ borderRight: menuOptions && ('1px solid gray') }}>
                    {
                        imageCards.map((imageCard, index) => (
                            <ImageCardOption key={index} {...imageCard} />
                        ))
                    }
                </Col>
                {menuOptions && (
                    <Col lg={5} md={12} className={`${styles.menuOptions}`}>
                        <Row>
                            {
                                menuOptions.map((menuOption, index) => (
                                    <MenuOptions key={index} {...menuOption} />
                                ))
                            }
                        </Row>
                    </Col>
                )}
            </Row>
            {extraOption && (
                <Row className={`${styles.extraOption}`}>
                    <Link to={extraOption.link} className={`${styles.btn}`}>
                        <ChevronRight className={`${styles.icon}`} />
                        {extraOption.text}
                    </Link>
                </Row>
            )}
        </div>
    )
}
