import OptionCard from 'modules/website/components/Category/OptionCard/OptionCard'
import ContentCard from 'modules/website/components/ContentCard/ContentCard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ApiURLs from 'services/BackendApi/BackendApiURLs'
import styles from './PressRoomView.module.scss'

export default function PressRoomView() {

  const news = [
    {
      title: 'Youth In Peace Building',
      description: 'Aegis Trust has established the Rwanda Peace Education Programme to build peace and prevent future atrocities. The programme is helping to rebuild a...',
      image: '/glifos/sites/default/files/2024-07/3_1.jpeg?itok=FKKYh1HX',
      id: '/news/Rwandas_Young_People_Build_Peace_Together'
    },
    {
      title: 'Belgian Ministers visit KGM  ',
      description: 'Belgian Deputy Prime Minister and Minister of Foreign Affairs, Didier Reynders, and Minister for Development Cooperation Alexander De Croo, visited...',
      image: '/glifos/sites/default/files/2024-07/thumb_b.jpeg?itok=7IYjLTsA',
      id: '/news/Belgian_Ministers_visit_KGM'
    },
    {
      title: 'Memorials Virtual Tours',
      description: 'The memory of the 1994 genocide against the Tutsi is kept alive at memorials across Rwanda. They are dignified resting...',
      image: '/glifos/sites/default/files/2024-07/thumb_c.png?itok=wjcOaRyC',
      id: '/news/Virtual_tours_give_online_access'
    },
    {
      title: 'The Genocide Archive of Rwanda...  ',
      description: 'To mark the twentieth commemoration of the Genocide against the Tutsi, Aegis Trust and its partners have expanded the Kigali Genocide Memorial. The...',
      image: '/glifos/sites/default/files/2024-07/thumb1.jpeg?itok=S7IeVRn9',
      id: '/news/Global_Centre_for_Humanity_opens_at_the_Kigali_Genocide_Memorial'
    }
  ]

  return (
    <div>
      <Row className={`${styles.rowCards}`}>
        {
          news.map(
            (item, index) => {
              return (

                <Col
                  key={index}
                  md={12}
                  lg={6}
                  xl={6}
                  className='mb-4'
                >
                  <ContentCard
                    key={index}
                    title={item.title}
                    image={
                      ApiURLs.baseURL +
                      item.image
                    }
                    description={item.description}
                    nav={item.id}
                    searchable={item.searchable}
                  />
                </Col>
              )
            }
          )
        }
      </Row>
    </div>
  )
}
