import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CollectionCard from './CollectionCard/CollectionCard'
import styles from './CollectionCards.module.scss'
import AudiovisualTestimonies from 'assets/img/Collections/audiovisual-testimonies.png'
import InteractiveMaps from 'assets/img/Collections/interactive-maps.png'
import PostGenocide from 'assets/img/Collections/post-genocide.png'
const Cards = [
    {
        title: 'TESTIMONIES',
        btn: 'WATCH TESTIMONIES',
        image: AudiovisualTestimonies,
        description: 'Watch testimonies about the 1994 genocide against the Tutsi given by survivors, rescuers, perpetrators and elders.',
        link: "/category/testimonies"
    },
    {
        title: 'MAPPING GENOCIDE',
        btn: 'SEARCH MAPS',
        image: InteractiveMaps,
        description: 'Explore interactive maps that detail the 1994 genocide against the Tutsi. You can use these maps to locate and learn about killing sites, mass graves, roadblocks and take a virtual tour of 22 memorials sites across Rwanda.',
        link:"/Interactive_Maps"
    },
    {
        title: 'POST-GENOCIDE RECONSTRUCTION',
        btn: 'LEARN MORE',
        image: PostGenocide,
        description: 'Learn about the programmes, projects and policies that have been implemented to foster reconciliation and recovery in Rwanda.',
        link: "/Post_Genocide"
    },
]

export default function CollectionCards() {

    return (
        <Row>
            {Cards.map((card, index) => {
                return (
                    <Col xl={4} lg={6} md={12} key={index} className={`${styles.column}`}>
                        <CollectionCard title={card.title} btn={card.btn} image={card.image} url={card.link}>
                            {card.description}
                        </CollectionCard>
                    </Col>
                )
            })}

        </Row>
    )
}
