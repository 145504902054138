import React from 'react'
import { Card } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './OptionCard.module.scss'
import { useNavigate } from 'react-router-dom';
export default function OptionCard(props) {
    const { image, title, children, link } = props;
    const navigate = useNavigate();
    return (
        <Card className={`${styles.card}`}>
            <div className={`${styles.top}`} onClick={()=>navigate(link)}>
                <Card.Img variant="top" src={image} className={`${styles.imagess}`} />
                <ChevronRight className={`${styles.icon}`} />
                <div className={`${styles.srDescEffect}`}/>
                <Card.Title className={`${styles.title}`}>{title}</Card.Title>
            </div>
            <Card.Body className={`${styles.body}`}>
                <Card.Text className={`${styles.description}`}>
                    {children}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
