import { useNodePage } from 'modules/website/hooks/useNodePage';
import React from 'react'
import { useLocation } from 'react-router-dom';
import NotFoundView from '../NotFoundView/NotFoundView';
import Alert from 'modules/user/components/Alert/Alert';
import { formatDate } from 'modules/website/utilities/utilities';
import styles from './NewView.module.scss';
import ShareButton from 'modules/website/components/New/ShareButton/ShareButton';
import NewLoading from 'modules/website/components/New/NewLoading/NewLoading';
import { NewTop } from 'routes/TopPart';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import SidebarNew from 'modules/website/components/New/SideBarNew/SidebarNew';
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
export default function NewView() {
    const location = useLocation();
    const nid = location.pathname.replace('/news/', '');
    /* const { isLoading, isError, payload, error } = useNodePage({
        nid: nid,
    }); */
    const { isLoading, isError, payload, error } = useAliasNodePage({
        alias: nid,
    });

    //TODO: Agregar el titulo de la noticia al breadcrumb
    /* if (payload !== null) {
        NewTop.breadC[2].title = payload.title[0].value
    } */
    if (isLoading) return <NewLoading />
    if (isError && !isLoading) return <Alert variant='danger'>{error}</Alert>
    if (!isLoading && !isError && payload) {
        return (
            <SideBarLayout
                title={NewTop['title']}
                rightBar={<SidebarNew />}
                breadC={[
                    {
                        title: 'Home',
                        path: '/',
                    },
                    {
                        title: 'News',
                        path: '/news',
                    },
                    {
                        title: payload.title[0].value,
                        path: '',
                    },
                ]}
            >
                <div className={`${styles.new}`}>
                    {isLoading && <NewLoading />}
                    {!isLoading && isError && <Alert variant='danger'>{error}</Alert>}
                    {!isLoading && !isError && payload && (
                        <div className={`${styles.content}`}>
                            <div className={`${styles.title}`}>{payload.title[0].value}</div>
                            <div className={`${styles.date}`}>
                                {formatDate(payload.field_news_date[0].value)}
                            </div>
                            <div className={`${styles.image}`}>
                                {
                                    payload.field_news_image[0]?.url && (
                                        <img src={payload.field_news_image[0].url} alt={payload.title[0].value} />
                                    )
                                }
                            </div>
                            <div className={`${styles.share}`}>
                                <ShareButton />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: payload.body[0].processed }} className={`${styles.text}`}/>
                            <div className={`${styles.share}`}>
                                <ShareButton />
                            </div>
                            <div className={`${styles.newsComment}`}>
                                <iframe name="fbc639246fc61a623"
                                    width="1000px"
                                    height="100px"
                                    data-testid="fb:comments Facebook Social Plugin"
                                    title="fb:comments Facebook Social Plugin"
                                    frameBorder="0"
                                    allowtransparency="true"
                                    allowFullScreen={true}
                                    scrolling="no"
                                    llow="encrypted-media"
                                    src="https://www.facebook.com/v2.2/plugins/comments.php?app_id=695232667242592&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfb76436c8bb0511e5%26domain%3Dgenocidearchiverwanda.org.rw%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fgenocidearchiverwanda.org.rw%252Ff4fe9b0425f2b919d%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=986&amp;css=https%3A%2F%2Fgenocidearchiverwanda.org.rw%2Fskins%2Fwx%2Fmain10.css&amp;height=100&amp;href=https%3A%2F%2Fgenocidearchiverwanda.org.rw%2Findex.php%2FNews%3AGenocide_Archive_Trains_Young_Rwandans_to_Index_National_Archives&amp;locale=en_US&amp;numposts=5&amp;sdk=joey&amp;version=v2.2&amp;width="
                                    style={{ border: "none", visibility: "visible", width: "100%", height: "209px" }}
                                    className="">

                                </iframe>
                            </div>
                        </div>
                    )}
                </div>
            </SideBarLayout>

        )
    }
}
