import React from 'react'
import { Card } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './NewsImageCard.module.scss'
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'modules/website/utilities/utilities';

export default function NewsImageCard(props) {
    const { image, title, nav,date,secondType } = props;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(nav)
    }
    return (
        <Card className={`${styles.card} ${secondType&&styles.secondType}`} onClick={handleClick}>
            <div className={`${styles.top}`}>
                <Card.Img variant="top" src={image} className={`${styles.imagess}`} />
                <ChevronRight className={`${styles.icon}`} />
            </div>
            <Card.Body className={`${styles.body}`}>
                <Card.Title className={`${styles.title}`}
                    style={{ fontSize: secondType ? '20px' : '30px'}}
                >{title}</Card.Title>
                {
                    date && (
                        <Card.Text className={`${styles.date}`}>
                            {formatDate(date)}
                        </Card.Text>
                    )
                }
            </Card.Body>
        </Card>
    )
}
