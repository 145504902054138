import React from 'react'
import { Row } from 'react-bootstrap'
import CollectionCarItem from './CollectionCarItem/CollectionCarItem'
import styles from './CollectionCar.module.scss'
export default function CollectionCar() {
  return (
    <Row className={`${styles.Cars}`}>
      <CollectionCarItem title="Featured" color="#bc1000">
        Explore the most viewed, shared and recently added and materials.
      </CollectionCarItem>
      <CollectionCarItem title="Topics" color="#9b0d00" link="/category/Topics">
        Search through the collections easily using listed topics.
      </CollectionCarItem>
      <CollectionCarItem title="Source" color="#a7291e" link="/category/Source">
        Find specific materials for your research by browsing through the authors, collecting institutions and provenance.
      </CollectionCarItem>
      <CollectionCarItem title="Time Period" color="#7c1e16" link="/category/Time_Period">
        Browse through the 10 main time periods classified in the Rwandan History from pre-independence to post-genocide.
      </CollectionCarItem>
    </Row>
  )
}
