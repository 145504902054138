import React, { useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import styles from './BCrumb.module.scss';
import styled from 'styled-components';
const StyledBreakcrumb = styled(Breadcrumb.Item)`
	&:not(:first-child):before {
		content: '>' !important;
		color: #fff !important;
		font-weight: bold !important;
	}
`;
export default function BCrumb(props) {
	const navigate = useNavigate();
	const { breadC } = props;
	//if(breadC.length === 1){
	//Add Collection in the start of breadcrumb
	useEffect(() => {
		breadC.unshift({ title: 'Collections', path: '/collections' });
	}, [])
	//}
	return (
		<Breadcrumb className={`${styles.bc}`}>
			{
				breadC[0].title !== 'Collections' && breadC[0].title !== 'Home' &&(

					<StyledBreakcrumb
						className={`${styles.option}`}
					>

						<Link to={'/collections'} style={{ textDecoration: 'none', color: 'white' }}>

							Collection

						</Link>
					</StyledBreakcrumb>
				)
			}
			{breadC.map((item, index) => {
				if (index < breadC.length - 1) {
					return (

						<StyledBreakcrumb
							key={index}
							className={`${styles.option}`}
						>

							<Link to={item.path} style={{ textDecoration: 'none', color: 'white' }}>

								{item.title}

							</Link>
						</StyledBreakcrumb>
					);
				}
				return null;
			})}
			{/* <StyledBreakcrumb onClick={()=> navigate('/collections')} className={`${styles.option}`}>Collections</StyledBreakcrumb> */}
			<StyledBreakcrumb active className={`${styles.active}`}>
				{breadC[breadC.length - 1].title}
			</StyledBreakcrumb>
		</Breadcrumb>
	);
}
