import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styles from './BCrumb.module.scss'
import styled from "styled-components";
const StyledBreakcrumb = styled(Breadcrumb.Item)`
  &:not(:first-child):before {
    content: ">" !important;
    color: #fff !important;
    font-weight: bold !important;
  }
`;
export default function BCrumb() {
    const navigate = useNavigate();
    return (
        <Breadcrumb className={`${styles.bc}`}>
            <StyledBreakcrumb onClick={()=> navigate('/collections')} className={`${styles.option}`}>Collections</StyledBreakcrumb>
            <StyledBreakcrumb active className={`${styles.active}`}>Research</StyledBreakcrumb>
        </Breadcrumb>
    )
}
