import OptionCard from 'modules/website/components/WhatWeDo/OptionCard/OptionCard'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './WhatWeDoView.module.scss'
import CapacityBuilding from 'assets/img/WhatWeDo/Options/whatwe-capacitybuilding.png'
import Collections from 'assets/img/WhatWeDo/Options/whatwe-collections.png'
import DigitalArchive from 'assets/img/WhatWeDo/Options/whatwe-digitalarchive.png'
import EducationFilm from 'assets/img/WhatWeDo/Options/whatwe-educationfilmproduction.png'
import LibraryServices from 'assets/img/WhatWeDo/Options/whatwe-library.png'
import PhysicalPreservation from 'assets/img/WhatWeDo/Options/whatwe-physicalarchive.png'
export default function WhatWeDoView() {
    const options = [
        {
            image: Collections,
            title: 'Collection',
            link: '/about_us/Archive_Collection',
            description: 'The collection team compiles new materials for the archive, such as testimonies, audio-visual materials, documents, photographs and artefacts.',
        },
        {
            image: PhysicalPreservation,
            title: 'Physical preservation',
            link: '/about_us/Archive_Physical_Preservation',
            description: 'The preservation team manages our collections. They maintain the physical archive so that its materials are well protected and preserved.',
        },
        {
            image: DigitalArchive,
            title: 'Digital preservation and access',
            link: '/about_us/Archive_Digital_Preservation_and_Access',
            description: 'The digitisation team manages format conversion, editing, uploading and the backup of our digital archive materials, including audio-visual files, audio and photographs.',
        },
        {
            image: CapacityBuilding,
            title: 'Capacity building',
            link: '/about_us/Archive_Capacity_Building',
            description: 'Our teams attend workshops in archiving and preservation, both in Rwanda and abroad. In turn, we share our expertise through training sessions with local organisations and partners.',
        },
        {
            image: LibraryServices,
            title: 'Library services',
            link: '/about_us/Archive_Library_Services',
            description: 'The library team manages and maintains the archive library. They also guide researchers, students and visitors through the library and our digital and physical archives.',
        },
        {
            image: EducationFilm,
            title: 'Education film production',
            link: '/about_us/Archive_Education_Film_Production',
            description: 'The collection and digitisation teams create educational films and documentaries that local organisations use to promote peace building and genocide prevention.',
        },
    ]

    return (
        <div className={`${styles.main}`}>
            <Container>
                <Row>
                    {options.map((option, index) => (
                        <Col lg={6} md={12}>
                            <OptionCard key={index} image={option.image} title={option.title} link={option.link}>
                                {option.description}
                            </OptionCard>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}
