// La conexión la diferencia dependiendo el ambiente en el que esté
const ApiHostName =
	window.location.hostname.indexOf('genocidearchiverwanda.org') >= 0
		? window.location.hostname +'/api'
		: window.location.hostname.indexOf('local') >= 0
		? window.location.hostname+'/api'
		: window.location.hostname+'/api';

//console.log('ApiHostName:' + ApiHostName);
const ApiURLs = {
	publicFilesURL: 'https://' + ApiHostName + '/v1/',
	baseURL: window.location.protocol + '//' + ApiHostName,
	GetPageInfo: {
		href: '/glifos/:alias',
		method: 'get',
		urlParams: ['alias'],
		params: ['_format'],
	},
	GetReseach: {
		href: '/glifos/api/all/research/articles',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetLastNews:{
		href: '/glifos/api/last/news',
		method: 'get',
		urlParams: [],
		params: [],
	},
	GetNodeInfo: {
		href: '/glifos/node/:nid',
		method: 'get',
		urlParams: ['nid'],
		params: ['_format'],
	},
	GetNextNewInfo: {
		href: '/v1/website/nextnew/:nid',
		method: 'get',
		urlParams: ['nid']
	},
	GetContentType: {
		href: '/v1/website/contenttype/:alias',
		method: 'get',
		urlParams: ['alias'],
	},
	GetVideoInfo: {
		href: '/v1/richmedia/metadata/:alias/:stylesheet',
		method: 'get',
		urlParams: ['alias','stylesheet'],
		params: [],
	},
	GetDigitalArchiveInfo: {
		href: '/v1/richmedia/metadata/:alias/:stylesheet',
		method: 'get',
		urlParams: ['alias','stylesheet'],
		params: [],
	},
	GetCategoryPagination: {
		href: '/v1/search/taxonomyterm',
		method: 'get',
		params: [
		],
	},
	GetSearchQuery: {
		href: '/v1/search/query',
		method: 'get',
		params: [
		],
	},
	GetTaxonmyId: {
		href: '/v1/search/taxonomyid',
		method: 'get',
		params: [
		],
	},
};
//console.log(ApiURLs);
export default ApiURLs;
