import React from 'react';
import { Link } from 'react-router-dom';

export default function FooterLinks() {
	return (
		<div className='d-flex flex-row justify-content-center gap-5 flex-wrap'>
			<Link
				className='text-decoration-none text-white'
				to='/Terms_and_Conditions'>
				Terms & Conditions
			</Link>
			<div className='vr' />
			<Link
				className='text-decoration-none text-white'
				to='/Privacy_and_Cookies_Policy'>
				Privacy & Cookies Policy
			</Link>
			<div className='vr' />
			<Link className='text-decoration-none text-white' to='/FAQs'>
				FAQs
			</Link>
			<div className='vr' />
			<Link className='text-decoration-none text-white' to='/Disclaimer'>
				Disclaimer
			</Link>
			<div className='vr' />
			<Link className='text-decoration-none text-white' to='/contact_us'>
				Contact Us
			</Link>
			<div className='vr' />
			<a
				href='https://www.aegistrust.org/'
				target='_blank'
				className='text-decoration-none text-white'
				rel='noopener noreferrer'>
				Aegis Trust
			</a>
			<div className='vr' />
			<a
				href='https://kgm.rw/'
				target='_blank'
				className='text-decoration-none text-white'
				rel='noopener noreferrer'>
				Kigali Genocide Memorial
			</a>
		</div>
	);
}
