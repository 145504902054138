import React from 'react'
import BCrumb from './BreadCrumb/BCrumb'
import styles from './TopPart.module.scss'
import ChildCategoryList from '../Category/ChildCategoryList/ChildCategoryList';
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
export default function TopPart(props) {
    const { breadC, title, description, warning, broCategory, backBtn, memorial } = props;
    const navigate = useNavigate();
    return (
        <div className={`${styles.topPart}`}>
            {
                breadC && <BCrumb breadC={breadC} />
            }
            <div className={`${styles.title} ${backBtn ? "d-flex flex-row" : ""}`} style={{
                marginTop: !breadC ? ("62px" ) : ("0px" ),
                fontSize: backBtn ? ("28px") : ("60px")
                }}
                >
                {title}
                {
                    backBtn && (
                        <Link className={`${styles.btn}`} 
                        to={`/${backBtn.href.startsWith('/') ? backBtn.href.slice(1) : backBtn.href}`}
                        >
                            <ChevronLeft className={`${styles.icon}`} />
                            {backBtn.text}
                        </Link>
                    )
                }
            </div>
            {
                description && (

                    <div className={`${styles.description}`} dangerouslySetInnerHTML={{ __html: description }}></div>
                )
            }
            {
                broCategory && (
                    <ChildCategoryList
                        fatherid={broCategory}
                        actualTitle={title}
                    />
                )
            }
            {
                warning && <div className={`${styles.warning}`}>
                    {warning}
                </div>
            }
        </div>
    )
}
