import React from 'react'
import SearchForm from './SearchForm/SearchForm'
import BrowseVideo from './BrowseVideo/BrowseVideo'
import SearchSquareForm from '../SearchSquareForm/SearchSquareForm'

export default function SideBarVideo() {
  return (
    <div>
        <SearchSquareForm
        title='Search Videos '
        description= 'Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
        bkcolor='#25455a'
        btncolor='#ff9200'
        type='rich_media_archive'
        />
        <BrowseVideo/>
    </div>
  )
}
