import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './CollectionCard.module.scss'
import { Link } from 'react-router-dom';
export default function CollectionCard(props) {
    const { image, title, children, btn, url } = props;
    return (
        <Link className={`${styles.card}`} to={url}>
            <div className={`${styles.top}`} style={{ backgroundImage: `url(${image})` }}>
                <ChevronRight className={`${styles.icon}`}/>
                {title}
            </div>
            <div className={`${styles.description}`}>
                {children}
            </div>
            <Button className={`${styles.btn}`}>
                <ChevronRight className={`${styles.icon}`} />
                {btn}
            </Button>
        </Link>
    )
}
