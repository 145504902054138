import React from 'react'
import styles from './ChildCategoryList.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../Photographs/ExtraStyle.scss'
import { CaretDownSquareFill, ChevronRight } from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useCategoryBrother } from 'modules/website/hooks/useCategoryTreePage';
import ChildCategoryListLoading from '../ChildCategoryListLoading/ChildCategoryListLoading';
SwiperCore.use([Navigation, Mousewheel, Keyboard, Autoplay]);

export default function ChildCategoryList(props) {
    const { fatherid, actualTitle } = props;
    const navigate = useNavigate();

    const { isLoading, isError, payload, error } = useCategoryBrother({
        //category: 'category-' + tid,
        fatherid: fatherid,
    });

    //console.log(payload);

    return (
        <div className={`${styles.options}`}>
            
            {
                isLoading && <ChildCategoryListLoading/>
            }
            {
                isError && <div>Error: {error.message}</div>
            }

            {
                !isLoading && !isError && payload && (
                    Array.isArray(payload.records?.record) ? (
                        <>
                            <Swiper
                                spaceBetween={2}
                                slidesPerView={'auto'}
                                navigation
                                pagination={{ clickable: true }}
                                className={`${styles.swiper}`}>{
                                    payload.records?.record?.map((item, index) => (
                                        <>
                                            <SwiperSlide className={`${styles.slide}`} key={index}
                                            >
                                                <div
                                                    /* style={{
                                                        backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                                    }} */
                                                    className={`d-flex justify-content-center`}
                                                >
                                                    <Link to={item.id}
                                                        className={`${styles.categorie}`}
                                                        style={{
                                                            textDecoration: "none", color: "white",
                                                            backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                                        }}
                                                    >
                                                        <ChevronRight className={`${styles.icon}`} />
                                                        <div className={`${styles.title}`}>
                                                            {item.title}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    ))
                                }
                            </Swiper>
                            <div className={`${styles.selectCont}`}>
                                <Form.Select aria-label="SELECT NEWS CATEGORY" className={`${styles.select}`}
                                    onChange={(e) => {
                                        navigate(e.target.value);
                                    }}
                                >
                                    <option>SELECT NEWS CATEGORY</option>
                                    {payload.records?.record?.map((item, index) => (
                                        <option key={index}
                                            value={item.id}
                                        >{item.title}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </>
                    ) : (
                        <>
                            <Swiper
                                spaceBetween={2}
                                slidesPerView={'auto'}
                                navigation
                                pagination={{ clickable: true }}
                                className={`${styles.swiper}`}>{
                                    /* payload.records?.record?.map((item, index) => ( */
                                        <>
                                            <SwiperSlide className={`${styles.slide}`}
                                            >
                                                <div
                                                    /* style={{
                                                        backgroundColor: actualTitle === item.title ? "#d55a00" : "#6a6a6a",
                                                    }} */
                                                    className={`d-flex justify-content-center`}
                                                >
                                                    <Link to={payload.records?.record?.id}
                                                        className={`${styles.categorie}`}
                                                        style={{
                                                            textDecoration: "none", color: "white",
                                                            backgroundColor: actualTitle === payload.records?.record?.title ? "#d55a00" : "#6a6a6a",
                                                        }}
                                                    >
                                                        <ChevronRight className={`${styles.icon}`} />
                                                        <div className={`${styles.title}`}>
                                                            {payload.records?.record?.title}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    /* )) */
                                }
                            </Swiper>
                            <div className={`${styles.selectCont}`}>
                                <Form.Select aria-label="SELECT NEWS CATEGORY" className={`${styles.select}`}
                                    onChange={(e) => {
                                        navigate(e.target.value);
                                    }}
                                >
                                    <option>SELECT NEWS CATEGORY</option>
                                    <option value={payload.records?.record?.id}>{payload.records?.record?.title}</option>
                                    {/* {payload.records?.record?.map((item, index) => (
                                        <option key={index}
                                            value={item.id}
                                        >{item.title}</option>
                                    ))} */}
                                </Form.Select>
                            </div>
                        </>
                    )
                )
            }
            {/* <div className={`${styles.selectCont}`}>
                <Form.Select aria-label="SELECT NEWS CATEGORY" className={`${styles.select}`} >
                    <option>SELECT NEWS CATEGORY</option>
                    {categories.map((item, index) => (
                        <option key={index}>{item.title}</option>
                    ))}
                </Form.Select>
            </div> */}
        </div>
    )
}
