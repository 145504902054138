import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';

export default function CustomToolbar(Toolbar) {
	return (
		<Toolbar>
			{(slots) => {
				const {
					CurrentPageInput,
					Rotate,
					EnterFullScreen,
					GoToNextPage,
					GoToPreviousPage,
					NumberOfPages,
					SwitchTheme,
					Zoom,
					ZoomIn,
					ZoomOut,
				} = slots;
				const moreActions = {
					...slots,
					PrintMenuItem: () => <></>,
					OpenMenuItem: () => <></>,
					DownloadMenuItem: () => <></>,
				};
				return (
					<div
						data-testid='toolbar'
						className='rpv-toolbar'
						role='toolbar'
						aria-orientation='horizontal'
					>
						<div className='rpv-toolbar__left'>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<GoToPreviousPage />
							</div>
							<div className='rpv-toolbar__item'>
								<CurrentPageInput />
								<span className='rpv-toolbar__label'>
									{' / '}
									<NumberOfPages />
								</span>
							</div>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<GoToNextPage />
							</div>
						</div>
						<div className='rpv-toolbar__center'>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<ZoomOut />
							</div>
							<div className='rpv-toolbar__item'>
								<Zoom />
							</div>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<ZoomIn />
							</div>
						</div>
						<div className='rpv-toolbar__right'>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<SwitchTheme />
							</div>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<EnterFullScreen />
							</div>
							<div className='rpv-toolbar__item d-none d-md-flex'>
								<Rotate />
							</div>
							<div className='rpv-toolbar__item'>
								<MoreActionsPopover toolbarSlot={moreActions} />
							</div>
						</div>
					</div>
				);
			}}
		</Toolbar>
	);
}
