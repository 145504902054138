import React from 'react'
import { Accordion } from 'react-bootstrap'
import styles from './Itext.module.scss'
import GlifosTimeMark from '../GlifosTimeMark/GlifosTimeMark';
import MapItext from './MapItext/MapItext';

//TODO: hacer componente que sea itext tipo mapa
export default function Itext(props) {
    const { item, index } = props;
    if(item.title !== ""){
        return (
            <Accordion.Item eventKey={index} className={`${styles.item}`} key={index}>
                <Accordion.Header className={`${styles.header}`}>{item.title}</Accordion.Header>
                <Accordion.Body className={`${styles.body}`}>
                    {
                        item.type === 'map' ? (
                            <MapItext item={item.locations} />
                        ) : (
                            item.type === 'other' ? (
                                <div className={`${styles.other}`}>
                                    <div dangerouslySetInnerHTML={{__html: item.html}}></div>
                                </div>
                            ) : (
                                Array.isArray(item.tm) ? (
                                    item.tm.map((tm, index) => (
                                        <GlifosTimeMark tm={tm} margin={0} key={index} nextTm={item.tm[index + 1]} type={item.type} />
                                    ))
                                ) : (
                                    <GlifosTimeMark tm={item} margin={0} type={item.type} />
                                )
                            )
                        )
                    }
                </Accordion.Body>
            </Accordion.Item>
        )
    } else {
        return (
            <div></div>
        )
    }
    
}
