import React from 'react'
import styles from './WhatWeDo.module.scss'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const Options = [
    {
        title: 'Collection',
        link: '/about_us/Archive_Collection',
    },
    {
        title: 'Physical preservation',
        link: '/about_us/Archive_Physical_Preservation',
    },
    {
        title: 'Digital preservation',
        link: '/about_us/Archive_Digital_Preservation_and_Access',
    },
    {
        title: 'Capacity building',
        link: '/about_us/Archive_Capacity_Building',
    },
    {
        title: 'Library services',
        link: '/about_us/Archive_Library_Services',
    },
    {
        title: 'Education film production',
        link: '/about_us/Archive_Education_Film_Production',
    },
    {
        title: 'Projects',
        link: '/projects',
    },
    {
        title: 'Reports',
        link: '/reports',
    },
];

export default function WhatWeDo() {
    const navigate = useNavigate();
    return (
        <div className={`${styles.whatWeDo}`}>
            <div className={`${styles.title}`}>
                What we do
            </div>
            <div className={`${styles.options}`}>
                {Options.map((option, index) => (
                    <Button variant="primary" type="submit" className={`${styles.btn}`} key={index} onClick={()=>navigate(option.link)}>
                        {option.title}
                        <ChevronRight className={`${styles.icon}`} />
                    </Button>
                ))}
            </div>
        </div>
    )
}
