import React from 'react'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './Option.module.scss';
import { useNavigate } from 'react-router-dom';
export default function Option(props) {
    const { title, children, nav } = props;
    const navigate = useNavigate();
    return (
        <div className={`${styles.option}`}>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.description}`}>
                {children}
            </div>
            <Button className={`${styles.btn}`} onClick={()=>navigate(nav)}>
                <ChevronRight className={`${styles.icon}`}/>
                LEARN MORE
            </Button>
        </div>
    )
}
