import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingViewer() {
	return (
		<div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
			<Spinner animation='border' role='status' variant='primary'>
				<span className='visually-hidden'>Loading...</span>
			</Spinner>
		</div>
	);
}
