import ArticleLoading from 'modules/website/components/Article/ArticleLoading/ArticleLoading';
import { useInformationPage } from 'modules/website/hooks/useInformationPage';
import React from 'react'
import { Container } from 'react-bootstrap';
import Article from 'modules/website/components/Article/Article';
import { useLocation } from 'react-router-dom';
import styles from './AboutUsInfoView.module.scss'
import Alert from 'modules/website/components/Alert/Alert';

export default function AboutUsInfoView() {
    const location = useLocation();

    const alias = location.pathname.includes('what_we_do') ? location.pathname.replace('/about_us/what_we_do/', '') : location.pathname.replace('/about_us/', '');

    const { isLoading, isError, payload, error } = useInformationPage({
        alias: alias,
    });
    return (
        <div className={`${styles.info}`}>

            {isLoading &&
                <>
                    <Container>
                        <ArticleLoading />
                    </Container>
                </>
            }
            {!isLoading && isError && (<Alert variant="danger">{error.message}</Alert>)}
            {!isLoading && !isError && payload && (
                <>
                    <Container>
                        <Article title={payload.title} mediaSharing>
                            <div dangerouslySetInnerHTML={{ __html: payload.body }} />
                        </Article>
                    </Container>
                </>
            )}

        </div>
    )
}
