import React, { useState } from 'react'
import OrderBy from 'modules/website/components/Research/OrderBy/OrderBy'
import { useCategoryTreePage } from 'modules/website/hooks/useCategoryTreePage';
import Alert from 'modules/website/components/Alert/Alert';
import { Col, Row } from 'react-bootstrap';
import styles from './TestimoniesView.module.scss'
import OptionCard from 'modules/website/components/Category/OptionCard/OptionCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';

const dumbData = [
    {
        title: "ELDERS",
        description: "To understand the Genocide against the Tutsi, it is important to learn about the history of Rwanda. Here you can listen to elders talk about their experiences before the 1994 Genocide and how division and segregation was fomented by politicians, religious leaders, the media and colonisers.",
        link: "",
        image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=Isidore-featured.gif"
    },
    {
        title: "PERPETRATORS",
        description: "Watch testimonies given by perpetrators of the 1994 genocide against the Tutsi in Rwanda. They share their experiences and the consequences of the acts they committed in 1994, including facing justice, rejoining society and reconciliation.",
        link: "",
        image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=Perpetrator%20tileIMG%205281.jpg"
    },
    {
        title: "RESCUERS",
        description: "Watch testimonies given by those who hid and protected Tutsi from the Interahamwe militia and perpetrators of the 1994 genocide against the Tutsi in Rwanda.",
        link: "",
        image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=Rescuer%20tileIMG%209279.jpg"
    },
    {
        title: "SURVIVORS",
        description: "Watch testimonies given by survivors of the 1994 genocide against the Tutsi in Rwanda. They talk about life before 1994, the events leading up to the genocide, surviving the genocide, loss of family, friends and other topics.",
        link: "",
        image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=Survivor%20Umurerwa%20Bethe%20tile%2001.jpg"
    }
]

export default function TestimoniesView() {
    const tid = 1954;
    const [order, setOrder] = useState('sorttitle')
    const { isLoading, isError, payload, error } = useCategoryTreePage({
        category: 'category-' + tid,
        page: 1,
        tid: tid,
        sortby: order
    });

    //console.log(payload)
    return (
        <div>
            <div>
                <OrderBy
                    order={order}
                    setOrder={setOrder}
                />
            </div>
            {
                isLoading && <div>Loading ...</div>
            }
            {
                isError && <Alert variant="error">{error}</Alert>
            }
            {
                payload && !isLoading && !isError && (

                    <Row className={`${styles.rowCards}`}>
                        {payload?.solr_content?.records?.record?.map((option, index) => (
                            <Col lg={6} md={12} key={index}>
                                <OptionCard
                                    image={
                                        ApiURLs.baseURL +
                                        option.thumbnail
                                    }
                                    title={option.title}
                                    link={option.id}
                                    description={option.blurb} />
                            </Col>
                        ))}
                    </Row>
                )
            }
        </div>
    )
}
