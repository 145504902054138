import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styles from './SearchForm.module.scss'
import { ChevronRight } from 'react-bootstrap-icons'
export default function SearchForm() {
    return (
        <Form className={`${styles.form}`}>
            <div className={`${styles.title}`}>
                Search
            </div>
            <div className={`${styles.description}`}>
                Browse through this section and find out about the work we do in the Aegis Trust archive and documentation department.
            </div>
            <Form.Group className={`${styles.formGroup} mb-3`} controlId="formBasicPassword">
                <Form.Control type="input" />
            </Form.Group>
            <Button variant="primary" type="submit" className={`${styles.btn}`}>
                <ChevronRight className={`${styles.icon}`} />
                SEARCH
            </Button>
        </Form>
    )
}
