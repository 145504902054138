import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import React from 'react'
import styles from './ReportsView.module.scss'
import { Container } from 'react-bootstrap';
import ArticleLoading from 'modules/website/components/Article/ArticleLoading/ArticleLoading';
import Alert from 'modules/user/components/Alert/Alert';
import Article from 'modules/website/components/Article/Article';
import { useInformationPage } from 'modules/website/hooks/useInformationPage';
export default function ReportsView() {
    const { isLoading, isError, payload, error } = useInformationPage({
        alias: 'reports',
    });

    //console.log(payload);
    return (
        <div className={`${styles.info}`}>

            {isLoading &&
                <>
                    <Container>
                        <ArticleLoading />
                    </Container>
                </>
            }
            {!isLoading && isError && (<Alert variant="danger">{error.message}</Alert>)}
            {!isLoading && !isError && payload && (
                <>
                    <Container>
                        <Article title={payload.title} mediaSharing>
                            <div dangerouslySetInnerHTML={{ __html: payload.body }} />
                        </Article>
                    </Container>
                </>
            )}

        </div>
    )
}
