import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ShareFill } from 'react-bootstrap-icons'
import styles from './ShareButton.module.scss'
import ShareModal from 'modules/glifos-rich-media/components/CreateSegment/ShareModal/ShareModal';

export default function ShareButton(props) {
    const { document } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <Button className={`${styles.btn}`} onClick={handleShow}>
                <ShareFill className={`${styles.icon}`} />
                SHARE {document && 'DOCUMENT'}
            </Button>
            <ShareModal show={show} handleClose={handleClose}/>
        </div>
    )
}
