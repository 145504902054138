import TopPart from 'modules/website/components/TopPart/TopPart';
import React from 'react';
import styles from '../PostGenocideReconstructionView/post_genocide.module.css';
export default function OurPartnersView() {
	const refContainer = React.useRef(null);
	React.useEffect(() => {
		const liElements = refContainer.current.querySelectorAll('li');

		const toogleDescription = (e) => {
			const description = e.currentTarget.querySelector(
				`.${styles['partners-int-list-desc']}`
			);
			//toggle the show classname
			description.classList.toggle(styles.show);
		};

		liElements.forEach((li) => {
			// Verifica si el elemento tiene algún estilo aplicado
			if (!li.getAttribute('class')) {
				li.addEventListener('click', toogleDescription);
			}
		});

		// Recuerda remover el event listener cuando el componente se desmonte
		return () => {
			liElements.forEach((li) => {
				if (!li.getAttribute('class')) {
					li.removeEventListener('click', toogleDescription);
				}
			});
		};
	}, []);
	return (
		<div ref={refContainer}>
			<TopPart
				breadC={[
					{
						title: 'About Us',
						path: '/collections',
					},
					{
						title: 'Our Partners',
						path: '',
					},
				]}
				title='PARTNERS &amp; DONORS'
				description='The  existence  and development of the  Genocide Archive of Rwanda  relies on the  generous contributions of  public  and private donors and supporters . Learn more about the friends of the Genocide Archive of Rwanda  and  find  inspiration on the different ways to support the work we do to preserve the memory of the 1994 genocide of the Tutsi in Rwanda.'
			/>
			<div className={`${styles['partners-int']} ${styles['col-md-4']}`}>
				<div className={`${styles['partners-int-title']}`}>
					<span style={{ fontSize: '36px' }}>International</span>
				</div>
				<div className={`${styles['partners-int-col1']}`}>
					<div className={`${styles['partners-int-subt']}`}>
						DONORS
					</div>
					<ul className={`${styles['partners-int-list']}`}>
						<li>
							UK Department for International Development (DfID)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								DfID is the United Kingdom government department
								responsible for administering overseas aid. In
								Rwanda DFID supports progress towards the
								Millennium Development Goals (MDGs), focusing on
								education, health, agriculture and social
								protection.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='https://www.gov.uk/government/publications/dfid-rwanda-operational-plan-2014'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Swedish International Development and Cooperation
							Agency (Sida)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Swedish aid contributes to ensuring that
								development in Rwanda takes place in an
								environmentally peaceful and sustainable way.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='http://www.sida.se/English/where-we-work/Africa/Rwanda/Our-work-in-Rwanda/'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Kingdom of the Netherlands Embassy
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Netherlands support to Rwanda is focused on
								creating a strong justice system, reconciliation
								efforts, promotion of human rights and fighting
								against the genocide. This support provided to
								the justice system strengthens infrastructure,
								training and equipment.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='http://rwanda.nlembassy.org'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							The Annenberg Foundation
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The Annenberg Foundation is a family foundation
								established in 1989. Founded by Walter H.
								Annenberg, the Foundation supports the worldwide
								community through its grant making, technical
								assistance and direct charitable activities. The
								Annenberg Foundation supports the development of
								more effective ways to share ideas and
								knowledge.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='http://www.annenbergfoundation.org'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
					</ul>
				</div>
				<div className={`${styles['partners-int-col2']}`}>
					<div className={`${styles['partners-int-subt']}`}>
						PARTNERS
					</div>
					<ul className={`${styles['partners-int-list']}`}>
						<li>
							The University of Texas Libraries (UTL)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The University of Texas Libraries (UTL) is the
								fifth largest academic library in the USA.
								Through its Human Rights Documentation
								Initiative (HRDI), UTL contributes to the
								long-term preservation of fragile and vulnerable
								records of human rights struggles worldwide, the
								promotion and secure usage of human rights
								archival materials, and the advancement of human
								rights research and advocacy around the world.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='https://www.lib.utexas.edu/hrdi/about'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							The University of Southern California Shoah
							Foundation (USC SF)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The University of Southern California Shoah
								Foundation (USC SF), the Institute for Visual
								History and Education is a nonprofit
								organization established by Steven Spielberg in
								1994. Originally aimed at recording and
								preserving testimonies of survivors and other
								witnesses of the Holocaust, the Institute's
								Visual History Archive is expanding its
								collection to include testimony from survivors
								and witnesses of other genocides, including the
								Genocide of the Tutsi in Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='https://sfi.usc.edu'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							GLIFOS
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								GLIFOS is a Guatemalan company specialized in
								GLIFOS is a Guatemalan company specialized in
								developing software for managing document
								collections, video collections, libraries, and
								institutional portal.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']} ${styles['btn-yellow']}`}
									href='https://www.glifos.com'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div
				className={`${styles['partners-local']} ${styles['col-md-4']}`}
			>
				<div className={`${styles['partners-local-title']}`}>
					<span style={{ fontSize: '36px' }}>Local</span>
				</div>
				<div className={`${styles['partners-local-col1']}`}>
					<div className={`${styles['partners-int-subt']}`}>
						GOVERNMENT AGENCIES
					</div>
					<ul className={`${styles['partners-int-list']}`}>
						<li>
							Ministry of Sports and Culture (MINISPOC)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The Ministry of Sports and Culture oversees 5
								agencies including the National Commission for
								the Fight Against Genocide, National Library and
								Archives and the Institute of National Museums
								of Rwanda to develop and support implementation
								of policies and strategies that lead to making
								culture foundational to Rwanda's development
								agenda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://www.minispoc.gov.rw'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							The National Commission for the fight against
							Genocide (CNLG)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The CNLG is the Ministry of Sports and Culture
								agency in charge of preventing and fighting
								against genocide and the genocide ideology. With
								the objective of building a world free of
								Genocide and Genocide ideology, CNLG addresses
								genocide issues both within and outside Rwanda;
								which includes the support to the creation of a
								national research and Documentation Centre on
								Genocide.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://www.cnlg.gov.rw'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Rwanda Development Board IT (RDB-IT)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Guided by the objective of transforming Rwanda
								into a dynamic global hub for business,
								investment, and innovation, Rwandan Development
								Board's work includes all aspects related to the
								development of the private sector. The ICT
								Information and Communication Technology
								Department of RDB is a central engine to driving
								Rwanda's transformation to a knowledge-based
								economy.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://www.rdb.rw/departments/information-communication-technology/overview.html'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
					</ul>
				</div>
				<div className={`${styles['partners-local-col2']}`}>
					<div className={`${styles['partners-int-subt']}`}>
						PARTNERS
					</div>
					<ul className={`${styles['partners-int-list']}`}>
						<li>
							IBUKA
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Ibuka, which means "Remember" in Kinyarwanda, is
								the umbrella association for Genocide survivors'
								organizations working on survivors support and
								focusing on memory, justice, and peacebuilding.
								IBUKA provides information and content useful
								for the development of the Genocide Archive of
								Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#IBUKA'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Never Again Rwanda (NAR)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Never Again Rwanda is a human rights and
								peacebuilding organization founded in response
								to the 1994 genocide perpetrated against Tutsis.
								Guided by a vision of a nation where citizens
								are agents of positive change, NAR aims to
								empower Rwandans with opportunities to become
								active citizens through peacebuilding and
								development. NAR provides information and
								content useful for the development of the
								Genocide Archive of Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://neveragainrwanda.org'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Association des Veuves du Genocide Agahozo (AVEGA)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								AVEGA Agahozo is the Rwandan National
								Association of Genocide Widows which has
								operated on a national level since 1995. Its
								action is focused on the specific support that
								widows of the genocide and their dependents need
								as a consequence of the physical and sexual
								violence endured. AVEGA provides information and
								content useful for the development of the
								Genocide Archive of Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://avegaagahozo.org/about-us'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Association des &eacute;l&egrave;ves et
							&eacute;tudiants rescap&eacute;s du Genocide (AERG)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The AERG, association of student survivors of
								genocide Created was created in 1996 to connect
								and represent all students survivors and assist
								young survivors towards their rehabilitation
								into society, and assist then into designing a
								better future including education [promotion in
								collaboration with FARG - Government of Rwanda
								Assistance Fund for Survivors -. AERG also
								advocates for the ongoing needs of survivors,
								and encourages them to contribute to the
								eradication of the genocide ideology. AERG
								provides information and content useful for the
								development of the Genocide Archive of Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://aerg.org.rw'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Groupe des anciens &eacute;tudiants rescap&eacute;s
							du Genocide (GAERG)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								GAERG is an organization founded by Rwandan
								graduates Genocide survivors with a mission of
								creating a world where the memory of genocide is
								preserved and a self-sustaining genocide
								survivor&#39;s community exists through
								education and capacity-building, socio economic
								development, and advocacy for beneficiaries.
								GAERG provides information and content useful
								for the development of the Genocide Archive of
								Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://gaerg.org'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Dukundane Family (D.F.)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Dukundane Family is an association of Young
								Survivors founded in 2007 by former members of
								Survivors&#39; Students Association in St Andrew
								College (AERG St Andre). D.F. provides support
								to families affected by the families. D.F.
								provides information and content useful for the
								development of the Genocide Archive of Rwanda.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://survivors-fund.org.uk/tag/dukundane-family'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Rwanda Broadcasting Agency: RADIO RWANDA
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#RADIO RWANDA'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Rwanda Broadcasting Agency: RWANDA TELEVISION
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#RWANDA TELEVISION'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							IMVAHO NSHYA
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#IMVAHO NSHYA'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Center for Conflict Management (CCM)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#Center for Conflict Management'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							National Archives of Rwanda (NAR)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								\ .<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#National Archives of Rwanda'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							The Institute of National Museums of Rwanda (INMR)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#The Institute of National Museums of Rwanda'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							University of Rwanda (College of Arts and Social
							Sciences in Butare)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#University of Rwanda'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Centre Iwacu Kabusunzu
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#Centre Iwacu Kabusunzu'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							Les Dominicains (priests)
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								.<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='#Les Dominicains'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div className={`${styles['partners-part']} ${styles['col-md-3']}`}>
				<div className={`${styles['partners-part-title']}`}>
					<span style={{ fontSize: '36px' }}>
						Special Project Partners
					</span>
				</div>
				<div className={`${styles['partners-part-subt']}`}>
					<ul className={`${styles['partners-int-list']}`}>
						<li>
							<span style={{ fontSize: '14px' }}>
								Kings College London (KCL)
							</span>
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								The Department of Digital Humanities, King’s
								College London, is an international leader in
								the application of technology in the arts and
								humanities, and in the social sciences. It is
								the largest and most prestigious department of
								its kind anywhere.
								<br />
								&nbsp;
								<br />
								The Department is dedicated to furthering the
								possibilities of computing for arts and
								humanities scholarship and, in collaboration
								with local, national, and international research
								partners across the disciplines, to design and
								build applications which implement these
								possibilities. It has a broad range of expertise
								in the development of large-scale digital
								projects
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://www.kcl.ac.uk/artshums/depts/ddh/index.aspx'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
						<li>
							<span style={{ fontSize: '14px' }}>
								The Netherlands Institute for Holocaust and
								Genocide Studies (NIOD)
							</span>
							<div
								className={`${styles['partners-int-list-desc']}`}
							>
								Institute for War, Holocaust and Genocide
								Studies NIOD is an institute of the Royal
								Netherlands Academy of Arts and Sciences (KNAW).
								NIOD focuses on the knowledge of war, genocide,
								and large-scale violence, its roots and its
								aftermath, and disseminating this knowledge to
								society. State-of-the-art interdisciplinary
								research is key in this process. In the domain
								of collections NIOD focuses on digitization,
								sustainable methods of access to archives and
								collections, acquisitions, and cooperation with
								strategic archives worldwide.
								<br />
								<a
									className={`${styles['btn']} ${styles['no-arr']}`}
									href='http://www.niod.nl/en/node/11'
									target='_blank'
									rel='noreferrer'
								>
									VISIT WEBSITE
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
