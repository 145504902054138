import OptionCard from 'modules/website/components/Category/OptionCard/OptionCard'
import OrderBy from 'modules/website/components/Research/OrderBy/OrderBy'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './JobOpportunitiesView.module.scss'

export default function JobOpportunitiesView() {
  const dumbData =[
    {
      title: "EMPLOYMENT",
      image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=CollectionIMG%209732.JPG",
      link:"/Jobs"
    },
    {
      title: "INTENSHIPS",
      image: "https://genocidearchiverwanda.org.rw/index.php?action=ajax&rs=importImage&rsargs[]=PreservationIMG%206568.JPG",
      link:"/Internships"
    },
  ]
  return (
    <div>
      <div>
        <OrderBy />
      </div>
      <div>
      <Row className={`${styles.rowCards}`}>
            {dumbData?.map((option, index) => (
              <Col lg={4} md={12} key={index}>
                <OptionCard image={option.image} title={option.title} link={option.link} description={option.blurb}/>
              </Col>
            ))}
          </Row>
      </div>
    </div>
  )
}
