import React from 'react';

export default function Copyright() {
	return (
		<div className='d-flex flex-row align-items-center justify-content-center gap-4 flex-wrap'>
			<p className='text-muted my-1'>
				&copy; Copyright AEGIS TRUST {new Date().getFullYear()}
			</p>
			<div className='vr' />
			<p className='text-muted my-1'>All rights reserved</p>
		</div>
	);
}
