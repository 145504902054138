import React from 'react'
import { Link } from 'react-router-dom'
import styles from './WhatWeDoTitle.module.scss'
import { ChevronLeft } from 'react-bootstrap-icons'
export default function WhatWeDoTitle() {
    return (
        <div className={`${styles.main}`}>
            <div className={`${styles.title}`}>WHAT WE DO</div>
            <Link to={'/about_us'} className={`${styles.link}`}>
                <ChevronLeft/>
                BACK TO ABOUT US
            </Link>
        </div>
    )
}
