import React from 'react';
import HeadingVariants from './HeadingVariants';

export default function Heading(props) {
	let { variant, override } = props;
	if (!variant) variant = 'H1';
	else variant = variant.toUpperCase();
	if (override) variant = 'Override';
	const Component = HeadingVariants[variant];
	return <Component {...props}>{props.children}</Component>;
}
