import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import CenteredLayout from 'common/layouts/CenteredLayout/CenteredLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import { importComponent } from 'common/loaders/importComponent';
import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo';
import NotRegisteredAd from 'modules/website/components/NotRegisteredAd/NotRegisteredAd';
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import { useAliasNodeType } from 'modules/website/hooks/useAliasNodeType';
import { useInformationPage } from 'modules/website/hooks/useInformationPage';
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ComponentMap } from 'routes/ComponentMap';

//const RichMediaView = lazy(() => import('../GlifosRichMediaView/GlifosRichMediaView'));
//const InfromationView = lazy(() => import('../InfromationView/InfromationView'));

export default function AliasNodeView() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [ViewLoad, setViewLoad] = useState(null);
    const [leftBar, setLeftBar] = useState(null);
    const [rightBar, setRightBar] = useState(null);
    const [title, setTitle] = useState(null);
    const [breadC, setBreadC] = useState(null);
    const [backBtn, setBackBtn] = useState(null);
    const [description, setDescription] = useState(null);
    const [component, setComponent] = useState(null);
    //const alias = location.pathname.replace('/', '');
    const { alias, alias2 } = useParams();
    const { isLoading, isError, payload, error } = useAliasNodeType({
        alias: `${alias}${alias2 ? "$$" + alias2 : ""}`,
    });

    //console.log(payload?.indexed[0])
    //console.log(auth)

    useEffect(() => {
        const type = payload && (payload.type[0].target_id)
        if (!isLoading && payload) {
            const type = payload.type[0].target_id
            switch (type) {
                case "rich_media_archive":
                    //get from ComponentMap where type is the same
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    setLeftBar(SideBarVideo)
                    //setViewLoad(RichMediaView);
                    setTitle(payload.title[0].value);
                    setDescription("Author: Genocide Archive Rwanda");
                    break;
                case "glifos_digital_archive":
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    setLeftBar(SideBarDocument)
                    //setViewLoad(RichMediaView);
                    setTitle(payload.title[0].value);
                    //setDescription("Author: Genocide Archive Rwanda");
                    /* setBreadC(
                        [
                            {
                                title: 'Collections',
                                path: '/collections',
                            },
                            {
                                title: 'Documents',
                                path: '/category/documents',
                            },
                            {
                                title: payload.title[0].value,
                                path: '',
                            },
                        ]
                    ) */
                    break;
                case "glifos_memorial":
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    setTitle(payload.title[0].value);

                case "glifos_photographs":
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    break;
                case "information":
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    break;
                case "about_information":
                    setComponent(ComponentMap.find(componente => componente.type === type));
                    break;
                case "news":
                    navigate('/news/' + alias)
                default:
                    // Manejo de tipo desconocido o sin asignar
                    break;
            }
        }
    }, [payload]);

    const ViewComponent = component ? importComponent(component.c) : null;

    if (!isLoading && payload) {
        console.log(auth)
        if (payload?.indexed[0].value === 'registered') {
            // To login if there is no refresh_token expiration
            if (!auth?.refresh_token_expires_on) {
                return (
                    <BasicLayout>
                        <NotRegisteredAd alias={`${alias}${alias2 ? "/" + alias2 : ""}`} />
                    </BasicLayout>
                )
            }
            // To login if the token expired
            if (new Date() > new Date(auth?.refresh_token_expires_on)) {
                return (
                    <BasicLayout>
                        <NotRegisteredAd alias={`${alias}${alias2 ? "/" + alias2 : ""}`} />
                    </BasicLayout>
                )
            }

        }
    }

    return (
        component && component.l === 'left' ? (
            <SideBarLayout
                title={title}
                breadC={breadC}
                description={description}
                leftBar={leftBar}
                rightBar={rightBar}
                backBtn={backBtn}
            >
                <Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} setBackBtn={setBackBtn} title={title} /></Suspense>
            </SideBarLayout>
        ) : (
            component && component.l === 'central' ? (
                <CenteredLayout>
                    <Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} title={title} /></Suspense>
                </CenteredLayout>
            ) : (
                component && component.l === 'basic' ? (
                    <BasicLayout>
                        <Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} title={title} /></Suspense>
                    </BasicLayout>
                ) : (
                    <></>
                )
            )
        )
    )

}
