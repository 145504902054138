import React from 'react'
import styles from './Collections.module.scss'
import CollectionsBg from 'assets/img/Collections/collections-bg.png'
import SearchBar from '../MainPage/WelcomeMsg/SearchBar/SearchBar'
import AdvancedSearch from './AdvancedSearch/AdvancedSearch'
import { Row } from 'react-bootstrap'
import CollectionCards from './CollectionCards/CollectionCards'
import Types from './Types/Types'
import CollectionCar from './CollectionCar/CollectionCar'
export default function Collections() {
    return (
        <div style={{ background: `url(${CollectionsBg}) no-repeat scroll center 0px / cover transparent` }} className={`${styles.container}`}>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>
                    COLLECTIONS
                </div>
                <Row>
                    <p className={`${styles.description}`}>
                        The Genocide Archive of Rwanda's collection contains over 8,000 photographs, videos and documents related to the 1994 genocide against theTutsi. Materials about pre-genocide history and post-genocide reconciliation and recovery initiatives in Rwanda are also included.
                        Browse through and search to find specific content that may be of interest to you.
                    </p>
                    <SearchBar placeholder="Search Collections" />
                    <AdvancedSearch />
                </Row>
                <CollectionCards />

            </div>
            <Types />
            <CollectionCar />
        </div>
    )
}
