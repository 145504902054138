import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './LearnMoreBtn.module.scss'
import { useNavigate } from 'react-router-dom'
export default function LearnMoreBtn(props) {
  const { variant, link } = props
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link)
  }
  return (
    <Button className={variant === "red" ? (`${styles.redbtn}`):(`${styles.orangebtn}`)} variant={variant} onClick={handleClick}>
        <ChevronRight className={`${styles.icon}`}/>
        LEARN MORE
    </Button>
    
  )
}
