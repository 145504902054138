import React from 'react';
import { Whatsapp, Envelope, Twitter, Facebook } from 'react-bootstrap-icons';

import styles from './MediaSharing.module.scss';

export default function MediaSharing() {
	return (
		<div className='align-self-end'>
			<div className='d-flex flex-row flex-wrap gap-4'>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('Facebook')}>
					<Facebook size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('twitter')}>
					<Twitter size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('whatsapp')}>
					<Whatsapp size={25} />
				</div>
				<div
					className={`d-flex flex-row align-items-center justify-content-center ${styles.social_media_box}`}
					onClick={() => console.log('whatsapp')}>
					<Envelope size={25} />
				</div>
			</div>
		</div>
	);
}
