import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './CollectionBtn.module.scss'
import { useNavigate } from 'react-router-dom'
export default function CollectionBtn() {
  const navigate = useNavigate();
  return (
    <Button className={`${styles.btn}`} onClick={()=>navigate('/collections')}>
        <ChevronRight className={`${styles.icon}`}/>
        VIEW OUR COLLECTIONS
    </Button>
  )
}
