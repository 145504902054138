import React, { useState } from 'react'
import styles from './SuscribeToForm.module.scss'
import { Button, Form, FormGroup } from 'react-bootstrap'
export default function SuscribeToForm() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <Form className={`${styles.form}`} noValidate validated={validated} action='//aegistrust.us9.list-manage.com/subscribe/post?u=358cbffee5f2094b202ee0f6d&id=c934e36a09'>
            <h2 className={`${styles.title}`}>Subscribe to our mailing list</h2>
            <FormGroup className={`${styles.group}`} controlId="validationCustom01">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" required />
                <Form.Control.Feedback type="invalid">
                    Valid email is required.
                </Form.Control.Feedback>
            </FormGroup>
            <FormGroup className={`${styles.group}`}>
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" />
            </FormGroup>
            <FormGroup className={`${styles.group}`}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" />
            </FormGroup>
            <Button variant="primary" type="submit" className={`${styles.btn}`}>
                Suscribe
            </Button>
        </Form>
    )
}
