import React from 'react'
import styles from './OptionsCategories.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { CaretDownSquareFill } from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
SwiperCore.use([Navigation, Mousewheel, Keyboard, Autoplay]);

export default function OptionsCategories() {
    const navigate = useNavigate();
    const categories = [
        {
            title: 'PEACE EDUCATION',
            link: '/category/News_Peace_Education'
        },
        {
            title: 'CAPACITY BUILDING',
            link: '/category/News_Capacity_Building'
        },
        {
            title: 'PRESERVATION',
            link: '/category/News_Preservation'
        },
        {
            title: 'YOUTH AND PEACE BUILDING',
            link: '/category/News_Youth_and_Peace_Building'
        },
        {
            title: 'KWIBUKA',
            link: '/category/News_Kwibuka'
        },
        {
            title: 'EVENTS',
            link: '/category/News_Events'
        },
        {
            title: 'COLLECTIONS',
            link: '/category/News_Collections'
        },
    ]
    return (
        <div className={`${styles.options}`}>
            <Swiper
                spaceBetween={5}
                slidesPerView={5}
                navigation
                pagination={{ clickable: true }}
                className={`${styles.swiper}`}>
                {categories.map((item, index) => (
                    <SwiperSlide className={`${styles.slide}`} key={index}>
                        <div className={`${styles.categorie}`}>
                            <Link to={item.link}
                                className={`${styles.categorie}`}
                                style={{ textDecoration: "none", color: "white" }}
                            >
                                <CaretDownSquareFill className={`${styles.icon}`} />
                                <div className={`${styles.title}`}>
                                    {item.title}
                                </div>
                            </Link>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={`${styles.selectCont}`}>
                <Form.Select aria-label="SELECT NEWS CATEGORY" className={`${styles.select}`} 
                    onChange={(e) => navigate(e.target.value) }
                >
                    <option>SELECT NEWS CATEGORY</option>
                    {categories.map((item, index) => (
                        <option key={index}
                            onClick={() => navigate(item.link) }
                            value={item.link}
                        >
                            <Link to={item.link}
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                {item.title}
                            </Link>
                        </option>
                    ))}
                </Form.Select>
            </div>
        </div>
    )
}
