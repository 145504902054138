import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TypeCard from './TypeCard/TypeCard'
import styles from './Types.module.scss'
import CollectionsPhotos from 'assets/img/Collections/collections-photos.png'
import CollectionsVideos from 'assets/img/Collections/collections-videos.png'
import CollectionsaAudio from 'assets/img/Collections/collections-audio.png'
import CollectionsDocuments from 'assets/img/Collections/collections-documents.png'

const TypesOptions = [
    {
        image: CollectionsPhotos,
        label: 'PHOTOGRAPHS',
        link: '/category/photographs'
    },
    {
        image: CollectionsVideos,
        label: 'VIDEOS',
        link: '/category/videos'
    },
    {
        image: CollectionsaAudio,
        label: 'AUDIO',
        link: '/category/audio'
    },
    {
        image: CollectionsDocuments,
        label: 'DOCUMENTS',
        link: '/category/documents'
    },
]
export default function Types() {
    return (
        <Row className={`${styles.typesRow}`}>
            <Col lg={3} md={12} className={`${styles.container}`}>
                <div className={`${styles.title}`}>BROWSE BY TYPE</div>
                <div className={`${styles.description}`}>
                    The archive contains thousands of photos, videos,
                    audio files and documents related to the 1994 genocide against the Tutsi,
                    pre-genocide history and reconciliation programmes. Here you can browse our collections by content type.
                </div>
            </Col>
            <Col lg={9} md={12} className={`${styles.types}`}>
                <Row>
                    {
                        TypesOptions.map((item, index) => {
                            return (
                                <Col xl={3} lg={6} md={6} key={index}>
                                    <TypeCard image={item.image} label={item.label} link={item.link}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
        </Row>
    )
}
