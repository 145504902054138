
/* export function formatDate(date) {
    console.log(date)
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${day} ${month} ${year}`;
} */
export function formatDate(date) {
    const dateObj = new Date(date + 'T00:00:00Z'); // Asegurarse de que la fecha se trate en UTC
    const month = dateObj.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return `${day} ${month} ${year}`;
}

export function getFirstParagraph(body, title) {
    const regex = /<p(?:\s+[^>]*)?>(.*?)<\/p>/;
    const match = body.match(regex);
    if (match) {
        if (match[1] != title) {
            const paragraphContent = match[1];
            return (paragraphContent);
        } else {
            const paragraphContent = match[2];
            return (paragraphContent);
        }
    } else {
        return ('No se encontró ningún párrafo en el string HTML.');
    }
}