import React from 'react'
import { Placeholder } from 'react-bootstrap'
import styles from './NextNewLoading.module.scss'
import NextStoryButton from '../../NextStoryButton/NextStoryButton'
export default function NextNewLoading() {
    return (
        <>
            <NextStoryButton/>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                        <Placeholder xs={8} />
                    </Placeholder>
                </div>
                <div className={`${styles.detail}`}>
                    <Placeholder animation="glow" >
                        <Placeholder xs={7} /> <Placeholder xs={4} />
                        <Placeholder xs={3} /> <Placeholder xs={8} />
                        <Placeholder xs={8} /> <Placeholder xs={3} />
                        <Placeholder xs={9} /> <Placeholder xs={2} />
                        <Placeholder xs={4} /> <Placeholder xs={7} />
                    </Placeholder>
                </div>
            </div>
        </>
    )
}
