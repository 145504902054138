import React from 'react'
import AccordionOption from './AccordionOption/AccordionOption'
import styles from './ContactUs.module.scss'
import SuscribeToForm from './SuscribeToForm/SuscribeToForm'
import { Link } from 'react-router-dom'
//TODO: Que la flecha cambie hacia abajo cuando se depliega un acordion y hacia arriba cuando se cierra en AccordionOption
//TODO: En las Link poner las URI hacia la pagina de de job opotunities en VOLUNTEER
const options = [
  {
    title: 'TELL YOUR STORY',
    description:
      <>
        <p>
          The archive and documentation department collects testimonies from survivors, perpetrators,
          rescuers and elders of the 1994 genocide against the Tutsi for the Genocide Archive of Rwanda.
          These testimonies allow people to share their experiences before, during and after the genocide.
        </p>
        <p>
          To provide your testimony to the Genocide Archive of Rwanda,
          you can contact us online, or visit us at the Kigali Genocide Memorial in Gisozi. The archive office is open from Monday – Friday, 8am – 4pm.
        </p>
      </>,
  },
  {
    title: 'DONATE MATERIALS',
    description:
      <>
        <p>
          The archive and documentation department collects materials relating to the genocide and its victims for the Genocide Archive of Rwanda.
          The types of items often donated include photos, identification cards, lost relatives' belongings, documents and much more.
        </p>
        <p>
          To donate materials to the Genocide Archive of Rwanda,
          you can contact us online, or visit us at the Kigali Genocide Memorial in Gisozi.
          The archive office is open from Monday – Friday, 8am – 4pm.
        </p>
      </>,
  },
  {
    title: 'SUPPORT US',
    description:
      <>
        <p>
          The archive and documentation department relies on the generous support of friends and donors in Rwanda and abroad for the maintenance the Genocide Archive of Rwanda.
          You can support our work through financial contribution, project-based collaboration or expertise sharing.
        </p>
        <p>
          To support the Genocide Archive of Rwanda, you can contact us online, or visit us at the Kigali Genocide Memorial in Gisozi.
          The archive office is open from Monday – Friday, 8am – 4pm.
        </p>
      </>,
  },
  {
    title: 'VOLUNTEER',
    description:
      <>
        <p>
          The archive and documentation department welcomes volunteers to join our
          team in order to contribute and to learn about the work we do for the Genocide Archive of Rwanda.
          The department also welcomes interns and Research Affiliates.
        </p>
        <p>
          If you would like to learn more about our volunteering and internship programmes,click <Link to='/category/Job_Opportunities'>here</Link>. To learn more about our Research Affiliate programme, click <Link to='/category/Job_Opportunities'>here</Link>.
        </p>
        <p>
          You can also contact us online, or visit us at the Kigali Genocide Memorial in Gisozi.
          The archive office is open from Monday – Friday, 8am – 4pm.
        </p>
      </>,
  },
  {
    title: 'SIGN UP FOR UPDATES',
    description:
      <>
        <p>
          Once you register with us, you are automatically signed up for regular updates about the work of Genocide Archive of Rwanda.
        </p>
        <p>
          You can also stay in touch with the team by following us on&nbsp;
          <a href="https://www.facebook.com/genocidearchiverwanda" target="_blank" rel="noreferrer" style={{color: "white"}}>Facebook</a>, <a href="https://www.instagram.com/genocidearchiverwanda/" target="_blank" rel="noreferrer" style={{color: "white"}}>Instagram</a>, <a href="https://twitter.com/GenArchiveRw" target="_blank" rel="noreferrer" style={{color: "white"}}>Twitter</a> and <a href="https://www.youtube.com/user/GenocideArchiveRwand" rel="noreferrer" target="_blank" style={{color: "white"}}>YouTube</a>.
        </p>
        <SuscribeToForm/>
      </>,
  },
  {
    title: 'OPENING HOURS',
    description:
      <>
        <p>
          The archive and documentation department collects materials relating to the genocide and its victims for the Genocide Archive of Rwanda.
          The types of items often donated include photos, identification cards, lost relatives' belongings, documents and much more.
        </p>
        <p>
          To donate materials to the Genocide Archive of Rwanda,
          you can contact us online, or visit us at the Kigali Genocide Memorial in Gisozi.
          The archive office is open from Monday – Friday, 8am – 4pm.
        </p>
      </>,
  }
]
export default function ContactUs() {
  return (
    <div className={`${styles.contactUs}`}>
      <div className={`${styles.title}`}>
        Get Involved
      </div>
      <div className={`${styles.subtitle}`}>
        There are many ways to contribute to the Genocide Archive of Rwanda:
      </div>
      <AccordionOption options={options} />
    </div>
  )
}
