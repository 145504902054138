import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './NextStoryButton.module.scss'
import { useNavigate } from 'react-router-dom';
export default function NextStoryButton(props) {
    const navigate = useNavigate();
    const { nav } = props;
    return (
        <Button className={`${styles.btn}`} onClick={()=>navigate(nav)}>
            <ChevronRight className={`${styles.icon}`} />
            NEXT STORY
        </Button>
    )
}
