import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useCategoryId(props) {
	const { alias } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetTaxonmyId(
				{
					params: {
						id: alias
						//contenttype: 'category_video_tami',
					},
				}
			)
			.then((response) => {
				//console.log("Respuesta")
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				console.log("Errorrrrr")
				console.log(Error)
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {

				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
		//},[]);
	}, [alias]);

	return pageContent;

}

export function useCategoryTreePage(props) {
	const { category, page, respp, location, tid, sortby } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		const apiS = () => {
			apiService
				.GetCategoryPagination(
					{
						params: {
							id: tid,
							category: category,
							sortby: sortby,
							page: page,
							//contenttype: 'category_video_tami',
						},
					}
				)
				.then((response) => {
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: false,
						payload: {
							...response.data,
						},
					}));
				})
				.catch((error) => {
					console.log("ErroRRRRRR")
					console.log(Error)
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: true,
						isLoading: false,
						error: error?.message,
					}));
				})
				.finally(() => {

					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isLoading: false,
					}));
				});
		}
		if (tid != undefined) {
			apiS();
		}
		//},[]);
	}, [category, page, sortby, tid]);

	return pageContent;

}

export function useCategoryBrother(props){
	const { category, page, respp, location, tid, sortby, fatherid } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		const apiS = () => {
			apiService
				.GetSearchQuery(
					{
						params: {
							category: "category-"+fatherid,
							contenttype: 'category',
						},
					}
				)
				.then((response) => {
					console.log("Respuesta")
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: false,
						payload: {
							...response.data,
						},
					}));
				})
				.catch((error) => {
					console.log("Error")
					console.log(Error)
					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isError: true,
						error: error?.message,
					}));
				})
				.finally(() => {

					setPageContent((prevPageContent) => ({
						...prevPageContent,
						isLoading: false,
					}));
				});
		}
		if (fatherid != undefined) {
			apiS();
		}
		//},[]);
	}, [fatherid]);

	return pageContent;

}

export function useTaxonomyTitle(props) {
	const { tid } = props;
	const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});
	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetTaxonomyTitle(
				{
					urlParams: {
						tid: tid,
					}
				}
			)
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [tid]);

	return pageContent;
}
