import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './VictimsDatabaseView.module.scss'
import { Link } from 'react-router-dom'
export default function VictimsDatabaseView() {
    return (
        <Container className={`${styles.cont}`}>
            <p>
                The Genocide Archive of Rwanda's <strong>Victims' Database</strong> is currently under development.
                You can contribute to this project by providing pictures of relatives and friends you lost in the 1994 genocide against the Tutsi.
            </p>
            <p>
                to find out how else you can contribute to the Victims' Database project, get in touch with our archive and documentation department online <Link className={`${styles.link}`} to="/contact_us">here</Link>
                , or visit us at the Kigali Genocide Memorial. The Archive and Documentation center is open 8am to 5pm, Monday to Friday.
            </p>
        </Container>
    )
}
