import { useLastNewsPage } from 'modules/website/hooks/useLastNewsPage';
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import NewsImageCard from '../NewsImageCard/NewsImageCard';
import styles from './ImageTopNews.module.scss'
export default function ImageTopNews() {
    const { isLoading, isError, payload, error } = useLastNewsPage();
    //(payload)
    return (
        <div>
            {
                isLoading && ("Loading...")
            }
            {
                isError && ("Error")

            }
            {
                payload && (
                    <Row className={`${styles.row}`}>
                        <Col md={12} lg={7} className={`${styles.col}`}>
                            <NewsImageCard
                                title={payload[0].title}
                                image={payload[0].field_image}
                                date={payload[0].field_news_date}
                                nav={payload[0].view_node.replace('/api/glifos','/news')}
                            />
                        </Col>
                        <Col md={5} lg={5}>
                            <Row>
                                <Col md={12} lg={12}>
                                    <NewsImageCard
                                        title={payload[1].title}
                                        image={payload[1].field_image}
                                        secondType
                                        nav={payload[1].view_node.replace('/api/glifos','/news')}
                                    />
                                </Col>
                                <Col md={12} lg={12}>
                                    <NewsImageCard
                                        title={payload[2].title}
                                        image={payload[2].field_image}
                                        secondType
                                        nav={payload[2].view_node.replace('/api/glifos','/news')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }

        </div>
    )
}
