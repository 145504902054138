import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './OptionCardLoading.module.scss'
import { useNavigate } from 'react-router-dom';
export default function OptionCardLoading(props) {
    const { image, title, children, link } = props;
    const navigate = useNavigate();
    return (
        <Card className={`${styles.card}`}>
            <div className={`${styles.top}`}>
                <Placeholder animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>
                <div className={`${styles.srDescEffect}`} />
            </div>
            <Card.Body className={`${styles.body}`}>
                <Card.Title className={`${styles.title}`}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={5} />
                    </Placeholder>
                </Card.Title>
                <Card.Text className={`${styles.description}`}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
