import React, { useState } from 'react'
import styles from './GLifosMetadata.module.scss'
export default function GLifosMetadata(props) {
    const { metadata } = props;
    //console.log(metadata);
    const [IndexShow, setIndexShow] = useState(0);
    const [chmetadata, setChmetadta] = useState(
        metadata ? (
            metadata.length === 1 ? metadata[0] : metadata[0]
        ) : (
            null
        )
    );

    const handleChannelMetadata = (index) => {
        setChmetadta(metadata[index]);
        setIndexShow(index);
    };

    if (metadata?.length === 1) {
        const arr = Object.entries(metadata[0]);
        return (
            <div className={`${styles.main}`}>
                {
                    arr.map((item, index) =>
                    (
                        <div className={`${styles.item}`} key={index}>
                            <div className={`${styles.title}`}>
                                {item[0]}:
                            </div>
                            <div className={`${styles.value}`}>
                                {
                                    Array.isArray(item[1]) ? (
                                        item[1].map((item, index) => (
                                            <div className={`${styles.value}`} key={index}>
                                                {item}
                                            </div>
                                        ))
                                    ) : (
                                        typeof item[1] === 'object' ? (
                                            Object.entries(item[1]).map((item, index) => {
                                                return (
                                                    <div className={`${styles.value}`}>
                                                        {item[0]}: {item[1]}
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            item[1]
                                        )
                                    )
                                }
                            </div>
                        </div>
                        /* console.log(typeof item[1], item[1]) */
                    )
                    )
                }
            </div>
        )
    } else {
        if (metadata) {
            return (
                <div className={`${styles.main}`}>
                    <div className={`${styles.options}`}>
                        {
                            metadata.map((item, index) => {
                                //const arr = Object.entries(item);
                                return (
                                    index !== IndexShow && (
                                        <a onClick={() => handleChannelMetadata(index)}>
                                            {'['}show {item.lang ? item.lang : item.language} metadata{']'}
                                        </a>
                                    )
                                )
                            })
                        }
                    </div>
                    {
                        chmetadata && (
                            <div className={`${styles.main}`}>
                                {
                                    Object.entries(chmetadata).map((item, index) =>
                                    (
                                        <div className={`${styles.item}`} key={index}>
                                            <div className={`${styles.title}`}>
                                                {item[0]}:
                                            </div>
                                            <div className={`${styles.value}`}>
                                                {
                                                    Array.isArray(item[1]) ? (
                                                        item[1].map((item, index) => (
                                                            <div className={`${styles.value}`} key={index}>
                                                                {item}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        typeof item[1] === 'object' ? (
                                                            Object.entries(item[1]).map((item, index) => {
                                                                return (
                                                                    <div className={`${styles.value}`}>
                                                                        {item[0]}: {item[1]}
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            item[1]
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                        /* console.log(typeof item[1], item[1]) */
                                    )
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            )
        } else(
            <div>
                No metadata
            </div>
        )

    }
}

/* 
item[0] !== 'value' && item[1].value !== null && item[1].value !== '' && item[1].value !== 'unauthorized' ? (
                        <div className={`${styles.item}`}>
                            <div className={`${styles.title}`}>
                                {item[0]}:
                            </div>
                            <div className={`${styles.value}`}>
                                {item[1].value}
                            </div>
                        </div>
                    ) : (
                        item[1].value === '' && (
                            item[1].keyword && (
                                <div className={`${styles.item}`}>
                                    <div className={`${styles.title}`}>
                                        {item[0]}:
                                    </div>
                                    <div className={`${styles.value}`}>
                                        {Array.isArray(item[1].keyword) ? (
                                            item[1].keyword.map((item, index) => {
                                                return (
                                                    <div className={`${styles.value}`}>
                                                        {item.value}
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className={`${styles.value}`}>
                                                {item[1].keyword.value}
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )
                        )
                    )

*/
