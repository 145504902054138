import OptionsCategories from 'modules/website/components/News/OptionsCategories/OptionsCategories'
import TopPart from 'modules/website/components/TopPart/TopPart'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './NewsView.module.scss'
import MostRecentNews from 'modules/website/components/News/MostRecentNews/MostRecentNews'
import SuggestedNews from 'modules/website/components/News/SuggestedNews/SuggestedNews'
import ImageTopNews from 'modules/website/components/News/ImageTopNews/ImageTopNews'

export default function NewsView() {
    
    const breadC = [
        {
            'title': 'Home',
            'path': '/',
        },
        {
            'title': 'About',
            'path': '/about_us',
        },
        {
            'title': 'Updates',
            'path': '',
        },
    ]
    const title = 'UPDATES'
  return (
    <div>
        <TopPart breadC={breadC} title={title} />
        <OptionsCategories/>
        <div>
            <ImageTopNews/>
        </div>
        <div className={`${styles.line}`}/>
        <div className={`${styles.News}`}>
            <Row className={`${styles.row}`}>
                <Col md={12} lg={6}>
                    <MostRecentNews/>
                </Col>
                <Col md={12} lg={6}>
                    <SuggestedNews/>
                </Col>
            </Row>
        </div>
    </div>
  )
}
