import TopPart from 'modules/website/components/TopPart/TopPart';
import React from 'react';
import styles from '../PostGenocideReconstructionView/post_genocide.module.css';
export default function YoungPeoplePeaceView() {
	return (
		<div className={`${styles['young-peace-bg']}`}>
			<TopPart
				breadC={[
					{
						title: 'About Us',
						path: '/collections',
					},
					{
						title: 'Young People and Peace Building',
						path: '',
					},
				]}
				title='YOUNG PEOPLE AND  PEACE BUILDING'
				description='After the 1994 genocide against the Tutsi in Rwanda, a
            number of youth led organisations have been set up in the country to support the young survivors and orphans
            who where left alone and homeless. These organisations aim to inspire and empower young people to be future
            leaders in the fight against genocide, supporting each other and building a united Rwanda.'
			/>
			<div
				className={`${styles['young-peace-items-list']} ${styles['col-xs-12']}`}
			>
				<div
					className={`${styles['young-peace-item-row']} ${styles['col-xs-12']}`}
				>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								National Youth Council
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								National Youth Council of Rwanda facilitates and
								encourages young people to participate in
								socio-economic development and to build a
								peaceful and prosperous society.
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/National_Youth_Council'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								AERG
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								<p>
									The Association of Student Genocide
									Survivors (known as AERG) is a group of
									survivors of the genocide who connect and
									represent student survivors across Rwanda.
								</p>
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-red']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/AERG_programmes'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								Peace and Love Proclaimers
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								<p>
									Peace and Love Proclaimers (PLP) empowers
									students to take responsibility for
									establishing a more unified nation within
									their respective countries.
								</p>
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/Peace_and_Love_Proclaimers'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${styles['young-peace-item-row']} ${styles['col-xs-12']}`}
				>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								IDebate
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								<p>
									IDebate Rwanda is a youth led organisation
									that uses debate to change the lives of
									young Africans - by teaching them how to
									solve problems critically and creatively.
								</p>
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-red']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/Idebate'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								Aegis Youth Department
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								<p>
									The Aegis Youth Department coordinates Aegis
									Trust youth activities related&nbsp;to
									genocide prevention and peace building.
								</p>
								<p></p>
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/Aegis_Youth_Department'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
					<div
						className={`${styles['young-peace-item-cont']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['young-peace-item']}`}>
							<div className={`${styles['young-peace-title']}`}>
								GAERG
							</div>
							<div
								className={`${styles['young-peace-description']}`}
							>
								GAERG (Groupe des Anciens Etudiants et
								El&egrave;ves Rescap&eacute;s du
								G&eacute;nocide)
								<strong>
									was formed by graduate students and alumni
									survivors of the genocide.
								</strong>
							</div>
							<div className={`${styles['young-peace-link']}`}>
								<a
									className={`${styles['btn']} ${styles['btn-red']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
									href='/category/GAERG'
									target='_blank'
								>
									VIEW COLLECTION
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className={`${styles['young-peace-lower']} ${styles['col-xs-12']}`}
			>
				<div className={`${styles['young-peace-lower-white']}`}>
					<div
						className={`${styles['yp-lower-winfo']} ${styles['col-md-3']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['yp-lower-wtitle']}`}>
							<strong>EXPLORE AND SHARE</strong>
						</div>
						<div className={`${styles['yp-lower-wdesc']}`}>
							<strong>
								Explore collection of creative materials that
								were submitted by the youth in Rwanda and
								internationally related to the 1994 genocide
								against the Tutsi in Rwanda.
							</strong>
						</div>
					</div>
					<div
						className={`${styles['yp-lower-wlinks']} ${styles['col-md-9']} ${styles['col-xs-12']}`}
					>
						<div
							className={`${styles['yp-lower-wlink-row']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
						>
							<div
								className={`${styles['yp-lower-wlink']} ${styles['col-xs-6']}`}
							>
								<strong>
									<a
										className={`${styles['yp-white-link']} ${styles['yp-blog']} ${styles['post-link-a']}`}
										href='/blog/'
										target='_blank'
									>
										BLOG
									</a>{' '}
								</strong>
							</div>
							<div
								className={`${styles['yp-lower-wlink']} ${styles['col-xs-6']}`}
							>
								<strong>
									<a
										className={`${styles['yp-white-link']} ${styles['yp-messages']} ${styles['post-link-a']}`}
										href='/category/Messages'
										target='_blank'
									>
										MESSAGES
									</a>{' '}
								</strong>
							</div>
						</div>
						<div
							className={`${styles['yp-lower-wlink-row']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
						>
							<div
								className={`${styles['yp-lower-wlink']} ${styles['col-xs-6']}`}
							>
								<div
									className={`${styles['yp-white-link']} ${styles['yp-art']}`}
									href='/category/Artwork'
									target='_blank'
								>
									<a
										href='/category/Artwork'
										target='_blank'
										className={`${styles['post-link-a']}`}
									>
										<strong>ARTWORK</strong>
									</a>
								</div>
							</div>
							<div
								className={`${styles['yp-lower-wlink']} ${styles['col-xs-6']}`}
							>
								<strong>
									<a
										className={`${styles['yp-white-link']} ${styles['yp-poems']} ${styles['post-link-a']}`}
										href='/category/Poems'
										target='_blank'
									>
										POEMS
									</a>{' '}
								</strong>
							</div>
						</div>
					</div>
					<div style={{ clear: 'both' }}>
						<div style={{ display: 'none' }}>
							<strong>&amp;nbsp;</strong>
						</div>
					</div>
				</div>
				<div
					className={`${styles['young-peace-light-blue']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
				>
					<div className={`${styles['yp-blue-title']}`}>
						<strong>Youth in Action</strong>
					</div>
					<div
						className={`${styles['yp-lblue-item-col']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Walk to Remember</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<p>
									<strong>
										Walk to Remember is a series of
										activities created to unite the
										international community and empower
										young people to take action against
										genocide and mass atrocities.
									</strong>
								</p>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-lblue']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Walk_to_Remember'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Umuganda</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<p>
									<strong>
										On the last Saturday of each month,
										millions of Rwandans work together to
										improve their communities. This
										programme is called Umuganda, meaning
										&lsquo;coming together in common
										purpose&rsquo;.
									</strong>
								</p>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Umuganda'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Film and Photography</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<strong
									style={{
										lineHeight: '19.5px',
										backgroundColor: 'rgb(0, 59, 98)',
									}}
								>
									Young people of Rwanda share the stories
									from their communities through
									photography.&nbsp;
								</strong>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-lblue']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Film_and_Photography'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Campaigns</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<p>
									<strong>
										Young people run campaigns to raise
										awareness about the needs of survivors
										and raise funds to support peace
										building initiatives.
									</strong>
								</p>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Campaigns'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
					</div>
					<div
						className={`${styles['yp-lblue-item-col']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
					>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>A Million Voices</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<p>
									<strong>
										A Million Voices is your chance to share
										a message of remembrance, renewed hope
										for the future and unite your generation
										around shared human values.
									</strong>
								</p>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/A_Million_Voices'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Exhibitions</strong>
							</div>
							<p>
								<strong>
									Young people of Rwanda share messages of
									peace through paintings, drawings and poems.
									Their art pieces have been part of a
									travelling exhibition that has visited
									districts across Rwanda over a period of
									three months.
								</strong>
							</p>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-lblue']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Exhibitions'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
						<div className={`${styles['yp-lblue-item']}`}>
							<div className={`${styles['yp-lblue-title']}`}>
								<strong>Debate and Dialogue</strong>
							</div>
							<div className={`${styles['yp-lblue-desc']}`}>
								<strong>
									Young people get together to tackle issues
									that affect their communities through
									analytical thinking, critical discussions
									and transformative solutions.
								</strong>
							</div>
							<div className={`${styles['yp-lblue-button']}`}>
								<strong>
									<a
										className={`${styles['btn']} ${styles['btn-yellow']} ${styles['btn-noarr']} ${styles['post-link-a']}`}
										href='/category/Debate_and_Dialogue'
									>
										LEARN MORE
									</a>{' '}
								</strong>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${styles['young-peace-dark-blue']} ${styles['col-md-6']} ${styles['col-xs-12']}`}
				>
					<div className={`${styles['yp-blue-title']}`}>
						<strong>Themes</strong>
					</div>
					<strong>
						<a
							className={`${styles['yp-dblue-link']} ${styles['post-link-a']}`}
							href='/category/Rememberance'
						>
							Rememberance
						</a>{' '}
						<a
							className={`${styles['yp-dblue-link']} ${styles['post-link-a']}`}
							href='/category/Peace_Building'
						>
							Peace Building
						</a>{' '}
						<a
							className={`${styles['yp-dblue-link']} ${styles['post-link-a']}`}
							href='/category/Empowering'
						>
							Empowering
						</a>{' '}
						<a
							className={`${styles['yp-dblue-link']} ${styles['post-link-a']}`}
							href='/category/Healing'
						>
							Healing
						</a>{' '}
						<a
							className={`${styles['yp-dblue-link']} ${styles['post-link-a']}`}
							href='/category/Reconciliation'
						>
							Reconciliation
						</a>{' '}
					</strong>
				</div>
			</div>
			<div style={{ clear: 'both' }}>
				<div style={{ display: 'none' }}>
					<strong>SPACE</strong>
				</div>
			</div>
		</div>
	);
}
