import React from 'react'
import SearchSquareForm from '../SearchSquareForm/SearchSquareForm'

export default function SideBarAudio() {
  return (
    <div>
        <SearchSquareForm
        title='Search Audio'
        description= 'Search through this collection for particular items by proving as many key words and relevant information as possible for better results.'
        bkcolor='#ff9201'
        btncolor='#bc1000'
        //type='audio'
        />
    </div>
  )
}
