import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Modal, Overlay, Row, Tooltip } from 'react-bootstrap';
import styles from './ShareModal.module.scss';
import { File, Files } from 'react-bootstrap-icons';
import facebook from 'assets/img/wx/fb-shr.png';
import share from 'assets/img/wx/share2.png';
import Twitter from 'assets/img/wx/twitter-shr.png';
import digg from 'assets/img/wx/digg.png';
import delicious from 'assets/img/wx/delicious.png';
import { useParams } from 'react-router-dom';

export default function ShareModal(props) {
    const { show, handleClose, start, end, title } = props;
    const [link, setLink] = useState(window.location.href);

    const [showOverLay, setShowOverlay] = useState(false);
    const target = useRef(null);
    const [msg, setMsg] = useState("");
    const { alias, alias2 } = useParams();

    const SocialMediaOptions = [
        {
            title: 'Facebook',
            icon: facebook,
            link: 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(link) + '$&t=' + encodeURIComponent(title),
        },

        {
            title: 'Twitter',
            icon: Twitter,
            link: 'http://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(link),
        },
        {
            title: 'Digg',
            icon: digg,
            link: 'http://digg.com/submit?phase=2&url=' + encodeURIComponent(link) + '$&title=' + encodeURIComponent(title) + '$&bodytext=&topic=',
        },
        {
            title: 'Delicious',
            icon: delicious,
            link: 'http://del.icio.us/post?noui=&notes=&tags=&v=4&jump=close&url=' + encodeURIComponent(link) + '$&title=' + encodeURIComponent(title),
        },

    ]

    /*  useEffect(() => {
         const urlParams = new URLSearchParams(window.location.search);
         const b = urlParams.get('b');
         const e = urlParams.get('e');
         if (b && e) {
             if (start > end) {
                 let link = window.location.href;
                 link = link.replace("?b=" + b + "&e=" + e, "?b=" + start)
                 setLink(link)
             } else {
                 let link = window.location.href;
                 link = link.replace("?b=" + b + "&e=" + e, "?b=" + start + "&e=" + end)
                 setLink(link)
             }
         } else if (b && !e) {
             if (start < end) {
                 let link = window.location.href;
                 link = link.replace("?b=" + b, "?b=" + start + "&e=" + end)
                 setLink(link)
             } else {
                 let link = window.location.href;
                 link = link.replace("?b=" + b, "?b=" + start)
                 setLink(link)
             }
         } else {
             const link = window.location.href;
             if (start > end) {
                 setLink(link + "?b=" + start)
             } else {
 
                 setLink(link + "?b=" + start + "&e=" + end)
             }
         }
     }, [start, end]) */

    const inputRef = useRef(null);

    const copyToClipboard = () => {
        if (inputRef.current) {
            //inputRef.current.select();
            navigator.clipboard.writeText(inputRef.current.value)
                .then(() => {
                    //console.log('Copied to clipboard');
                    setMsg("Copied to clipboard");
                    setShowOverlay(true);
                    setTimeout(() => {
                        //console.log("set show to false")
                        setShowOverlay(false);

                    }, 1000)

                })
                .catch((error) => {
                    console.error('Error copying to clipboard:', error);
                    setShowOverlay(true);
                    setMsg("Error copying to clipboard");
                    setTimeout(() => {
                        //console.log("set show to false")
                        setShowOverlay(false);

                    }, 1000)
                });
        }
    };

    const openPopup = (url) => {
        window.open(url, 'popup', 'width=800,height=600');
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton
                style={{ borderBottom: 'none', backgroundColor: '#153764' }}
            >
                <Modal.Title style={{ color: 'white', fontSize: '24px' }} className='d-flex align-items-center'>
                    <img src={share} style={{ width: '25px', marginRight: '10px', marginLeft: '10px' }} />
                    Share this video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${styles.mBody}`}>
                <Row>
                    <Col md={12} lg={6}>
                        <div className={`${styles.shareLink}`}>
                            <div className={`${styles.title}`}>
                                Link
                            </div>
                            <div className={`${styles.value}`}>
                                <input id='input-link' ref={inputRef} type="text" defaultValue={link} />
                                <Button className={`${styles.btn}`} ref={target} onClick={copyToClipboard} >
                                    <Files />
                                </Button>
                                <Overlay target={target.current} show={showOverLay} placement="top">
                                    {(props) => (
                                        <Tooltip id="overlay-example" {...props}>
                                            {msg}
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className={`${styles.socialLinks}`}>
                            <div className={`${styles.title}`}>
                                Social
                            </div>
                            <div className={`${styles.value}`}>
                                {SocialMediaOptions.map((item, index) => {
                                    return (
                                        <div className={`${styles.btn}`} title={item.title} key={index}>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openPopup(item.link);
                                                }}
                                            >
                                                <img src={item.icon} className={`${styles.icon}`} />
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={`${styles.shareemail}`}>
                            <div className={`${styles.title}`}>Send E-mail</div>
                            <div>
                                {/* <iframe
                                    id="mailiframe"
                                    mailertitle={title}
                                    mailerserver="https://genocidearchiverwanda.org.rw"
                                    mailerfold=""
                                    mailerpage={`${alias}${alias2 ? "/" + alias2 : ""}`}
                                    g_player="video1"
                                    src="https://genocidearchiverwanda.org.rw/extensions/GSMMail/index.php?videourl=https%3A%2F%2Fgenocidearchiverwanda.org.rw%2Findex.php%3Ftitle%3DAccountability%3A_What_Can_Legislative_Bodies_Do%3F%26p%3Dvideo1%26b%3D0&amp;isvideo=true&amp;nom=Accountability%20What%20Can%20Legislative%20Bodies%20Do%20%20%20&amp;titulo=Accountability%20What%20Can%20Legislative%20Bodies%20Do%20%20%20&amp;view=iframe" 
                                    //src={"https://genocidearchiverwanda.org.rw/extensions/GSMMail/index.php?videourl=https%3A%2F%2Fgenocidearchiverwanda.org.rw%2Findex.php%3Ftitle%3D" + `${alias}${alias2 ? "/" + alias2 : ""}` + "&amp;isvideo=true&amp;nom=" + title + "&amp;titulo=" + title + "&amp;view=iframe"}
                                    style={{ display: 'inline-block', overflow: 'hidden', height: '310px' }}>
                                </iframe> */}
                                <iframe
                                    id="mailiframe"
                                    mailertitle={title}
                                    mailerserver="https://genocidearchiverwanda.org.rw"
                                    mailerfold=""
                                    mailerpage={`${alias}${alias2 ? "/" + alias2 : ""}`}
                                    g_player="video1"
                                    src={`https://genocidearchiverwanda.org.rw/extensions/GSMMail/index.php?videourl=https%3A%2F%2Fgenocidearchiverwanda.org.rw%2Findex.php%3Ftitle%3D${encodeURIComponent(alias)}${alias2 ? "%2F" + encodeURIComponent(alias2) : ""}&isvideo=true&nom=${encodeURIComponent(title)}&titulo=${encodeURIComponent(title)}&view=iframe`}
                                    style={{ display: 'inline-block', overflow: 'hidden', height: '310px' }}
                                ></iframe>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* <div className={`${styles.embedCode}`}>
                    <div className={`${styles.title}`}>
                        Embed Code
                    </div>
                    <div className={`${styles.value}`}>
                        <textarea id='input-link' type="text" />
                    </div>
                </div> */}

            </Modal.Body>
        </Modal>
    )
}
