import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import MessageUsForm from './MessageUsForm/MessageUsForm'
import styles from './SideBarContactUs.module.scss'

export default function SideBarContactUs() {
    return (
        <div className={`${styles.sideBar}`}>
            <div className={`${styles.title}`}>Tel (+250)784651051</div>
            <ul>
                <li>
                    <a className={`${styles.link}`} href='mailto:info@genocidearchiverwanda.org.rw' target="_blank" rel="noreferrer" >info@genocidearchiverwanda.org.rw</a>
                </li>
                <li>
                    <a className={`${styles.link}`} href="http://www.genocidearchiverwanda.org.rw" target="_blank" rel="noreferrer" >www.genocidearchiverwanda.org.rw</a>
                </li>
                <li>
                    <a className={`${styles.link}`} href="http://www.aegistrust.org" target="_blank" rel="noreferrer" >www.aegistrust.org</a>
                </li>
            </ul>
            <ul className={`${styles.info}`}>
                <li>
                    Genocide Archive of Rwanda
                </li>
                <li>
                    Aegis Trust - Rwanda Office
                </li>
                <li>
                    PO Box 7251, Kigali, Rwanda
                </li>
            </ul>
            <a href="https://www.google.rw/maps/place/Kigali+Genocide+Memorial/@-1.930882,30.060721,17z/data=!3m1!4b1!4m2!3m1!1s0x19dca40387460893:0x520a96e55654da22?hl=en" target="_blank" rel="noreferrer">
                <Button className={`${styles.btn}`}>
                    <ChevronRight className={`${styles.icon}`} />
                    View Map
                </Button>
            </a>
            <MessageUsForm />
        </div>
    )
}
