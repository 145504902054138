import React, { useEffect } from 'react';
import styles from './ContentCard.module.scss';
import { useState } from "react";
import { Col, Card, Placeholder } from "react-bootstrap";
import notFoundAH from "assets/img/not_found.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import { Link } from 'react-router-dom';

export default function ContentCard(props) {
    const { title, description, image, nav, searchable } = props;
    const [recordData, setRedordData] = useState({});
    const [loading, setLoading] = useState({ img: true, data: true });
    const [notFoundImage, setNotFoud] = useState("");
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    useEffect(() => {
        function getImage(element) {
            if (!element) {
                return notFoundImage;
            }
            if (element === "NULL") {
                return notFoundImage;
            }
            return element;
        }
        /* function getUrlPath(element, whitDomain = false) {
            /* if (element.contenttype === "page") {
              return ApiURLs.cirmaDomainName + element.id.substring(1);
            }
            if (element.contenttype === "news") {
              return ApiURLs.cirmaDomainName+ "noticia/" + element.id;
            }
            if (element?.repository === "CIRMA-BIB") {
              return whitDomain
                ? ApiURLs.libraryDomainName + "opac/record/" + element.id
                : "opac/record/" + element.id;
            }
            if (element?.contenttype === "library") {
              return whitDomain
                ? ApiURLs.libraryDomainName + "opac/record/" + element.id
                : "opac/record/" + element.id;
            }
            return whitDomain
              ? ApiURLs.cirmaDomainName + "glifos/item/" + element.id
              : "glifos/item/" + element.id; 
            return ApiURLs.baseURL + element.id;
        } */
        var temData = image;
        /* if (Array.isArray(temData?.authors)) {
            temData.authors = temData?.authors[0];
        } */
        //temData.urlPath = getUrlPath(temData, true);
        temData = getImage(temData, true);

        setRedordData(temData);
        setLoading({ data: false });
        /* if (props?.data?.contenttype === "item") {
            setNotFoud(notFoundAH);
        } else if (props?.data?.contenttype === "library") {
            setNotFoud(notFoundLib);
        } else { */
        setNotFoud(notFoundAH);
        /* } */
    }, [notFoundImage, props]);

    function proccessImage(image) {
        let imageP = image;
        if (image?.includes("api/glifos/glifos/file/get///default/sites/default/files/styles/large/public") === true) {
            imageP = image.replace("api/glifos/glifos/file/get///default/sites/default/files/styles/large/public", "api/glifos/sites/default/files");
        } else if (image?.includes("api/glifos//default/sites/default/files/styles/large/public") === true) {
            imageP = image.replace("api/glifos//default/sites/default/files/styles/large/public", "api/glifos/sites/default/files");
        } else if (image?.includes("/glifos/glifos/file/get//sites/default/files/") === true) {
            imageP = image.replace("/glifos/glifos/file/get//sites/default/files/", "/glifos/sites/default/files/");
        } else if (image?.includes("/api/api") === true) {
            imageP = image.replace("/api/api", "/api");
        } else if (image?.includes("//default") === true) {
            imageP = image.replace("//default", "/glifos");
        } 
        return imageP;
    }

    /* return (
        <Link className={`${styles.card} link-underline-opacity-0`} to={nav} >
            <div className={`${styles.image}`}
                style={{width:width&&(width), height:height&&(height)}}
            >
                <LazyLoadImage
                    className="card-img-top cont"
                    alt={props.data?.id}
                    src={proccessImage(image)} // use normal
                    effect="blur"
                    delayTime={1}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        //currentTarget.src = notFoundImage;
                        setHeight("0px");
                        setWidth("0px")
                    }}
                />
            </div>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>
                    {title}
                </div>
                <div className={`${styles.description}`}>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
        </Link>
    ) */
    return (
        <Link className={`${styles.card} link-underline-opacity-0`} to={nav}>
            <div className={`${styles.image}`} style={{ width: width, height: height }}>
                <LazyLoadImage
                    className="card-img-top cont"
                    alt={title}
                    src={proccessImage(image)}
                    effect="blur"
                    delayTime={1}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        setHeight("0px");
                        setWidth("0px");
                    }}
                />
            </div>
            {/* <div className={`${styles.content}`}> */}
                <div className={`${styles.title}`}>
                    {title}
                </div>
                    <div dangerouslySetInnerHTML={{ __html: description }} className={`${styles.description}`}/>
                    {
                            searchable === 'registered' && (
                                <div className={`${styles.registered}`}>
                                    Registered users only
                                </div>
                            )
                        }
            {/* </div> */}
        </Link>
    );
}
