import ArticleLoading from 'modules/website/components/Article/ArticleLoading/ArticleLoading';
import { useInformationPage } from 'modules/website/hooks/useInformationPage';
import React from 'react'
import { Container } from 'react-bootstrap';
import NotFoundView from '../NotFoundView/NotFoundView';
import Article from 'modules/website/components/Article/Article';
import { useLocation } from 'react-router-dom';
import styles from './ResearchInfoView.module.scss'

export default function ResearchInfoView() {
    const location = useLocation();
    const alias = location.pathname.replace('/research/', '');
    const { isLoading, isError, payload, error } = useInformationPage({
        alias: alias,
    });
    return (
        <>

            {isLoading &&
                <>
                    <div className={`${styles.title}`}>RESEARCH</div>
                    <Container>
                        <ArticleLoading />
                    </Container>
                </>
            }
            {!isLoading && isError && <NotFoundView />}
            {!isLoading && !isError && payload && (
                <>
                    <div className={`${styles.title}`}>RESEARCH</div>
                    <Container>
                        <Article title={payload.title} mediaSharing>
                            <div dangerouslySetInnerHTML={{ __html: payload.body }} />
                        </Article>
                    </Container>
                </>
            )}

        </>
    )
}
