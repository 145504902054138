import React from 'react'
import BCrumb from './BreadCrumb/BCrumb'
import Cards from './Cards/Cards'
import OrderBy from './OrderBy/OrderBy'
import styles from './Research.module.scss'
import OptionCardLoading from './Cards/OptionCard/OptionCardLoading/OptionCardLoading'
export default function Research() {
    return (
        <div>
            <div className={`${styles.container}`}>
                <BCrumb/>
                <div className={`${styles.title}`}>RESEARCH</div>
                <div className={`${styles.description}`}>
                    <p>The Genocide Archive of Rwanda welcomes researcher students, teachers and other visitors to contribute to research and to assist them in getting content.</p>
                </div>
                <div className={`${styles.orderBy}`}>
                    <OrderBy/>
                </div>
                <Cards/>

            </div>
        </div>
    )
}
