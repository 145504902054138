import WhatWeDoTitle from "modules/website/components/WhatWeDo/WhatWeDoTitle/WhatWeDoTitle";
import { useState } from "react";

export const ContactUsTop = {
	title: 'CONTACT US',
	description:
		'The Genocide Archive of Rwanda collections are housed at the Kigali Genocide Memorial. Visitors are advised to call at least three days in advance to book for a visiting session of the physical archive. We also encourage visitors to first visit the Kigali Genocide Memorial.',
	breadC: [
		{
			title: 'About Us',
			path: '/about_us',
		},
		{
			title: 'Contact Us',
			path: '/contact-us',
		},
	],
};

//TODO: ver como cambiar el ultimo de breadC con el titulo de la noticia
//export const [lastBreadNew, setLasytBreadNew] = useState('');
export const NewTop = {
	title: 'News',
	breadC: [
		{
			title: 'Home',
			path: '/',
		},
		{
			title: 'News',
			path: '/news',
		},
		{
			title: '',
			path: '',
		},
	],
};

export const AboutUsInfoTop = {
	title: 'ABOUT US',
};

export const GlifosRichMediaTop = {
	title: 'GLIFOS RICH MEDIA',
	description: 'Author: Genocide Archive of Rwanda',
	breadC: [
		{
			title: 'Home',
			path: '/',
		},
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Example Glifos Rich Media',
			path: '',
		},
	],
};

export const GlifosDigitalArchiveTop = {
	title: "La France N'est Nullement Impliquée Dans Le Génocide Au Rwanda",
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Reports',
			path: '/reports',
		},
		{
			title: "La France N'est Nullement Impliquée Dans Le Génocide Au Rwanda",
			path: '',
		},
	],
};

export const WhatWeDoTop = {
	title: <WhatWeDoTitle />,
	breadC: [
		{
			title: 'Home',
			path: '/',
		},
		{
			title: 'About Us',
			path: '/about_us',
		},
		{
			title: 'What We Do',
			path: '/what_we_do',
		},
	],
};

export const VideosTop = {
	title: 'Videos',
	description: 'The Genocide Archive of Rwanda collections feature videos related to the 1994 genocide against the Tutsi in Rwanda. Watch videos of testimonies, Gacaca court proceedings, confessions, documentaries, TV news reports, commemoration ceremonies and more.',
	warning: 'Caution: Some videos may contain sensitive content.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Videos',
			path: '',
		},
	],
};

export const TestimoniesTop = {
	title: 'TESTIMONIES',
	description: 'The Genocide Archive of Rwanda collections contain over one hundred testimonies from survivors, rescuers, elders and perpetrators of the 1994 genocide against the Tutsi. Please be aware some testimonies contain sensitive content.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Testimonies',
			path: '',
		},
	],
};

export const DocumentsTop = {
	title: 'Documents',
	description: 'The Genocide Archive of Rwanda collections contain text based documents related to the 1994 genocide against the Tutsi in Rwanda. Read through these collections that include newspapers and articles, correspondences, reports and more.',
	warning: 'Caution: Some documents may contain sensitive content.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Documents',
			path: '',
		},
	],
};

export const AudioTop = {
	title: 'Audio',
	description: 'The Genocide Archive of Rwanda collections feature audio recordings related to the 1994 genocide against the Tutsi in Rwanda. Listen to speeches, songs and radio broadcasts that were aired locally and internationally, before and during the genocide.',
	warning: 'Caution: Some audio may contain sensitive content.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Audio',
			path: '',
		},
	],
}

export const FeaturedTop = {
	title: 'FEATURED',
	description: 'Explore new materials and suggested collection from our archive team.',
	breadC: [
		{
			title: 'Home',
			path: '/',
		},
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Featured',
			path: '',
		},
	],
}

export const PressRoomTop = {
	title: 'PRESS ROOM',
	description: 'Explore new materials and suggested collection from our archive team.',
	breadC: [
		{
			title: 'Home',
			path: '/',
		},
		{
			title: 'Press Room',
			path: '',
		},
	],
}

export const PhotoTop = {
	title: 'Photographs',
	description: "The Genocide Archive of Rwanda's collections feature photographs related to the 1994 genocide against the Tutsi in Rwanda. View photographs of events, victims, survivors, perpetrators, memorials, post-genocide recovery initiatives and more. ",
	warning: 'Caution: Some photographs may contain sensitive content.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Photographs',
			path: '',
		},
	],
}

export const JoBopTop = {
	title: 'Job Opportunities',
	description: 'At the Aegis Trust Archive and documentation department, we often recruit interns and volunteers to help contribute and learn from the work we do for the Genocide Archive of Rwanda collections. Please visit this site frequently to view our listings of job oppenings, internships and volunteering listings.',
	breadC: [
		{
			title: 'Collections',
			path: '/collections',
		},
		{
			title: 'Job Opportunities',
			path: '',
		},
	],
};
