import React from 'react'
import styles from './Option.module.scss'
import { useNavigate } from 'react-router-dom'
export default function Option(props) {
    const { title, description, link, redirect } = props
    const navigate = useNavigate();
    return (
        <div className={`${styles.option}`}>
            {
                redirect ? (
                    <a href={link} className={`${styles.top}`} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.icon}`}>
                            <props.icon />
                        </div>
                        <div className={`${styles.title}`}>
                            {title}
                        </div>

                        <div className={`${styles.description}`}>
                            {description}
                        </div>
                    </a>
                ) : (
                    <div className={`${styles.top}`} onClick={() => navigate(link)}>
                        <div className={`${styles.icon}`}>
                            <props.icon />
                        </div>
                        <div className={`${styles.title}`}>
                            {title}
                        </div>
                        <div className={`${styles.description}`}>
                            {description}
                        </div>
                    </div>
                )
            }
            {/* <div className={`${styles.top}`} onClick={() => navigate(link)}>
                <div className={`${styles.icon}`}>
                    <props.icon />
                </div>
                <div className={`${styles.title}`}>
                    {title}
                </div>
            </div> */}
        </div>
    )
}
