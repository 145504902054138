import { createSlice } from "@reduxjs/toolkit";

function getInitialAuthInfoCheck() {
  let userLocal = localStorage.getItem("gl-admin-authInfo")
    ? JSON.parse(localStorage.getItem("gl-admin-authInfo"))
    : null;

  if (
    userLocal !== null &&
    (!userLocal?.refresh_token_expires_on ||
      new Date() > new Date(userLocal?.refresh_token_expires_on))
  ) {
    userLocal = null;
    localStorage.removeItem("gl-admin-authInfo");
  }

  if (userLocal === null) {
    return {
      displayname: null,
      roles: null,
      permissions: null,
      failed: false,
    };
  }
  return userLocal;
}

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialAuthInfoCheck,
  reducers: {
    loggedIn: (state, action) => {
      //state.displayname = action.payload.displayname;
      //state.roles = action.payload.roles;
      //state.permissions = action.payload.permissions;
      state.failed = false;
    },
    loggedInFailed: (state, action) => {
      state.displayname = null;
      state.permissions = null;
      state.roles = [];
      state.failed = true;
    },
    loggedOut: getInitialAuthInfoCheck,
  },
});

export const { loggedIn, loggedInFailed, loggedOut } = authSlice.actions;
export default authSlice.reducer;
