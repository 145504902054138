import NavDropMenu from 'modules/website/components/NavDropMenu/NavDropMenu';
import React from 'react'
import styles from './DropMenu.module.scss'

import About1 from 'assets/img/AboutUs/Menu/About1.jpg'
import About2 from 'assets/img/AboutUs/Menu/About2.jpg'
import About3 from 'assets/img/AboutUs/Menu/About3.jpg'

import Collection1 from 'assets/img/Collections/Menu/Collection1.png'
import Collection2 from 'assets/img/Collections/Menu/Collection2.png'
import Collection3 from 'assets/img/Collections/Menu/Collection3.jpg'

import Research1 from 'assets/img/Research/Menu/Research1.jpg'
import Research2 from 'assets/img/Research/Menu/Research2.jpg'
import Research3 from 'assets/img/Research/Menu/Research3.jpg'

const menus = {
    about_us: {
        imageCards: [
            {
                image: About1,
                title: 'HISTORY OF THE AEGIS TRUST ARCHIVE AND DOCUMENTATION',
                link: '/about_us/History_of_the_Aegis_trust_archive_and_documentation'
            },
            {
                image: About2,
                title: 'GENOCIDE ARCHIVE OF RWANDA',
                link: '/about_us/Genocide_Archive_of_Rwanda'
            },
            {
                image: About3,
                title: 'WHAT WE DO',
                link: '/what_we_do'
            }
        ],
        menuOptions: [
            {
                title: 'PRESS ROOM',
                options: [
                    {
                        title: 'News',
                        link: '/news'

                    },
                    {
                        //TODO: Que el link sea con el mismo hostname 
                        title: 'Blog',
                        link: '/blog',
                        newWindow: true

                    },
                    {
                        title: 'Gallery',
                        link: 'https://www.flickr.com/photos/128827388@N06/',
                        newWindow: true

                    },
                    {
                        title: 'Press Releases',
                        link: '/Press_Releases_about'

                    },
                    {
                        title: 'Newsletters',
                        link: '/newsletters'
                    }
                ]
            },
            {
                title: 'JOB OPPORTUNITIES',
                link: '/category/job_opportunities',
                options: [
                    {
                        title: 'Volunteer',
                    },
                    {
                        title: 'Internships',
                    },
                    {
                        title: 'Employment',
                    }
                ]
            },
            {
                title: 'PARTNERS AND DONORS',
                link: '/Our_Partners',
                options: [
                    {
                        title: 'International Partners',
                        link: ''
                    },
                    {
                        title: 'Local Partners',
                        link: ''
                    },
                    {
                        title: 'Project Partners',
                        link: ''
                    }
                ]
            }
        ]
    },
    collections: {
        imageCards: [
            {
                image: Collection1,
                title: 'INTERACTIVE MAPS',
                options: [
                    'Memorials map',
                    'Unity and Reconciliation Map',
                    'Young People and Peace Building Map'
                ],
                link: '/Interactive_Maps'
            },
            {
                image: Collection2,
                title: 'TESTIMONIES',
                options: [
                    'Elders',
                    'Rescuers',
                    'Perpetrators',
                    'Survivors',
                ],
                link: '/category/testimonies'
            },
            {
                image: Collection3,
                title: 'POST-GENOCIDE RECONSTRUCTION',
                options: [
                    'Gacaca',
                    'Kwibuka',
                    'Mapping Memory & Genocide',
                    'Unity and Reconciliation',
                    'Young People and Peace Building',
                ],
                link: '/Post_Genocide'
            }
        ],
        menuOptions: [
            {
                title: 'PHOTOGRAPHS',
                link: '/category/photographs',
                options: [
                    {
                        title: 'Kwibuka',
                        link: '/category/Remembrance_and_Memory_preservation'
                    },
                    {
                        title: 'Reconciliation',
                        link: '/category/Reconciliation'
                    },
                    {
                        title: 'Post-Genocide Recovery Initiatives',
                        link: '/category/Post-Genocide_Recovery_Initiatives'
                    },
                    {
                        title: 'Perpetrators',
                        link: '/category/Perpetrators'
                    },
                    {
                        title: 'Objects',
                        link: '/category/Objects_and_Artifacts(Stills)'
                    }
                ],
            },
            {
                title: 'VIDEOS',
                link: '/category/videos',
                options: [
                    {
                        title: 'TV Broadcasts',
                        link: '/category/TV_News'
                    },
                    {
                        title: 'Gacaca Process',
                        link: '/category/Gacaca_Process'
                    },
                    {
                        title: 'Kwibuka',
                        link: '/category/Remembrance'
                    },
                    {
                        title: 'Documentaries',
                        link: '/category/Documentaries'
                    },
                    {
                        title: 'Perpetrator Confessions',
                        link: '/category/Perpetrator_Confessions'
                    },
                    {
                        title: 'Conferences',
                        link: '/category/Conferences_Videos'
                    }
                ]
            },
            {
                title: 'AUDIO',
                link: '/category/audio',
                options: [
                    {
                        title: 'Speeches',
                        link: '/category/Speeches'
                    },
                    {
                        title: 'Songs',
                        link: '/category/Songs'
                    },
                    {
                        title: 'Radio Broadcasts',
                        link: '/category/Radio_Broadcasts'
                    },
                    {
                        title: 'Conferences',
                        link: '/category/Conferences'
                    }
                ]
            },
            {
                title: 'DOCUMENTS',
                link: '/category/documents',
                options: [
                    {
                        title: 'Reports',
                        link: '/category/Reports'
                    },
                    {
                        title: 'Press Releases',
                        link: '/category/Press_Releases'
                    },
                    {
                        title: 'Official Communiques',
                        link: '/category/Official_Communiqués'
                    },
                    {
                        title: 'Newspapers',
                        link: '/category/Newspapers'
                    },
                    {
                        title: 'Magazines',
                        link: '/category/Magazines'
                    },
                    {
                        title: 'Journals',
                        link: '/category/Journals'
                    },
                    {
                        title: 'Dissertations',
                        link: '/category/Dissertations'
                    },
                    {
                        title: 'Declassified Files',
                        link: '/category/Declassified_files'
                    },
                    {
                        title: 'Correspondencies',
                        link: '/category/Correspondences'
                    },
                    {
                        title: 'Audio Transcripts',
                        link: '/category/Audio_Transcripts'
                    }
                ],
                column: 3
            }
        ],
        extraOption: {
            text: 'VICTIMS DATABASE',
            link: '/Victims_Database'
        }
    },
    research: {
        imageCards: [
            {
                image: Research1,
                title: 'Access Policy',
                link: '/research/Access_Policy'
            },
            {
                image: Research2,
                title: 'Become a Research Affiliate',
                link: '/research/Become_a_Research_Affiliate'
            },
            {
                image: Research3,
                title: 'Library',
                link: '/research/Library_KGM'
            }
        ],
    }
}
export default function DropMenu(pops) {
    const {setSelectedDropdown, seletedDropdown } = pops;
    if (seletedDropdown === null) return null
    const menu = menus[seletedDropdown]
    return (
        <div className={`${styles.dropmenu}`}
            onMouseLeave={() => setSelectedDropdown(null)}>
            {/* {children} */}
            <NavDropMenu imageCards={menu.imageCards} menuOptions={menu.menuOptions} extraOption={menu.extraOption} />
        </div>
    )
}
