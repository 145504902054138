import React from 'react'
import styles from './SuggestedCard.module.scss'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
export default function SuggestedCard(props) {
    const navigate = useNavigate();
    const { title, date, description, nav } = props;
    return (
        <div className={`${styles.card}`}>
            <div className={`${styles.date}`}>
                {date}
            </div>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.description}`}>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            <Button variant="outline-dark" className={`${styles.btn}`} onClick={() => navigate(nav)}>
                <ChevronRight className={`${styles.icon}`}/>
                CONTINUE READING
            </Button>
        </div>
    )
}
