import React from 'react'
import styles from './SideBarNew.module.scss';
import NextStoryButton from '../NextStoryButton/NextStoryButton';
import Back2NewsButton from '../Back2NewsButton/Back2NewsButton';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import MoreNewCard from './MoreNewsCard/MoreNewCard';
import GARLaunch from 'assets/img/New/GAR_launch.jpg';
import More2 from 'assets/img/New/More2.jpg';
import More3 from 'assets/img/New/More3.jpg';
import More4 from 'assets/img/New/More4.png';
import { Link, useLocation } from 'react-router-dom';
import { useNextNewPage } from 'modules/website/hooks/useNextNewPage';
import Alert from 'modules/user/components/Alert/Alert';
import NextNewLoading from './NextNewLoading/NextNewLoading';

const categories = [
  {
    name: 'Peace education',
    link: '/category/News_Peace_Education'
  },
  {
    name: 'Capacity Building',
    link: '/category/News_Capacity_Building'
  },
  {
    name: 'Preservation',
    link: '/category/News_Preservation'
  },
  {
    name: 'Youth and Peace Building',
    link: '/category/News_Youth_and_Peace_Building'
  },
  {
    name: 'Kwibuka',
    link: '/category/News_Kwibuka'
  },
  {
    name: 'Events',
    link: '/category/News_Events'
  },
  {
    name: 'Collections',
    link: '/category/News_Collections'
  },
]

const MoreNews = [
  {
    image: GARLaunch,
    title: 'Aegis Trust launches new state of the art online archive',
    link: '/news/Aegis_Trust_launches_new_state_of_the_art_online_archive'
  },
  {
    image: More2,
    title: 'Aegis Trust and partners to launch new and expanded Genocide Archive of Rwanda website',
    link: '/news/Aegis_Trust_and_partners_to_launch_new_and_expanded_Genocide_Archive_of_Rwanda_website'
  },
  {
    image: More3,
    title: 'Belgian ministers visit and offer future support to genocide documentation',
    link: '/news/Belgian_Ministers_visit_KGM'
  },
  {
    image: More4,
    title: 'Virtual tours give online access to genocide memorials across Rwanda',
    link: '/news/Virtual_tours_give_online_access'
  },
]

export default function SidebarNew() {
  const location = useLocation();
  const nid = location.pathname.replace('/news/', '');
  const { isLoading, isError, payload, error } = useNextNewPage({
    nid: nid,
  });

  //console.log(payload);
  return (
    <div className={`${styles.sideBar}`}>
      <div className={`${styles.nextNew}`}>
        {isLoading &&
          <NextNewLoading />
        }
        {!isLoading && isError && <Alert variant='danger'>{error}</Alert>}
        {!isLoading && !isError && payload && (
          <>
            <NextStoryButton nav={"/news" + payload.alias} />
            <div className={`${styles.content}`}>
              <div className={`${styles.title}`}>
                {payload.title}
              </div>
              <div className={`${styles.detail}`} dangerouslySetInnerHTML={{ __html: payload.body }} />
            </div>
          </>
        )}
      </div>
      <div className={`${styles.moreNews}`}>
        <div className={`${styles.title}`}>
          More News
        </div>
        <div>
          <Container>
            <Row>
              {
                MoreNews.map((news, index) => (
                  <Col md={6} key={index}>
                    <MoreNewCard image={news.image} title={news.title} link={news.link}/>
                  </Col>
                ))
              }

            </Row>
          </Container>
        </div>
      </div>
      <div className={`${styles.categories}`}>
        <div className={`${styles.title}`}>
          Categories
        </div>
        <ListGroup className={`${styles.list}`}>
          {
            categories.map((category, index) => (
              <ListGroup.Item action className={`${styles.item}`} key={index}>
                <Link to={category.link}
                style={{textDecoration: "none",color:"white"}}
                >
                  <ChevronRight className={`${styles.icon}`} />
                  {category.name}
                </Link>
              </ListGroup.Item>
            ))
          }
        </ListGroup>
      </div>
      <div className={`${styles.btn}`}>
        <Back2NewsButton />
      </div>
    </div>
  )
}
