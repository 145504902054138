import { Button } from 'react-bootstrap'
import React from 'react'
import { PlayCircle } from 'react-bootstrap-icons'
import styles from './IntVideoBtn.module.scss'
import ModalVideo from './ModalVideo/ModalVideo'

export default function IntVideoBtn() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Button className={`${styles.btn}`} onClick={() => setModalShow(true)}>
        <PlayCircle className={`${styles.icon}`} />
        INTRODUCTION VIDEO
      </Button>
      <ModalVideo show={modalShow} onHide={() => setModalShow(false)}/>
    </>
  )
}
