import TopPart from 'modules/website/components/TopPart/TopPart';
import React from 'react';
import styles from '../PostGenocideReconstructionView/post_genocide.module.css';
export default function InteractiveMapView() {
	return (
		<div>
			<TopPart
				breadC={[
					{
						title: 'Collections',
						path: '/collections',
					},
					{
						title: 'Interactive Maps',
						path: '/Interactive_Maps',
					},
					{
						title: 'Memorials Map',
						path: '',
					},
				]}
				title='MEMORIALS MAPS'
				description='Explore our new interactive maps that feature different genocide landmarks including memorials, various post-genocide reconstruction efforts such as unity and reconciliation associations, and peace building projects undertaken by communities and the Rwandan youth around the country.'
			/>
			<div>
				<p>
					<iframe
						className='map-frame'
						frameBorder='0'
						height='900'
						marginHeight='0'
						scrolling='no'
						title='Memorials Map'
						src='https://www.arcgis.com/apps/Viewer/index.html?appid=d082a7c1778b4698a116dfcf5afa2394'
						style={{ width: '100%' }}
						width='1150'
					></iframe>
				</p>
			</div>
			<div className={`${styles['about-car-cont']}`}>
				<div
					className={`${styles['about-car-item']} ${styles['about-odd']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
				>
					<div className={`${styles['about-map-thumb']}`}>
						<img
							alt=''
							data-wx-image-external='false'
							data-wx-image-link='false'
							data-wx-image-link-target=''
							data-wx-image-link-url=''
							data-wx-imagename='Youngpeople.png'
							src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Youngpeople.png&amp;rsargs[]='
							style={{ width: '140px', height: '82px' }}
						/>
					</div>
					<div className={`${styles['about-map-text']}`}>
						<div className={`${styles['about-car-title']}`}>
							<strong>&gt;</strong>&nbsp;
							<a
								href='/Interactive_Map_Youth'
								target='_blank'
								className={`${styles['post-link-a']}`}
							>
								YOUTH&nbsp;MAP
							</a>
						</div>
						<div className={`${styles['about-car-abstract']}`}>
							This map features activities being done by youth
							organisations in Rwanda that contribute to peace
							building and fostering development around the
							country.
						</div>
						<a
							className={`${styles['btn']} ${styles['post-link-a']}`}
							href='/Young_People_Peace'
							target='_blank'
						>
							VIEW COLLECTION
						</a>
					</div>
				</div>
				<div
					className={`${styles['about-car-item']} ${styles['about-even']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
				>
					<div className={`${styles['about-map-thumb']}`}>
						<img
							alt=''
							data-wx-image-external='false'
							data-wx-image-link='true'
							data-wx-image-link-target='_parent'
							data-wx-image-link-url='http://arcg.is/1C6ICe8'
							data-wx-imagename='Post genocide map.png'
							src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Post genocide map.png&amp;rsargs[]='
							style={{ width: '153px', height: '164px' }}
						/>
					</div>
					<div className={`${styles['about-map-text']}`}>
						<div className={`${styles['about-car-title']}`}>
							<strong>&gt;</strong>&nbsp;
							<a
								href='/Interactive_Map_Unity_and_Reconciliation'
								target='_blank'
								className={`${styles['post-link-a']}`}
							>
								UNITY AND RECONCILIATION MAP
							</a>
						</div>
						<div className={`${styles['about-car-abstract']}`}>
							This map features different Unity and reconciliation
							projects in Rwanda that help communities and the
							population to overcome tension and conflict amongst
							communities members in Rwanda, and to reconstruct
							social cohesion all over the country.
						</div>
						<a
							className={`${styles['btn']} ${styles['post-link-a']}`}
							href='/category/Unity_and_Reconciliation'
							target='_blank'
						>
							VIEW COLLECTION
						</a>
					</div>
				</div>
				<div
					className={`${styles['about-car-item']} ${styles['about-odd']} ${styles['col-md-4']} ${styles['col-xs-12']}`}
				>
					<div className={`${styles['about-map-thumb']}`}>
						<img
							alt=''
							data-wx-image-external='false'
							data-wx-image-link='true'
							data-wx-image-link-target='_parent'
							data-wx-image-link-url='http://arcg.is/1C6ICe8'
							data-wx-imagename='Kibeho tile photo 06.jpg'
							src='https://genocidearchiverwanda.org.rw/index.php?action=ajax&amp;rs=importImage&amp;rsargs[]=Kibeho tile photo 06.jpg&amp;rsargs[]='
							style={{ width: '161px', height: '106px' }}
						/>
					</div>
					<div className={`${styles['about-map-text']}`}>
						<div className={`${styles['about-car-title']}`}>
							<strong>&gt;</strong>&nbsp;
							<a
								href='/Interactive_Maps'
								className={`${styles['post-link-a']}`}
							>
								MEMORIALS MAP
							</a>
						</div>
						<div className={`${styles['about-car-abstract']}`}>
							This map features different memorial sites that were
							established all over the country following the 1994
							genocide against the Tutsi in Rwanda. here you will
							find virtual tours of these sites, of which most of
							them where also killing sites. Some of the content
							might contain graphic images.
						</div>
						<a
							className={`${styles['btn']} ${styles['post-link-a']}`}
							href='/category/Memorials'
							target='_blank'
						>
							VIEW COLLECTION
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
