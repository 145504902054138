import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useGlifosPlayerData, useGlifosPlayerRef, useGlifosPlayerUpdate } from "../GlifosRichMediaProvider/GlifosRichMediaProvider";
import styles from './GlifosRichMediaPlayer.module.scss';
import PlayButton from "./controls/PlayButton/PlayButton";
import SpeakerButton from "./controls/SpeakerButton/SpeakerButton";
import { secondsToTime, timeToSeconds } from "modules/glifos-rich-media/helpers/TimeFunctions";
import PipButton from "./controls/PipButton/PipButton";
import FullScreenButton from "./controls/FullScreenButton/FullScreenButton";
import screenfull from "screenfull";

export default function GlifosRichMediaPlayer(props) {
  const [draggingSound, setDraggingSound] = useState({ currentX: 0, initialX: 0, captured: false, handleDelta: 0 });
  let draggingProgressNudge = false;
  let draggingSoundHandler = false;
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [seekThumbPos, setSeekThumbPos] = useState({ display: false, pos: 0.00, time: "00:00" });
  const [forcedPercentage, setForcedPercentage] = useState(null);
  const [firstTouchStyle, setFirstTouchStyle] = useState("");
  const [countTouchs, setCountTouchs] = useState(0);
  const playerData = useGlifosPlayerData();
  const [isTouchDevice, setIsTouchDevice] = useState('ontouchstart' in window);
  const playerActions = useGlifosPlayerUpdate();
  const playerRef = useGlifosPlayerRef();
  const barRef = useRef(null);
  const playerContRef = useRef(null);
  const { src } = props;
  let percentage = 0.00;
  let percentageWidth = '';
  let markCoords = { start: 0, end: 0 };

  //console.log(playerData?.src);

  function keyboardActions(evk) {
    //TODO: Averiguar como hacer que el teclado no afecte a los inputs
    //evk.preventDefault();
    evk.stopPropagation();
    console.log(evk.keyCode);
    switch (evk.keyCode) {
      case 32: {
        //spacebar
        playerActions.command({ type: "play" });
      } break;
    }
  }
  function clickSoundBar(evc) {
    //if (draggingSound.captured === true) return;
    evc.preventDefault();
    evc.stopPropagation();
    //console.log(evc);
    let bounds = evc.target.parentElement.getBoundingClientRect();
    let x = evc.clientX - bounds.left;
    if (evc.target.className.indexOf("soundbarcont") > 0)
      x -= document.getElementById('gp-sp-01').offsetWidth;
    //let y = evc.clientY - bounds.top;
    //console.log(x,y);
    let total = document.getElementById('gp-sp-02').offsetWidth;
    let newVolume = (x > 0) ? x / total : 0.5;
    //console.log(evc, x,total,newVolume);
    playerActions.update({ "volume": newVolume });
    setDraggingSound({ ...draggingSound, captured: false });
  }
  function mouseDownSoundHandle(evmd) {
    evmd.preventDefault();
    evmd.stopPropagation();
    draggingSoundHandler = true;
    setDraggingSound({ captured: true, currentX: evmd.clientX, initialX: evmd.clientX });
    document.addEventListener('mousemove', mouseMoveWhileDraggingSound, false);
    document.addEventListener('mouseup', mouseUpSoundHandle, false);
    /* let handlePos = playerData.volume * 36;
    let delta = evmd.clientX - draggingSound.initialX;
    handlePos = ((handlePos + delta) > 36) ? 36 : ((handlePos + delta) < 0) ? 0 : handlePos + delta;
    playerActions.update({ "volume": handlePos / 36 }); */
    //console.log('Setted mouse down', draggingSound, evmd.clientX, evmd);

  }
  function mouseUpSoundHandle(evmu) {
    //if (draggingSound.captured==false) return;
    if (draggingSoundHandler) {
      draggingSoundHandler = false;
      /* evmu.preventDefault();
      evmu.stopPropagation(); */
      setDraggingSound({ captured: false, currentX: evmu.clientX, initialX: evmu.clientX });
      /* let handlePos = playerData.volume * 36;
      let delta = evmu.clientX - draggingSound.initialX;
      handlePos = ((handlePos + delta) > 36) ? 36 : ((handlePos + delta) < 0) ? 0 : handlePos + delta;
      playerActions.update({ "volume": handlePos / 36 }); */
      document.removeEventListener('mousemove', mouseMoveWhileDraggingSound, false);
      document.removeEventListener('mouseup', mouseUpSoundHandle, false);
    }
    //console.log('Mouse Up', delta);
  }
  function mouseMoveWhileDraggingSound(evmm) {
    //if (draggingSound.captured==false) return;
    if (draggingSoundHandler) {
      evmm.preventDefault();
      evmm.stopPropagation();

      let handlePos = playerData.volume * 36;
      let delta = evmm.clientX - draggingSound.initialX;
      handlePos = ((handlePos + delta) > 36) ? 36 : ((handlePos + delta) < 0) ? 0 : handlePos + delta;
      playerActions.update({ "volume": handlePos / 36 });
      setDraggingSound({ ...draggingSound, captured: true, currentX: evmm.clientX });
    }
    //console.log('Dragging sound ', delta);
  }

  //TODO: Quem funcione solo cuando se le da click sin bug
  //TODO: 

  //Drag progress nudge
  function mouseDownProgressNudge(evmd) {
    //console.log("dragging nudge", draggingProgressNudge);
    evmd.preventDefault();
    evmd.stopPropagation();
    draggingProgressNudge = true;

    const playerElement = playerContRef.current;
    const isMobile = 'ontouchstart' in window;

    /* if (isMobile) {
      console.log('mouseDownProgressNudge on mobile');
      console.log(evmd);
      const touchEvent = evmd.changedTouches;
      touchStartProgressNudge(touchEvent);
    } else { */
    //console.log('mouseDownProgressNudge on desktop');
    document.addEventListener('mousemove', mouseMoveProgressNudge, false);
    document.addEventListener('mouseup', mouseUpProgressNudge, false);
    //}
    //document.addEventListener('click', clickProgressBar, false);
  }

  function mouseUpProgressNudge() {
    if (draggingProgressNudge) {
      draggingProgressNudge = false;
      /* document.removeEventListener('mousemove', mouseMoveProgressNudge, false);
      document.removeEventListener('mouseup', mouseUpProgressNudge, false); */
      /* const isMobile = 'ontouchstart' in window;
      if (isMobile) {
        document.addEventListener('touchmove', mouseMoveProgressNudge, false);
        document.addEventListener('touchend', mouseUpProgressNudge, false);

      } else { */

      document.addEventListener('mousemove', mouseMoveProgressNudge, false);
      document.addEventListener('mouseup', mouseUpProgressNudge, false);
      //}
      //document.addEventListener('click', clickProgressBar, false);
    }
  }

  function mouseMoveProgressNudge(evmm) {
    //console.log("dragging nudge", draggingProgressNudge);
    if (draggingProgressNudge) {
      evmm.preventDefault();
      evmm.stopPropagation();
      let tb = document.getElementById('gp-tb-01');
      var bounds = tb.getBoundingClientRect();
      var x = evmm.clientX - bounds.left;
      /*console.log("Percetage = ",(evc.target.offsetWidth));*/
      //Esta parte es para arrastrar y cambiar la posicion del thumb y del video
      //console.log("seek = ", console.log(tb.id), { type: "seek", value: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
      setForcedPercentage((x / (tb.offsetWidth)));
      playerActions.update({ markTime: null });
      playerActions.command({ type: "seek", value: (x / (tb.offsetWidth)) * playerData.videoLength });
      //console.log("dragging nudge", evmm.clientX);

      //Mostrar la thumb en la posicion en la que se va pasando
      /*console.log("Percetage = ",(evc.target.offsetWidth));*/
      // console.log("seek = ",console.log(evc.target.id),{type:"seek",value: secondsToTime()*playerData.videoLength)});
      //playerActions.command({type:"seek",value:});
      let newPos = (x - 16 > 5) ? x - 16 : 5;
      newPos = (newPos + 48 > tb.offsetWidth) ? tb.offsetWidth - 48 : newPos;
      setSeekThumbPos({ display: true, pos: (newPos), time: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });

    }
  }

  //Touch drag proccess

  /* function touchStartProgressNudge(evmd) {
    console.log("dragging nudge", draggingProgressNudge);
    //evmd.preventDefault();
    //evmd.stopPropagation();
    draggingProgressNudge = true;
    document.addEventListener('touchmove', touchMoveProgressNudge, false);
    document.addEventListener('touchend', touchEndProgressNudge, false);
    //document.addEventListener('click', clickProgressBar, false);
  } */

  function touchStartProgressNudge(evmd) {
    //console.log("dragging nudge", draggingProgressNudge);
    draggingProgressNudge = true;

    // Guardar el tiempo del inicio del toque
    const touchStartTime = Date.now();

    // Agregar un manejador de evento para touchend
    document.addEventListener('touchend', function touchEndHandler(evte) {
      // Remover el manejador de evento touchend
      document.removeEventListener('touchend', touchEndHandler, false);

      // Calcular la duración del toque
      const touchDuration = Date.now() - touchStartTime;

      // Si la duración es corta, considerarlo como un clic
      if (touchDuration < 300) {
        touchProgressBar(evmd);
      }

      // Resetear el estado de draggingProgressNudge
      draggingProgressNudge = false;
    }, false);

    // Agregar un manejador de evento para touchmove
    document.addEventListener('touchmove', touchMoveProgressNudge, false);
  }

  function touchEndProgressNudge() {
    if (draggingProgressNudge) {
      draggingProgressNudge = false;
      /* document.removeEventListener('mousemove', mouseMoveProgressNudge, false);
      document.removeEventListener('mouseup', mouseUpProgressNudge, false); */
      document.addEventListener('touchmove', touchMoveProgressNudge, false);
      document.addEventListener('touchend', touchEndProgressNudge, false);
    }
  }

  /* function touchMoveProgressNudge(evmm) {
    //console.log("dragging nudge", draggingProgressNudge);
    if (draggingProgressNudge) {
      evmm.preventDefault();
      evmm.stopPropagation();
      let tb = document.getElementById('gp-tb-01');
      var bounds = tb.getBoundingClientRect();
      var x = evmm.clientX - bounds.left;
      //console.log("Percetage = ",(evc.target.offsetWidth));
      //Esta parte es para arrastrar y cambiar la posicion del thumb y del video
      //console.log("seek = ", console.log(tb.id), { type: "seek", value: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
      setForcedPercentage((x / (tb.offsetWidth)));
      playerActions.update({ markTime: null });
      playerActions.command({ type: "seek", value: (x / (tb.offsetWidth)) * playerData.videoLength });
      //console.log("dragging nudge", evmm.clientX);

      //Mostrar la thumb en la posicion en la que se va pasando
      //console.log("Percetage = ",(evc.target.offsetWidth));
      // console.log("seek = ",console.log(evc.target.id),{type:"seek",value: secondsToTime()*playerData.videoLength)});
      //playerActions.command({type:"seek",value:});
      let newPos = (x - 16 > 5) ? x - 16 : 5;
      newPos = (newPos + 48 > tb.offsetWidth) ? tb.offsetWidth - 48 : newPos;
      setSeekThumbPos({ display: true, pos: (newPos), time: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });

    }
  } */

  function touchMoveProgressNudge(evmm) {
    if (draggingProgressNudge) {
      evmm.preventDefault();
      evmm.stopPropagation();
      let tb = document.getElementById('gp-tb-01');
      var bounds = tb.getBoundingClientRect();
      //var x = evmm.clientX - bounds.left;
      let touch = evmm.touches[0];
      var x = touch.clientX - bounds.left;

      // Verificar que playerData y playerData.videoLength estén definidos y sean números

      if (playerData && typeof playerData.videoLength === 'number' && isFinite(playerData.videoLength)) {
        let newPosition = x / tb.offsetWidth;
        let newTime = newPosition * playerData.videoLength;

        setForcedPercentage(newPosition);
        playerActions.update({ markTime: null });

        // Verificar que newTime sea un número válido y finito
        /* console.log('newTime', newTime);
        console.log('playerData.videoLength', playerData.videoLength);
        console.log('isFinite(newTime)', isFinite(newTime));

        console.log('newPosition', newPosition);
        console.log('tb.offsetWidth', tb.offsetWidth);
        console.log('x', x); */

        if (isFinite(newTime) && newTime >= 0 && newTime <= playerData.videoLength) {

          playerActions.command({ type: "seek", value: newTime });

          let newPos = (x - 16 > 5) ? x - 16 : 5;
          newPos = (newPos + 48 > tb.offsetWidth) ? tb.offsetWidth - 48 : newPos;

          setSeekThumbPos({ display: true, pos: newPos, time: secondsToTime(newTime) });
        }
      }
    }
  }

  function touchProgressBar(evc) {
    //if (evc.target.id=="gp-tb-01"){
    let tb = document.getElementById('gp-tb-01');
    evc.stopPropagation();
    evc.preventDefault();

    var bounds = evc.target.getBoundingClientRect();
    let touch = evc.touches[0];
    var x = touch.clientX - bounds.left;
    /*console.log("Percetage = ",(evc.target.offsetWidth));*/
    //console.log("seek = ", console.log(evc.target.id), { type: "seek", value: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
    setForcedPercentage((x / (tb.offsetWidth)));
    playerActions.update({ markTime: null });
    playerActions.command({ type: "seek", value: (x / (tb.offsetWidth)) * playerData.videoLength });
    //}
  }


  /* function mouseMoveWhileDragginNudge(evc) {
      let tb = document.getElementById('gp-tb-01');
      evc.stopPropagation();
      evc.preventDefault();

      var bounds = evc.target.getBoundingClientRect();
      var x = evc.clientX - bounds.left;
      //console.log("Percetage = ",(evc.target.offsetWidth));
      console.log("seek = ", console.log(evc.target.id), { type: "seek", value: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
      setForcedPercentage((x / (tb.offsetWidth)));
      playerActions.update({ markTime: null });
      playerActions.command({ type: "seek", value: (x / (tb.offsetWidth)) * playerData.videoLength });

      console.log("dragging nudge", evc.clientX, evc);
  } */

  useEffect(() => {
    //console.log('playerData');
    function onFullscreenChange() {
      playerActions.update({ "onFullScreen": Boolean(screenfull.isFullscreen) });
    }

    document.addEventListener('fullscreenchange', onFullscreenChange, false);
    document.addEventListener('fullscreenchange', onFullscreenChange, false);
    console.log(src)
    playerActions.update({ src: src });
    //document.addEventListener('keydown', keyboardActions, false);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);

  }, []);


  /* useEffect(() => {
      console.log('screenfull', fullscreen);
      //playerActions.update({ "onFullScreen": fullscreen});
  }, [fullscreen]); */

  const playerCallbacks = {
    onReady: function (params01) {
      console.log('onReady', params01);
      playerActions.update({ ready: true });
    },
    onStart: function (params02) {
      console.log('onStart', params02);
    },
    onPlay: function (params03) {
      playerActions.update({ status: 'playing' });
    },
    onProgress: function (/*{playedSeconds,played,loaded,loadedSeconds}*/params04) {
      if (null !== forcedPercentage) {
        setForcedPercentage(null);
      }
      playerActions.update({ currentPosition: params04.playedSeconds });
      //console.log("data de player",playerData)
    },
    onDuration: function (params05) {
      playerActions.update({ videoLength: params05 });
    },
    onPause: function (params05) {
      playerActions.update({ status: 'paused' });
    },
    onBuffer: function (params05) {
      playerActions.update({ buffering: true });
    },
    onBufferEnd: function (params05) {
      playerActions.update({ buffering: false });
    },
    onSeek: function (params05) {
      //console.log('onSeek',params05);
    },
    /*onPlayBackRateChange:function(params05){
        console.log(params05);
    },*/
    onEnded: function (params05) {
      console.log('onEnded', params05);
      playerActions.update({ status: 'stopped' });
    },
    onError: function (params05) {
      console.log('onError', params05);
    },
    onClickPreview: function (params05) {
      console.log('onClickPreview', params05);
    },
    onEnablePIP: function (params05) {

      playerActions.update({ videoInPIP: true });
    },
    onDisablePIP: function (params05) {

      playerActions.update({ videoInPIP: false, pipEnabled: false });
    }

  }
  function ref(player) {
    playerRef.setUp(player);
  }
  function switchStatusClass() {
    if (playerData.markTime) {
      return styles.statuspaused;
    }
    switch (playerData.status) {
      case "paused": return styles.statuspaused; break;
      case "playing": return styles.statusplaying; break;
    }
  }
  function clickProgressBar(evc) {
    //if (evc.target.id=="gp-tb-01"){
    let tb = document.getElementById('gp-tb-01');
    evc.stopPropagation();
    evc.preventDefault();

    var bounds = evc.target.getBoundingClientRect();
    var x = evc.clientX - bounds.left;
    /*console.log("Percetage = ",(evc.target.offsetWidth));*/
    //console.log("seek = ", console.log(evc.target.id), { type: "seek", value: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
    setForcedPercentage((x / (tb.offsetWidth)));
    playerActions.update({ markTime: null });
    playerActions.command({ type: "seek", value: (x / (tb.offsetWidth)) * playerData.videoLength });
    //}
  }
  function mouseOverProgressBar(evmo) {
    let tb = document.getElementById('gp-tb-01');
    //ev.stopPropagation();
    //evc.preventDefault();

    var bounds = evmo.target.getBoundingClientRect();
    var x = evmo.clientX - bounds.left;
    /*console.log("Percetage = ",(evc.target.offsetWidth));*/
    // console.log("seek = ",console.log(evc.target.id),{type:"seek",value: secondsToTime()*playerData.videoLength)});
    //playerActions.command({type:"seek",value:(x/(tb.offsetWidth))*playerData.videoLength});
    let newPos = (x - 16 > 5) ? x - 16 : 5;
    newPos = (newPos + 48 > tb.offsetWidth) ? tb.offsetWidth - 48 : newPos;
    setSeekThumbPos({ display: true, pos: (newPos), time: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
    playerActions.update({ markTime: null });
  }
  function mouseMoveProgressBar(evmo) {
    if (seekThumbPos.display !== true) return;
    let tb = document.getElementById('gp-tb-01');
    //ev.stopPropagation();
    //evc.preventDefault();

    var bounds = evmo.target.getBoundingClientRect();
    var x = evmo.clientX - bounds.left;
    /*console.log("Percetage = ",(evc.target.offsetWidth));*/
    // console.log("seek = ",console.log(evc.target.id),{type:"seek",value: secondsToTime()*playerData.videoLength)});
    //playerActions.command({type:"seek",value:});
    let newPos = (x - 16 > 5) ? x - 16 : 5;
    newPos = (newPos + 48 > tb.offsetWidth) ? tb.offsetWidth - 48 : newPos;
    setSeekThumbPos({ display: true, pos: (newPos), time: secondsToTime((x / (tb.offsetWidth)) * playerData.videoLength) });
  }

  function fullScreenClick(evc) {
    evc.stopPropagation();
    evc.preventDefault();
    if (playerData.onFullScreen === false) {
      playerContRef.current.requestFullscreen();
    }
    else {
      document.exitFullscreen();
    }
    playerActions.update({ "onFullScreen": !playerData.onFullScreen })
  }

  /* function clickViewArea(evc){
      evc.stopPropagation();
      evc.preventDefault();

      playerActions.command({ type: "play" });
  } */
  const clickViewArea = (evc) => {
    evc.stopPropagation();
    evc.preventDefault();

    const playerElement = playerContRef.current;
    const isMobile = 'ontouchstart' in window;

    if (isMobile) {
      // Si es un dispositivo móvil
      const now = Date.now();
      const lastTouchTime = playerElement.getAttribute('lastTouchTime') || 0;
      const touchTimeThreshold = 500;
      //console.log('clickViewArea on mobile');
      /* if (now - lastTouchTime <= touchTimeThreshold) { */
      if (playerElement.contains(evc.target)) {
        // Si el toque ocurrió dentro del área del reproductor
        if (countTouchs === 1) {
          // Si el tiempo entre toques es menor o igual al umbral, considerarlo un doble toque
          //console.log('Double tap detected');
          // Realiza las acciones que deseas para el doble toque aquí
          // Por ejemplo, puedes poner pausa o realizar alguna otra acción específica
          //playerActions.command({ type: "pause" });
          playerActions.command({ type: "play" });
          setFirstTouchStyle("");
          setCountTouchs(0);
        } else if (playerData.playing === false) {
          // Si el video está pausado, reproducirlo
          playerActions.command({ type: "play" });
          //setCountTouchs(0);
          setFirstTouchStyle("");
        } else {
          // Si el video está reproduciéndose, pausarlo
          setFirstTouchStyle(styles.firstTouch);
          //playerElement.setAttribute('lastTouchTime', now);
          setCountTouchs(1);
        }
      }
    } else {
      // Si no es un dispositivo móvil, ejecutar la acción normal
      playerActions.command({ type: "play" });
    }
  };

  let tb = document.getElementById('gp-tb-01');
  if (null !== tb) {
    if (forcedPercentage !== null) {
      if (playerData.onFullScreen === true) {
        percentage = (forcedPercentage * tb.offsetWidth).toString() + "px";
      }
      else {
        percentage = (forcedPercentage * tb.offsetWidth).toString() + "px";
      }
      percentageWidth = (forcedPercentage * tb.offsetWidth).toString() + "px";
    }
    else {
      if (playerData.onFullScreen === true) {
        percentage = ((playerData.currentPosition / playerData.videoLength) * tb.offsetWidth).toString() + "px";
      }
      else {
        percentage = ((playerData.currentPosition / playerData.videoLength) * tb.offsetWidth).toString() + "px";
      }
      percentageWidth = ((playerData.currentPosition / playerData.videoLength) * tb.offsetWidth).toString() + "px";
    }
    if (playerData.markTime !== null) {
      if (playerData.markTime.start) {
        markCoords.start = (playerData.markTime.start / playerData.videoLength * tb.offsetWidth);
      } else {
        markCoords.start = 0.00;
      }
      if (playerData.markTime.end) {
        markCoords.end = (playerData.markTime.end / playerData.videoLength * tb.offsetWidth);
      } else {
        markCoords.end = tb.offsetWidth;
      }
    }
  }

  const [mouseOver, setMouseOver] = useState(false);
  const funcionMouseOver = () => {
    console.log("Mouse Over")
    setMouseOver(true);
  };

  const funcionMouseOut = () => {
    setMouseOver(false);
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const config = {
    file: {
      forceHLS: !isSafari,
      forceSafariHLS: true,
      forceVideo: true,
      hlsVersion: '0.12.4',
      /* attributes: {
        poster: feed && feed.actionUrl && feed.actionUrl.image,
        disablePictureInPicture: true
      } */
    }
  }
  return <div ref={playerContRef} className={styles.gpwrapper} > <ReactPlayer
    ref={ref}
    className='react-player'
    url={playerData.src}
    width='100%'
    height='100%'
    pip={playerData.pipEnabled}
    controls={false}
    playing={playerData.playing}
    volume={playerData.volume}
    playsinline
    //config={config}
    {...playerCallbacks}
    id="gp-v-01"
  />
    <div 
      className={`${styles.overlay} ${switchStatusClass()} ${
        draggingSound.captured ? styles.soundbarmoving : ''
      } ${playerData.onFullScreen ? styles.Ofullscreen : ''} ${
        mouseOver ? styles.overlayOver : ''
      }`}
      onMouseOver={funcionMouseOver}
      onMouseOut={funcionMouseOut}
    >
      <div className={styles.viewarea} onClick={clickViewArea}>
      </div>
      <div className={styles.timebarc} style={{ '--progress': `${percentage}` }}>
        <div
          className={`${styles.timeline}`}
          id="gp-tb-01"
          ref={barRef}
          {...(!isTouchDevice
            ? {
              onMouseOver: mouseOverProgressBar,
              onMouseMove: mouseMoveProgressBar,
              onClick: clickProgressBar,
              onMouseDown: mouseDownProgressNudge,
            }
            : {
              //onClick: clickProgressBar,
              onTouchStart: touchStartProgressNudge,
            })}
        >

          {(playerData.markTime !== null) ? <div className={styles.progressmarkbar} style={{ left: markCoords.start.toString() + "px", width: (markCoords.end - markCoords.start).toString() + "px" }}> </div> : ''}
        </div>
        <div className={`${styles.progressindicator}`}
          {...(!isTouchDevice
            ? {
              onMouseDown: mouseDownProgressNudge
            } : {
              onTouchStart: touchStartProgressNudge,
            }
          )}
        >
        </div>
        <div className={styles.seekthumbc} style={{ left: seekThumbPos.pos.toString() + "px" }}>
          {seekThumbPos.time}
        </div>
      </div>
      <div className={styles.controlarea}>
        <button alt="Play (spacebar)" title="Play (spacebar)"
          className={styles.playbtn}
          onClick={() => playerActions.command({ type: "play" })}>
          <PlayButton stylesheet={styles} videostate={playerData.status} />
        </button>
        {isTouchDevice === false && (
          <div className={styles.spkrbtncnt}>
            <button id="gp-sp-01" className={styles.spkrbtn} onClick={() => playerActions.command({ type: "mute" })}><SpeakerButton stylesheet={styles} volume={playerData.volume} /></button>
            <div id="gp-sp-02" className={styles.soundbarcont}
              onMouseDown={mouseDownSoundHandle}
              onMouseMove={mouseMoveWhileDraggingSound}
              onClick={clickSoundBar}
            >
              <div className={styles.soundbarhandle} style={{ left: (playerData.volume * 36).toString() + "px" }}></div>
            </div>
          </div>
        )
        }
        <div className={styles.timesection}>
          <span id="gp-ct-01">{secondsToTime((forcedPercentage !== null) ? playerData.videoLength * forcedPercentage : playerData.currentPosition)}</span>/<span id="gp-tt-01">{secondsToTime(playerData.videoLength)}</span>
        </div>
        <div className={styles.filler}> </div>
        <button alt="Picture in Picture" title="Picture in Picture" onClick={() => playerActions.command({ type: "pip" })} className={styles.pipbtnc}>
          <PipButton stylesheet={styles} pipstate={false} />
        </button>
        <button alt="Fullscreen" title="Fullscreen" className={styles.fullscrbtnc} onClick={fullScreenClick}>
          <FullScreenButton stylesheet={styles} state={playerData.onFullScreen} />
        </button>
      </div>
    </div>
  </div>
}