import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import styles from './RegisterView.module.scss'
import { countries } from 'modules/website/utilities/country'
export default function RegisterView() {
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [username, setUsername] = React.useState('')
    const [country, setCountry] = React.useState(countries[0].name)
    const [telephone, setTelephone] = React.useState(`(${countries[0].code})`)
    const [remember, setRemember] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [Repassword, setRepassword] = React.useState('')
    const [bot, setBot] = React.useState('')
    const handleChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry);
        
        const countryData = countries.find(c => c.name === selectedCountry);
        if (countryData) {
            setTelephone(`(${countryData.code})`);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario
        console.log({
            firstName,
            lastName,
            username,
            country,
            telephone,
            remember,
            bot
        })
    };

    return (
        <Container>
            <Row className={`${styles.ad}`}>
                <Col md={9} sm={12} lg={6}>
                    Fields marked with <span className={`${styles.redFont}`}>*</span> are required.
                </Col>
            </Row>
            <Row className={`${styles.cont}`}>

                <Col md={9} sm={12} lg={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>First Name: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`} 
                                onChange={(e) => {
                                   setFirstName(e.target.value)
                                }}
                                value={firstName}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Last Name: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`} 
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                value={lastName}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Username: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="text" className={`${styles.control}`} 
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                                value={username}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className={`${styles.fGroup}`}>
                            <Form.Label>Country: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                className={`${styles.control}`}
                                required
                            >
                                {
                                    countries.map((country) => {
                                        return <option value={country.name}>{country.name}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formBasicTelephone" className={`${styles.fGroup}`}>
                            <Form.Label>Telephone:</Form.Label>
                            <Form.Control type="text" className={`${styles.control}`}  
                                onChange={(e) => {
                                    setTelephone(e.target.value)
                                }}
                                value={telephone}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPass" className={`${styles.fGroup}`}>
                            <Form.Label>Password:  <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="password" className={`${styles.control}`}  
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                value={password}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicRePass" className={`${styles.fGroup}`}>
                            <Form.Label>Retype password: <span className={`${styles.redFont}`}>*</span></Form.Label>
                            <Form.Control type="password" className={`${styles.control}`}  
                                onChange={(e) => {
                                    setRepassword(e.target.value)
                                }}
                                value={Repassword}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className={`${styles.fGroup}`}>
                            <Form.Check type="checkbox" label="Remember my login on this computer" 
                                onChange={(e)=>{
                                    setRemember(e.target.value)
                                }}
                                value={remember}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBot" className={`${styles.fGroup}`}>
                            <Form.Control type="text"
                                style={{
                                    display: 'none'
                                }}
                                onChange={(e) => {
                                    setBot(e.target.value)
                                }}
                                value={bot}
                            />
                        </Form.Group>
                        <div>
                            <Button variant="primary" type="submit"
                                className={`${styles.btn}`}
                            >
                                Create account
                            </Button>
                            {/* <Button variant="primary"
                                className={`${styles.btn}`}
                                style={{ marginLeft: '10px' }}
                            >
                                Forgotten Password?
                            </Button> */}

                        </div>
                    </Form>
                </Col>
            </Row>
            <Row>

            </Row>
        </Container>
    )
}