import React from 'react'
import styles from './BrowseSquare.module.scss'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

export default function BrowseSquare(props) {
    const {options,title} = props;
    const navigate = useNavigate();
    return (
        <div className={`${styles.whatWeDo}`}>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.options}`}>
                {options.map((option, index) => (
                    <a variant="primary" type="submit" className={`${styles.btn}`} key={index} href={option.link}
                        style={{color: 'white', textDecoration: 'none'}}
                    >
                        {option.title}
                        <ChevronRight className={`${styles.icon}`} />
                    </a>
                ))}
            </div>
        </div>
    )
}
