import React from 'react'
import InfoCard from './InfoCard/InfoCard';
import styles from './InfoCarousel.module.scss'

import Elders from 'assets/img/Main/Elders.gif'
import GenocideArchive from 'assets/img/Main/Genocide-Archive-Trains.png'
import GenocideMemorials from 'assets/img/Main/Genocide-Memorials.gif'
import MeetSiz from 'assets/img/Main/Meet-Siz-Rwandas.png'
import Ubumuntu from 'assets/img/Main/Ubumuntu.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Mousewheel,
    Keyboard,
    Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
SwiperCore.use([Navigation, Mousewheel, Keyboard, Autoplay]);

const style1 = {
    backgroundColor: '#25455a',
    colorBtn: '#ff9200'
}
const style2 = {
    backgroundColor: '#223b4b',
    colorBtn: '#bc1000'
}
const Data = [
    {
        image: GenocideMemorials,
        title: 'Genocide Memorials',
        description: 'Across Rwanda, memorials provide a dignified final resting place for victims of the 1994 Genocide against the Tutsi. These memorials receive local and international visitors...',
        style: style1,
        link:"/category/Memorials"
    },
    {
        image: GenocideArchive,
        title: 'Genocide Archive Trains...',
        description: 'Partner of the Rwanda Archive and Library Services Authority, Aegis Trust, through the Genocide Archive of Rwanda team, has trained a team of young volunteers in indexing to...',
        style: style2,
        link:"/news/Genocide_Archive_Trains_Young_Rwandans_to_Index_National_Archives"
    },
    {
        image: Ubumuntu,
        title: 'Ubumuntu',
        description: 'This documentary film features people who are called "rescuers". There are those who risked their lives during the 1994 genocide against the Tutsi to help and save people...',
        style: style1,
        link:"/Ubumuntu"
    },
    {
        image: MeetSiz,
        title: 'Meet Six Rwandans...',
        description: 'Supported by the National Unity and Reconciliation Commission, the Unity Club (an organisation composed of current and former Cabinet members and their spouses) awarded 17...',
        style: style2,
        link:"/news/Meet_Six_Rwandans_Honoured_for_Building_Peace_and_Reconciliation_after_Genocide"
    },
    {
        image: Elders,
        title: 'Elders',
        description: 'To understand the Genocide against the Tutsi, it is important to learn about the history of Rwanda. Here you can listen to elders talk about their experiences before the...',
        style: style1,
        link:"/category/Elders"
    }
]

export default function InfoCarousel() {
    //TODO: Correccion de bug cuando se muetran 3 slides
    return (    
        <Swiper
            pagination={{
                clickable: true,
            }}
            breakpoints={{
                // when window width is >= 640px
                480: {
                    slidesPerView: 1,
                },
                640: {
                    slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            }}
            autoplay={{ delay: 2000 }}
            navigation
            loop
            className='swiper-container'>
            {Data.map((item, index) => (
                <SwiperSlide className={`${styles.slide}`} key={index}>
                    <InfoCard image={item.image} title={item.title} description={item.description} styless={item.style} link={item.link}/>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
