import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './Cards.module.scss'
import AccessPOlicy from 'assets/img/Research/access-policy.jpg'
import BecomeAffiliate from 'assets/img/Research/become-affiliate.jpg'
import Library from 'assets/img/Research/library.jpg'
import OptionCard from './OptionCard/OptionCard'
import { useResearchInfoPage } from 'modules/website/hooks/useResearchInfoPage'
import ApiURLs from 'services/BackendApi/BackendApiURLs'
import CardsLoading from './CardsLoading/CardsLoading'
import Alert from '../../Alert/Alert'

export default function Cards() {
    const { isLoading, isError, payload, error } = useResearchInfoPage();
    return (
        <>
            {isLoading && (<CardsLoading/>)}
            {!isLoading && isError && (<Alert variant="danger">{error.message}</Alert>)}
            {!isLoading && !isError && payload && (
                <Row className={`${styles.rowCards}`}>
                    {payload.data.map((option, index) => (
                        <Col lg={4} md={12} key={index}>
                            <OptionCard image={window.location.protocol + '//' +window.location.hostname+option.field_research_cover} title={option.title} link={"/research"+option.view_node.replace('/api/glifos','')}>
                                {option.field_description}
                            </OptionCard>
                        </Col>
                    ))}
                </Row>
            )}
        </>
    )
}
