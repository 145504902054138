import { GLIFOSPDFViewer } from '../GLIFOSPDFViewer/GLIFOSPdfViewer';
import LoadingViewer from '../LoadingViewer/LoadingViewer';

export default function GlifosDigitalArchiveViewer({ src }) {
	return (
		<div className='d-flex flex-column justify-content-start align-items-center w-100 h-100 p-0 mvh-50'>
			{!src && <LoadingViewer />}
			{src && <GLIFOSPDFViewer fileUrl={src} />}
		</div>
	);
}
