import React, { useState } from 'react'
import styles from './OrderBy.module.scss'
import Form from 'react-bootstrap/Form';
export default function OrderBy(props) {
    //TODO: Implementar funcionalidad de ordenar por fecha y por título
    const {order, setOrder} = props; 

    const handleOrderChange = (event) => {
        // Actualizamos el estado 'order' con el valor seleccionado
        setOrder(event.target.value);
    };

    return (
        <div className={`${styles.orderby}`}>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>Order by:</div>
                <Form.Select aria-label="Order by" onChange={handleOrderChange} value={order}>
                    <option value="sorttitle">Title</option>
                    <option value="isodate">Date</option>
                </Form.Select>
            </div>
        </div>
    )
}
