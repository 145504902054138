import React from 'react'
import { Card } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './OptionCard.module.scss'
import { Link } from 'react-router-dom';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
export default function OptionCard(props) {
    const { image, title, description, link } = props;
    const imageExists = image && image.length > 0;
    return (
        <Link to={link} className={`${styles.cardLink} link-underline-opacity-0`}>
            <Card className={`${styles.card}`}>
                {imageExists && (
                    <div className={`${styles.top}`}>
                        <Card.Img variant="top" src={image} 
                        image={image}
                        className={`${styles.imagess}`} />
                        <ChevronRight className={`${styles.icon}`} />
                    </div>
                )}
                <Card.Body className={`${styles.body}`}>
                    <Card.Title className={`${styles.title}`}>{title}</Card.Title>
                    <Card.Subtitle className={`${styles.description}`}>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                        {/* { description } */}
                    </Card.Subtitle>
                </Card.Body>
            </Card>
        </Link>
    )
}
