import React, { useState } from 'react'
import styles from './GlifosTimeMark.module.scss'
import { Button } from 'react-bootstrap';
import { CircleFill, DashSquareDotted, PlayFill, PlusSquareDotted } from 'react-bootstrap-icons';
import { useGlifosPlayerData, useGlifosPlayerUpdate } from '../../GlifosRichMediaProvider/GlifosRichMediaProvider';
import { secondsToTime, timeToSeconds } from 'modules/glifos-rich-media/helpers/TimeFunctions';

//TODO: cambiar 
function isOnRange(playerData, start, nextTime, collapsed, child) {
    if ((collapsed && child !== undefined) || child === undefined) {
        if (Math.trunc(playerData.currentPosition) >= timeToSeconds(start ? (start) : (nextTime))) {
            //if ((Math.trunc(playerData.currentPosition) - nextTime) > 0) {
            if (Math.trunc(playerData.currentPosition) < timeToSeconds(nextTime)) {
                return true;
            } else {
                return false;
            }
            /* }else{
                return false;
            } */
        } else {
            return false;
        }
    } else {
        return false
    }
}

export default function GlifosTimeMark(props) {
    const playerData = useGlifosPlayerData();
    const playerUpdate = useGlifosPlayerUpdate();
    const { tm, margin, nextTm, type, className } = props;
    var nextTime;
    var startTime;
    if (nextTm) {
        nextTime = nextTm.start
        startTime = tm.start
    } else {
        nextTime = secondsToTime(Math.trunc(playerData.videoLength))
        startTime = tm.start
    }
    const [collapsed, setCollapsed] = useState(true);
    const handleCollapsed = () => setCollapsed(!collapsed);
    return (
        <div>
            <Button className={`${styles.button} ` + (className ? className : '') + " " + ((isOnRange(playerData, startTime, nextTime, collapsed, tm.tm)) ? `${styles.active}` : '')} style={{ marginLeft: margin + 'px' }}
                onMouseEnter={() => {
                    /*  if (playerData.markTime === null) { */
                    playerUpdate.update({ markTime: { start: timeToSeconds(startTime), end: timeToSeconds(nextTime) } });
                    /* } else { playerUpdate.update({ markTime: null }); } */
                }}
                onMouseLeave={() => {
                    playerUpdate.update({ markTime: null });
                }}
            >
                <div className={`${styles.Icon}`} >
                    {type === 'transcript' ? (
                        <PlayFill className={`${styles.iconPlus}`} />
                    ) : (
                        (tm.tm.length > 0) ? (
                            collapsed ? (
                                <PlusSquareDotted className={`${styles.iconPlus}`} onClick={handleCollapsed} />
                            ) : (
                                <DashSquareDotted className={`${styles.iconPlus}`} onClick={handleCollapsed} />
                            )
                        ) : (
                            <CircleFill className={`${styles.icon}`} />
                        )
                    )}
                    {/* {tm.start} */}
                </div>
                <div className={`${styles.timeMark} gl-sync ` + (className ? className : '') + " " + ((isOnRange(playerData, startTime, nextTime, collapsed, tm.tm)) ? `${styles.active}` : '')}
                    onClick={() => playerUpdate.command({ type: "seek", value: startTime })}
                >
                    {tm.content}
                </div>
            </Button>
            {
                (tm.tm.length > 0) && (
                    <div style={{ height: collapsed && (0), overflow: collapsed && ('hidden') }}>
                        {
                            Array.isArray(tm.tm) ? (
                                tm.tm.map((item, index) => (
                                    <GlifosTimeMark tm={item} margin={margin + 20} key={index} nextTm={tm.tm[index + 1] === undefined ? (nextTm) : (tm.tm[index + 1])} type={type} />
                                ))
                            ) : (
                                <GlifosTimeMark tm={tm.tm} margin={margin + 20} type={type} nextTm={nextTm} />
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}
