import React from 'react';
import styles from './Header.module.scss';
import { Placeholder } from 'react-bootstrap';

export default function Header() {
	return (
		<header
			className={`d-flex flex-column gap-5 py-5 ${styles.heading}`}>
			<Placeholder as="p" animation="wave">
				<Placeholder xs={4} />
			</Placeholder>
		</header>
	);
}
