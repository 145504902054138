import React from 'react'
import styles from './SideBarAboutUsInfo.module.scss'
import SearchForm from './SearchForm/SearchForm'
import WhatWeDo from './WhatWeDo/WhatWeDo'
export default function SideBarAboutUsInfo() {
  return (
    <div className={`${styles.sideBar}`}>
        <SearchForm/>
        <WhatWeDo/>
    </div>
  )
}
