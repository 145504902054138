import React from 'react';
import { Container } from 'react-bootstrap';
import Copyright from './Copyright/Copyright';
import FooterLinks from './FooterLinks/FooterLinks';
import SocialMedia from './SocialMedia/SocialMedia';

import styles from './Footer.module.scss';

export default function Footer() {
	return (
		<footer className='bg-black py-5'>
			<Container>
				<div
					className={`${styles.footer} d-flex flex-column w-100 gap-5`}>
					<FooterLinks />
					<SocialMedia />
					<Copyright />
				</div>
			</Container>
		</footer>
	);
}
