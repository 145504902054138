import React from 'react'
import styles from './BrowseVideo.module.scss'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';

//TODO: ver porque no sirven las ultimas 4 (no estan en la lista de Allcategories)
const Options = [
    {
        title: 'Places',
        link: '/category/Places',
    },
    {
        title: 'Topics',
        link: '/category/Topics',
    },
    {
        title: 'Keywords',
        link: '/category/Keywords',
    },
    {
        title: 'Key Events',
        link: '/category/Key_Events',
    },
    {
        title: 'People',
        link: '/category/People',
    },
    {
        title: 'Country Time Periods',
        link: '/category/Country_Time_Periods',
    },
];

export default function BrowseVideo() {
    const navigate = useNavigate();
    return (
        <div className={`${styles.whatWeDo}`}>
            <div className={`${styles.title}`}>
                Browse Videos
            </div>
            <div className={`${styles.options}`}>
                {Options.map((option, index) => (
                   
                        <Link to={option.link}
                        className={`${styles.btn}`}
                        >
                            {option.title}
                            <ChevronRight className={`${styles.icon}`} />
                        </Link>
                   
                ))}
            </div>
        </div>
    )
}
