import React from 'react'
import styles from './AboutCarItem.module.scss'
export default function AboutCarItem(props) {
    const { title, children } = props
    return (
        <div className={`${styles.item}`}>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.content}`}>
                {children}
            </div>
            {/* <VisitwebsiteBtn /> */}
            {/* <Button className={`${styles.btn}`}>
                <ChevronRight className={`${styles.icon}`} />
                VISIT WEBSITE
            </Button> */}
        </div>
    )
}
