import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
//import brand from "assets/img/brand.png";
//import poweredBy from "assets/img/poweredByGLIFOS.png";
import { useDispatch } from "react-redux";
import { signin } from "services/redux/auth/authActions";
import FormRequiredValueWarning from "modules/website/components/FormRequiredValueWarning/FormRequiredValueWarning";
import FormRequiredValueIcon from "modules/website/components/FormRequiredValueIcon/FormRequiredValueIcon";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import "./Login.scss";

export default function Login(props) {
  const { className } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [viewPassword, setViewPassword] = useState(false);

  const onInputChange = (e) => {
    let newErrors = { formErrors };
    let newFormData = { ...formData };
    newFormData[e.target.name] = e.target.value;
    if (newErrors.hasOwnProperty(e.target.name)) delete newErrors[e.target.name];
    setFormErrors(newErrors);
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};
    setFormErrors(newErrors);
    if (formData.username.trim() === "") newErrors["username"] = true;
    if (formData.password.trim() === "") newErrors["password"] = true;
    setFormErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formMessage = await dispatch(signin(formData));
      setFormErrors({ __form_general_error: formMessage });
    }
  };

  if (auth?.refresh_token_expires_on && Object.keys(formErrors).length === 0) {
    return <Navigate to="/" replace />;
  }

  const changeViewPassword = () =>{
    setViewPassword(!viewPassword);
  }

  return (
    <Container className={`${className} ${styles.wrapper}`} fluid>
      <Row className={`${styles.row}`}>
        <Col xs={12} lg={4} className={`bg-white d-flex flex-column justify-content-between`}>
          <div>&nbsp;</div>
          <div className="px-0 px-lg-5">
            {/* <div className="d-flex flex-row justify-content-center mb-5">
              <img src={brand} alt="brand" className={`${styles.brand}`} />
            </div> */}
            <h1 className="mb-5">Iniciar sesión</h1>
            {formErrors.__form_general_error && <Alert variant="danger">{formErrors.__form_general_error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <FloatingLabel label="Usuario">
                <Form.Control type="text" placeholder="Usuario" name="username" onChange={onInputChange} autoFocus />
              </FloatingLabel>
              {formErrors.username && <FormRequiredValueWarning />}
              <div className="px-1 mb-3">
                <Form.Text muted>
                  <FormRequiredValueIcon /> Escriba su nombre de usuario en <span className="ff-HandelGothic">GLIFOS</span>
                </Form.Text>
              </div>
              <FloatingLabel label="Password" className="d-flex">
                <Form.Control type={viewPassword ? "text" : "password"} placeholder="Password" name="password" onChange={onInputChange} id="passwordControl"/>
                {formData?.password?.trim() !== "" && 
                (
                  <div style={{margin:"auto"}}>
                  {viewPassword ? 
                  <FaEyeSlash onClick={changeViewPassword} role="button" id="eyeBtn"/>
                  :
                  <FaEye onClick={changeViewPassword} role="button" id="eyeBtn"/>
                   }
                </div>
                )
                }
              </FloatingLabel>
              {formErrors.password && <FormRequiredValueWarning />}
              <div className="px-1 mb-3">
                <Form.Text muted>
                  <FormRequiredValueIcon /> Escriba la contraseña asignada a su usuario
                </Form.Text>
              </div>
              <Button variant="primary" type="submit" className={`mb-4 col-12 p-2 ${styles.button}`}>
                Iniciar sesión
              </Button>
            </Form>
            {/* <div>
              <Link to="/password-change" className={styles.link}>
                ¿Ha olvidado su contraseña?
              </Link>
            </div> */}
          </div>
          {/* <div className="p-2 d-flex justify-content-end">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img src={poweredBy} alt="poweredByGLIFOS" width="63" height="30" className="rounded" />
            </a>
          </div> */}
        </Col>
        <Col lg={8} className={`d-none d-lg-flex ${styles.background}`} />
      </Row>
    </Container>
  );
}
