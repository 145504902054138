import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './Back2NewsButton.module.scss'
import { useNavigate } from 'react-router-dom'
export default function Back2NewsButton() {
    const navigate = useNavigate();
    return (
        <Button className={`${styles.btn}`} onClick={()=>navigate('/news')}>
            <ChevronRight className={`${styles.icon}`} />
            BACK TO NEWS
        </Button>
    )
}
