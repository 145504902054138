import React, { useEffect, useState } from 'react'
import styles from './CreateSegment.module.scss'
import Start from 'assets/img/Start.png'
import End from 'assets/img/End.png'
import { Button } from 'react-bootstrap'
import { PlayFill, Share, ShareFill } from 'react-bootstrap-icons'
import { useGlifosPlayerData, useGlifosPlayerUpdate } from '../GlifosRichMediaProvider/GlifosRichMediaProvider'
import { secondsToTime, timeToSeconds } from 'modules/glifos-rich-media/helpers/TimeFunctions'
import ShareModal from './ShareModal/ShareModal'
export default function CreateSegment(props) {
    const { title } = props;

    const [start, setStart] = useState("00:00:00");
    const [end, setEnd] = useState("00:00:00");

    const playerData = useGlifosPlayerData();
    const playerUpdate = useGlifosPlayerUpdate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleStart = () => {
        setStart(secondsToTime(playerData.currentPosition))
    }

    const handleEnd = () => {
        setEnd(secondsToTime(playerData.currentPosition))
    }

    const handleStartChange = (e) => {
        setStart(e.target.value)
    }

    const handleEndChange = (e) => {
        setEnd(e.target.value)
    }

    useEffect(() => {
        //TODO: que cargue en la posicion de la url
        const urlParams = new URLSearchParams(window.location.search);
        const b = urlParams.get('b');
        const e = urlParams.get('e');
        if (b && e) {
            //console.log("b y e");
            setStart(secondsToTime(b));
            setEnd(secondsToTime(e));
            /* playerUpdate.command({ type: "seek", value: b })
            if (!playerData.playing) {
                playerUpdate.update({ playing: true });
            } */
        } else if (b && !e) {
            setStart(secondsToTime(b));
            //playerUpdate.command({ type: "seek", value: b })
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const b = urlParams.get('b');
        if (playerData.ready && b) {
            playerUpdate.command({ type: "seek", value: start })
            //playerUpdate.update({ playing: true });
        }
    }, [playerData.ready, start]);

    //useEffect(() => playerUpdate.command({ type: "seek", value: start }), [start]);

    useEffect(() => {
        //if the current position is greater than the end time, pause the video
        const urlParams = new URLSearchParams(window.location.search);
        const e = urlParams.get('e');
        if (end === secondsToTime(playerData.currentPosition) && playerData.playing && e) {
            playerUpdate.command({ type: "play" });
        }
    }, [playerData.currentPosition]);

    return (
        <div className={`${styles.options}`}>
            <div className={`${styles.mainPart}`}>
                <div className={`${styles.title}`}>
                    Mark Segment
                </div>
                <div className={`${styles.option}`}>
                    <div className={`${styles.btn}`} onClick={handleStart}>
                        Begin
                    </div>
                    <div className={`${styles.value}`}>
                        <input type="text" placeholder="00:00:00" value={start} onChange={handleStartChange} />
                    </div>

                </div>
                <div className={`${styles.option}`}>
                    <div className={`${styles.btn}`} onClick={handleEnd}>
                        {/*  <img src={End} alt="end" /> */}
                        End
                    </div>
                    <div className={`${styles.value}`}>
                        <input type="text" placeholder="00:00:00" value={end} onChange={handleEndChange} />
                    </div>

                </div>
            </div>
            <div className={`${styles.option}`}>
                {/* <div className={`${styles.value}`}>
                    Play
                </div> */}
                <Button className={`${styles.btn} ${styles.btnStyle}`}
                    onMouseEnter={() => {
                        if (playerData.markTime === null) {
                            playerUpdate.update({ markTime: { start: timeToSeconds(start), end: timeToSeconds(end) } });
                        } else { playerUpdate.update({ markTime: null }); }
                    }}
                    onMouseLeave={() => { playerUpdate.update({ markTime: null }); }}
                    onClick={() => {
                        playerUpdate.command({ type: "seek", value: start })
                        if (!playerData.playing) {
                            playerUpdate.command({ type: "play" })
                        }
                    }}
                    style={{ backgroundColor: "#bc1000" }}
                >
                    <PlayFill className={`${styles.icon}`} />
                </Button>
            </div>
            <div className={`${styles.option}`}>
                {/* <div className={`${styles.value}`}>
                    Share
                </div> */}
                <Button className={`${styles.btn} ${styles.btnStyle}`} onClick={handleShow}
                    style={{ backgroundColor: "#d55a00" }}
                >
                    <ShareFill className={`${styles.icon}`} />
                    <div>Share</div>
                </Button>
            </div>
            <ShareModal show={show} handleClose={handleClose} start={timeToSeconds(start)} end={timeToSeconds(end)} title={title}/>
        </div>
    )
}
