import React from 'react'
import styles from './WelcomeMsg.module.scss'
export default function WelcomeMsg() {
  return (
    <div className={`${styles.welcome}`}>
      <div className={`${styles.title}`}>
        <p>GENOCIDE</p>
        <p>ARCHIVE</p>
        <p className={`${styles.ofRwanda}`}>OF RWANDA</p>
      </div>
      <p className={`${styles.paragraph}`}>
        Welcome to the Genocide Archive of Rwanda website.
        Here you will find our digital collection of items related to the 1994 Genocide against the Tutsi in Rwanda, pre-genocide history and post-genocide reconstruction processes.
        Our materials are also preserved and accessible at our physical archive which is located at the Kigali Genocide Memorial.
      </p>
    </div>
  )
}
