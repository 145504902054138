import React from 'react'
import styles from './CardsLoading.module.scss'
import OptionCardLoading from '../OptionCard/OptionCardLoading/OptionCardLoading'
import { Col, Row } from 'react-bootstrap'
export default function CardsLoading() {
    return (
        <Row className={`${styles.rowCards}`}>
            <Col lg={4} md={12}>
                <OptionCardLoading/>
            </Col>
            <Col lg={4} md={12}>
                <OptionCardLoading/>
            </Col>
            <Col lg={4} md={12}>
                <OptionCardLoading/>
            </Col>
        </Row>
    )
}
