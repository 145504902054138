/* import React from 'react'
import './FeaturedView.scss'
import ApiURLs from 'services/BackendApi/BackendApiURLs'

export default function FeaturedView() {
    
    return (
        <div class="gridBody masonryBody col-sm-12">
            <a href="/Isidore_Kayumba">
                <div className="grid-cell masonry-cell col-lg-2c col-md-2c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/f172bf00a764bc7a3b7ce1452f294376")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Isidore Kayumba &nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="masonry-bigcell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Isidore Kayumba &nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders,&nbsp;Role of Religion&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Isidore Kayumba talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Oral_Testimony_of_François_Gakuba">
                <div className="grid-cell masonry-cell col-lg-5c col-md-5c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/95ea93fa37bef22c5dc28ec57b7c001c")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of François Gakuba&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="grid-cell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of François Gakuba&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Perpetrators&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Alphonse Sagashya talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period. &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/A_testimony_of_Peter_Rwanyindo">
                <div className="grid-cell masonry-cell col-lg-2c col-md-2c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/9b1ced13690979e2f09a0bb9206f9364")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Prof. Pierre Rwanyindo Ruzirabwoba&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="masonry-bigcell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Prof. Pierre Rwanyindo Ruzirabwoba&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Prof. Pierre Rwanyindo Ruzirabwoba talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period. &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/A_testimony_of_Antoine_Mugesera">
                <div className="grid-cell masonry-cell col-lg-2c col-md-2c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/77c36e66f1ad57859190cb1e2905ea0b")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Antoine Mugesera&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="masonry-bigcell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Antoine Mugesera&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders,&nbsp;Butare&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Antoine Mugesera talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Esdras_Mpyisi">
                <div className="grid-cell masonry-cell col-lg-5c col-md-5c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/4edc2f68b1bf0abe2f02c6c8dc1aa449")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Pastor Ezra Mpyisi&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="grid-cell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Pastor Ezra Mpyisi&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Ezra Mpyisi shares his experiences before the 1994 genocide against the Tutsi and back to colonisation. He gives an insight into the dynamics of Rwandan society during that period.&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Kwibuka21">
                <div className="grid-cell masonry-cell col-lg-2c col-md-2c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/db43492b0e4c4ddd915173ca8a8653a0")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">21st Commemoration of Genocide Against Tutsi.&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="masonry-bigcell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">21st Commemoration of Genocide Against Tutsi.&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Kwibuka Videos&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                This video features the 21st commemoration of the genocide against Tutsi in 1994 at Kigali Genocide Memorial, 7 April 2015.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Two_Hills">
                <div className="grid-cell masonry-cell col-lg-5c col-md-5c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/3664205c1bbf2f808b84a440502719a7")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">Two Hills&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="grid-cell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">Two Hills&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Documentaries&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                A documentary film on people from two neighboring hills coming together and uniting after the 1994 genocide against the Tutsi produced by Aegis Trust.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Kalisa_Rugano">
                <div className="grid-cell masonry-cell col-lg-2c col-md-2c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/7341663d907709b29db18ed022c8304b")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Kalisa Rugano&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="masonry-bigcell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Kalisa Rugano&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Kalisa Rugano talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Words_that_Kill">
                <div className="grid-cell masonry-cell col-lg-5c col-md-5c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/97cad7a34c6f6736bea7c7f0b2c5cc0d")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">Words That Kill&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="grid-cell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">Words That Kill&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Documentaries&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                A documentary film on propaganda and genocide denial that was produced by Aegis Trust.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
            <a href="/Padiri_Muzungu_Bernardin">
                <div className="grid-cell masonry-cell col-lg-5c col-md-5c col-sm-12">
                    <div className="grid-cell-shadow masonry-cell-shadow">&nbsp;</div>
                    <div className="masonry-cell-nohighlight">&nbsp;</div>
                    <div
                        className="grid-cell-image"
                        style={{ backgroundImage: 'url("'+ApiURLs.baseURL+'/v1/file/get/a220a614cb33a5ddd6d03a2ca612ea39")' }}
                    >
                        &nbsp;
                    </div>
                    <div className="grid-cell-info masonry-cell-info">
                        <div className="grid-cell-first-name masonry-cell-title">A testimony of Father Bernardin Muzungu&nbsp;</div>
                        &nbsp;
                    </div>
                    <div className="grid-cell-reverse-info grid-cell-inactive">
                        <span className="grid-call-reverse-body masonry-reverse-body">
                            <div className="masonry-cell-reverse-first-name">A testimony of Father Bernardin Muzungu&nbsp;</div>
                            <div className="masonry-cell-reverse-categories">Elders, Colonial History&nbsp;</div>
                            <div className="masonry-cell-reverse-description">
                                Father Bernardin Muzungu talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.&nbsp;&nbsp;
                            </div>
                            <div className="masonry-cell-reverse-content-type">VIDEO</div>
                        </span>
                    </div>
                </div>
            </a>
        </div>
    )
} */

import React, { useState } from 'react';
import ApiURLs from 'services/BackendApi/BackendApiURLs'
import './FeaturedView.scss'
export default function FeaturedView() {
    const [hoveredIndex, setHoveredIndex] = useState(-1); // Estado para controlar el índice hoverado

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const items = [
        {
            url: '/Isidore_Kayumba',
            imageURL: ApiURLs.baseURL + '/v1/file/get/f172bf00a764bc7a3b7ce1452f294376',
            title: 'A testimony of Isidore Kayumba',
            categories: 'Elders, Role of Religion',
            description: 'Isidore Kayumba talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.',
            gridClass: 'col-lg-2c col-md-2c col-sm-12'
            ,masNorybigcell: 'masonry-bigcell-reverse-info'
        },
        {
            url: '/Oral_Testimony_of_François_Gakuba',
            imageURL: ApiURLs.baseURL + '/v1/file/get/95ea93fa37bef22c5dc28ec57b7c001c',
            title: 'A testimony of François Gakuba',
            categories: 'Perpetrators',
            description: 'François Gakuba talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.'
            ,gridClass: 'col-lg-5c col-md-5c col-sm-12'
            ,masNorybigcell: 'grid-cell-reverse-info'

        },
        {
            url: '/A_testimony_of_Peter_Rwanyindo',
            imageURL: ApiURLs.baseURL + '/v1/file/get/9b1ced13690979e2f09a0bb9206f9364',
            title: 'A testimony of Prof. Pierre Rwanyindo Ruzirabwoba',
            categories: 'Elders',
            description: 'Prof. Pierre Rwanyindo Ruzirabwoba talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.'
            ,gridClass: 'col-lg-2c col-md-2c col-sm-12'
            ,masNorybigcell: 'masonry-bigcell-reverse-info'
        },
        {
            url: '/A_testimony_of_Antoine_Mugesera',
            imageURL: ApiURLs.baseURL + '/v1/file/get/77c36e66f1ad57859190cb1e2905ea0b',
            title: 'A testimony of Antoine Mugesera',
            categories: 'Elders, Butare',
            description: 'Antoine Mugesera talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.'
            ,gridClass: 'col-lg-2c col-md-2c col-sm-12'
            ,masNorybigcell: 'masonry-bigcell-reverse-info'
        },
        {
            url: '/Esdras_Mpyisi',
            imageURL: ApiURLs.baseURL + '/v1/file/get/4edc2f68b1bf0abe2f02c6c8dc1aa449',
            title: 'A testimony of Pastor Ezra Mpyisi',
            categories: 'Elders',
            description: 'Ezra Mpyisi shares his experiences before the 1994 genocide against the Tutsi and back to colonisation. He gives an insight into the dynamics of Rwandan society during that period.'
            ,gridClass: 'col-lg-5c col-md-5c col-sm-12'
            ,masNorybigcell: 'grid-cell-reverse-info'
        },
        {
            url: '/Kwibuka21',
            imageURL: ApiURLs.baseURL + '/v1/file/get/db43492b0e4c4ddd915173ca8a8653a0',
            title: '21st Commemoration of Genocide Against Tutsi',
            categories: 'Kwibuka Videos',
            description: 'This video features the 21st commemoration of the genocide against Tutsi in 1994 at Kigali Genocide Memorial, 7 April 2015.'
            ,gridClass: 'col-lg-2c col-md-2c col-sm-12'
            ,masNorybigcell: 'masonry-bigcell-reverse-info'
        },
        {
            url: '/Two_Hills',
            imageURL: ApiURLs.baseURL + '/v1/file/get/3664205c1bbf2f808b84a440502719a7',
            title: 'Two Hills',
            categories: 'Documentaries',
            description: 'A documentary film on people from two neighboring hills coming together and uniting after the 1994 genocide against the Tutsi produced by Aegis Trust.'
            ,gridClass: 'col-lg-5c col-md-5c col-sm-12'
            ,masNorybigcell: 'grid-cell-reverse-info'
        },
        {
            url: '/Kalisa_Rugano',
            imageURL: ApiURLs.baseURL + '/v1/file/get/7341663d907709b29db18ed022c8304b',
            title: 'A testimony of Kalisa Rugano',
            categories: 'Elders',
            description: 'Kalisa Rugano talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.'
            ,gridClass: 'col-lg-2c col-md-2c col-sm-12'
            ,masNorybigcell: 'masonry-bigcell-reverse-info'
        },
        {
            url: '/Words_that_Kill',
            imageURL: ApiURLs.baseURL + '/v1/file/get/97cad7a34c6f6736bea7c7f0b2c5cc0d',
            title: 'Words That Kill',
            categories: 'Documentaries',
            description: 'A documentary film on propaganda and genocide denial that was produced by Aegis Trust.'
            ,gridClass: 'col-lg-5c col-md-5c col-sm-12'
            ,masNorybigcell: 'grid-cell-reverse-info'
        },
        {
            url: '/Padiri_Muzungu_Bernardin',
            imageURL: ApiURLs.baseURL + '/v1/file/get/a220a614cb33a5ddd6d03a2ca612ea39',
            title: 'A testimony of Father Bernardin Muzungu',
            categories: 'Elders, Colonial History',
            description: 'Father Bernardin Muzungu talks about his experience before the 1994 genocide against the Tutsi and back to the colonisation period. He shares his understanding of the dynamics of the Rwandan society during that period.'
            ,gridClass: 'col-lg-5c col-md-5c col-sm-12'
            ,masNorybigcell: 'grid-cell-reverse-info'
        }
    ];

    return (
        <div className="gridBody masonryBody col-sm-12">
            {items.map((item, index) => (
                <a key={index} href={item.url}>
                    <div
                        className={`grid-cell masonry-cell ${item.gridClass} ${hoveredIndex === index ? 'hovered' : ''}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className={`grid-cell-shadow masonry-cell-shadow ${hoveredIndex !== index ? 'grid-cell-inactive' : ''}`}>&nbsp;</div>
                        <div className={`masonry-cell-nohighlight ${hoveredIndex !== index ? '' : 'grid-cell-inactive'}`}>&nbsp;</div>
                        <div
                            className="grid-cell-image"
                            style={{ backgroundImage: `url("${item.imageURL}")` }}
                        >
                            &nbsp;
                        </div>
                        <div className={`grid-cell-info masonry-cell-info ${hoveredIndex === index ? 'grid-cell-inactive' : ''}`}>
                            <div className="grid-cell-first-name masonry-cell-title">{item.title}&nbsp;</div>
                            &nbsp;
                        </div>
                        <div className={`${item.masNorybigcell} ${hoveredIndex !== index ? 'grid-cell-inactive' : ''}`}>
                            <span className="grid-call-reverse-body masonry-reverse-body">
                                <div className="masonry-cell-reverse-first-name">{item.title}&nbsp;</div>
                                <div className="masonry-cell-reverse-categories">{item.categories}&nbsp;</div>
                                <div className="masonry-cell-reverse-description">{item.description}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                <div className="masonry-cell-reverse-content-type">VIDEO</div>
                            </span>
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
}
