import React from 'react'
import Header from './Header/Header';
import { Placeholder } from 'react-bootstrap';
import styles from './ArticleLoading.module.scss';

export default function ArticleLoading() {
    return (
        <div className={`d-flex w-100 flex-column`}>
            <Header />
            <section
                className={`${styles.section}`}>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={1} size="sm" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={3} size="lg" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                    <Placeholder xs={5} size="sm" /> <Placeholder xs={6} size="sm" />
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={8} size="sm" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={4} size="lg" />
                </Placeholder>
                <Placeholder as="p" animation="wave">
                    <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                    <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                    <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                    <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                    <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" />
                </Placeholder>
            </section>
        </div>
    );
}