import React from 'react'
import SearchForm from './SearchForm/SearchForm'
import BrowseVideo from './BrowseVideo/BrowseVideo'
import SearchSquareForm from '../SearchSquareForm/SearchSquareForm'

export default function SideBarDocument() {
  return (
    <div>
        <SearchSquareForm
        title='Search Documents'
        description= 'Browse through this collection or use our advanced search to look for particular items of interest. Provide as many details such as dates, titles and key words for better results.'
        bkcolor='#d65a00'
        btncolor='#bc1000'
        type='glifos_digital_archive'
        />
        <BrowseVideo/>
    </div>
  )
}
