import React from 'react'
import { ChevronRight } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styles from './InfoCard.module.scss'
import { Link } from 'react-router-dom';

export default function InfoCard(props) {
    const { image, title, description, styless, link } = props;
    return (
        <Card style={{ backgroundColor: styless.backgroundColor }} className={`${styles.card}`}>
            <Card.Img variant="top" src={image} className={`${styles.imagess}`} />
            <Card.Body className={`${styles.body}`}>
                <Card.Title className={`${styles.title}`}>{title}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
                <Button variant="warning" className={`${styles.btn}`} style={{ backgroundColor: styless.colorBtn }}>
                    <Link to={link}
                    style={{textDecoration: "none",color:"white"}}
                    >
                        <ChevronRight className={`${styles.icon}`} />
                        READ MORE
                    </Link>
                </Button>
            </Card.Body>
        </Card>
    )
}
