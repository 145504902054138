import React from 'react'
import { Button, Form, FormGroup } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './MessageUsForm.module.scss'

export default function MessageUsForm() {
    return (
        <Form className={`${styles.form}`}>
            <div className={`${styles.title}`}>Message Us</div>
            <FormGroup>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" />
            </FormGroup>
            <FormGroup>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" />
            </FormGroup>
            <FormGroup>
                <Form.Label>Message</Form.Label>
                <textarea type="textarea" className={`${styles.MessageI} form-control`}/>
            </FormGroup>
            <Button variant="primary" type="submit" className={`${styles.btn}`}>
                <ChevronRight className={`${styles.icon}`} />
                SEND
            </Button>
        </Form>
    )
}
