import React from 'react'
import { Col } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './MenuOptions.module.scss'
import { Link, useNavigate } from 'react-router-dom'
export default function MenuOptions(props) {
    const { title, options, column, link } = props
    const navigate = useNavigate();
    return (
        <Col lg={column !== undefined ? 12 : 4} md={column !== undefined ? 12 : 4} s={column !== undefined ? 12 : 6} xs={column !== undefined ? 12 : 12}>
            <div className={`${styles.optionList}`}>
                <div className={link ? `${styles.title} ${styles.active}` : `${styles.title}`} onClick={() => navigate(link)}>
                    <ChevronRight className={`${styles.icon}`} />
                    <div className={`${styles.text}`}>
                        {title}
                    </div>
                </div>
                <div className={`${styles.options}`}>
                    <ul className={`${styles.list}`} style={{ columns: column !== undefined ? column : '1' }}>
                        {
                            options.map((option, index) => (
                                option.link ? (
                                    <li className={`${styles.op}`} key={index} /* onClick={option.newWindow ? (() => window.open(option.link, '_blank', 'noreferrer')) : (() => navigate(option.link))} */>
                                        {option.newWindow ? (
                                            <a href={option.link} target="_blank" rel="noopener noreferrer">
                                                {option.title}
                                            </a>
                                        ) : (
                                            <Link to={option.link}>
                                                {option.title}
                                            </Link>
                                        )}
                                    </li>
                                ) : (
                                    <li className={`${styles.nonop}`} key={index}>
                                        {option.title}
                                    </li>
                                )
                            ))
                        }
                    </ul>
                </div>
            </div>
        </Col>
    )
}
