import React from 'react'
import styles from './NewLoading.module.scss';
import { Placeholder } from 'react-bootstrap';
import ShareButton from '../ShareButton/ShareButton';
import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import SidebarNew from '../SideBarNew/SidebarNew';
import { NewTop } from 'routes/TopPart';
export default function NewLoading() {
    return (
        <SideBarLayout
            title={NewTop['title']}
            rightBar={<SidebarNew />}
            breadC={[
                {
                    title: 'Home',
                    path: '/',
                },
                {
                    title: 'News',
                    path: '/news',
                },
                {
                    title: '',
                    path: '',
                },
            ]}>
            <div className={`${styles.content}`}>
                <div className={`${styles.title}`}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </div>
                <div className={`${styles.date}`}>
                    <Placeholder animation="glow">
                        <Placeholder xs={3} />
                    </Placeholder>
                </div>
                <div className={`${styles.image}`}>
                    <Placeholder animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </div>
                <div className={`${styles.share}`}>
                    <ShareButton />
                </div>
                <div>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                        <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                        <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                        <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={1} size="sm" />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={3} size="lg" />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                        <Placeholder xs={5} size="sm" /> <Placeholder xs={6} size="sm" />
                        <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                        <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                        <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                        <Placeholder xs={3} size="sm" /> <Placeholder xs={8} size="sm" />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={4} size="lg" />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={8} size="sm" /> <Placeholder xs={3} size="sm" />
                        <Placeholder xs={7} size="sm" /> <Placeholder xs={4} size="sm" />
                        <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />
                        <Placeholder xs={6} size="sm" /> <Placeholder xs={5} size="sm" />
                        <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" /> <Placeholder xs={3} size="sm" />
                    </Placeholder>
                </div>
                <div className={`${styles.share}`}>
                    <ShareButton />
                </div>
            </div>
        </SideBarLayout>
    )
}
