import React from 'react'
import { Search } from 'react-bootstrap-icons'
import styles from './AdvancedSearch.module.scss'
import { Link } from 'react-router-dom'
export default function AdvancedSearch() {
  return (
    <div className={`${styles.container}`}>
      <a href="/search/?noncategory=true" className={`${styles.container}`}>
        <Search className={`${styles.icon}`} />
        <div className={`${styles.text}`}>ADVANCED SEARCH</div>
      </a>
    </div>
  )
}
