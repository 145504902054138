import React from 'react'
import { Button, Col } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './CollectionCarItem.module.scss'
import { Link } from 'react-router-dom';
export default function CollectionCarItem(props) {
    const { title, children, color, link } = props;
    return (
        <Col lg={3} md={6} s={12} className={`${styles.ColCar}`} style={{ backgroundColor: color }}>
            <Link to={link} 
            style={{textDecoration: "none",color:"white"}}
            >
                <div className={`${styles.Info}`}>
                    <div className={`${styles.title}`}>
                        {title}
                    </div>
                    <div className={`${styles.description}`}>
                        {children}
                    </div>
                    <Button className={`${styles.btn}`}>
                        <ChevronRight className={`${styles.icon}`} />
                        SEE MORE
                    </Button>
                </div>
            </Link>
        </Col>
    )
}
