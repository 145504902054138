import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import { Link, useNavigate } from 'react-router-dom'
import styles from './ImageCardOption.module.scss'

export default function ImageCardOption(props) {
    const { image, title, options, link } = props
    const navigate = useNavigate();
    return (
        <Link className={`${styles.card}`} to={link}>
            <div className={`${styles.image}`}>
                {/* <img src={`url:${image}`} alt="image" /> */}
                <img src={image} alt="image" />
            </div>
            <Button className={`${styles.btn}`}>
                <ChevronRight />
                {title}
            </Button>
            {
                options && (
                    <div className={`${styles.options}`}>
                        <ul className={`${styles.list}`}>
                            {
                                options.map((option, index) => (
                                    <li key={index}>
                                        {option}
                                        {/* <a href={link}>{option}</a> */}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </Link>
    )
}
