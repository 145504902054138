import React from 'react'
import styles from './NotRegisteredAd.module.scss'
import { Link } from 'react-router-dom'
export default function NotRegisteredAd(props) {
    const {alias} = props;
    return (
        <div className={`${styles.nopermission}`}>
            You don't have the necessary permissions to access this content. 
            <br/><br/>
            Please contact the site administrator for more information on how to access this content.
            <br/><br/>
            <Link to="/Contact_us">Contact us</Link>&nbsp; | &nbsp;<Link to="/register">Register</Link>&nbsp; | &nbsp;<Link to={`/login?returnTo=${alias}`}>Log in</Link>
        </div>
    )
}
