import React from 'react'
import styles from './BrowseVideo.module.scss'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const Options = [
    {
        title: 'Topics',
        link: '/category/Topics',
    },
    {
        title: 'Pre-Gencide',
        link: '/category/Pre-Genocide',
    },
    {
        title: 'Genocide',
        link: '/category/Genocide',
    },
    {
        title: 'Post-Genocide',
        link: '/category/Post-Genocide',
    },
    /* {
        title: 'People',
        link: '/',
    },
    {
        title: 'Country Time Periods',
        link: '/',
    }, */
];

export default function BrowseVideo() {
    const navigate = useNavigate();
    return (
        <div className={`${styles.whatWeDo}`}>
            <div className={`${styles.title}`}>
                Browse Videos
            </div>
            <div className={`${styles.options}`}>
                {Options.map((option, index) => (
                    <Button variant="primary" type="submit" className={`${styles.btn}`} key={index} onClick={() => navigate(option.link)}>
                        {option.title}
                        <ChevronRight className={`${styles.icon}`} />
                    </Button>
                ))}
            </div>
        </div>
    )
}
