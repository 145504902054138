import React from 'react'
import LearnMoreBtn from './LearnMoreBtn/LearnMoreBtn'
import styles from './MessageCard.module.scss'
export default function MessageCard(props) {
  const { title, children, variant, link } = props
  return (
    <div className={`${styles.card}`}>
      <div className={`${styles.title}`}>
        {title}
      </div>
      <p className={`${styles.paragraph}`}>
        {children}
      </p>
      <LearnMoreBtn variant={variant} link={link}/>
    </div>
  )
}
