import React, { useEffect, useState, useContext } from "react";
//import { FilterContext } from "contextos/FilterContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Pagination.module.scss";

const Pagination = (props) => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState("");
  const [result_header, setResult_header] = useState({});
  //const { navigate } = useContext(FilterContext);
  const navigate = useNavigate();

  useEffect(() => {
    setResult_header(props.result_header);
  }, [props.result_header]);

  const paramsToObject = (params) => {
    if (params === "") return "";
    const urlParams = new URLSearchParams(params);
    const paramsObj = Object.fromEntries(urlParams);
    paramsObj.page && delete paramsObj.page;
    const paramsResult = Object.entries(paramsObj || {})
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
    return paramsResult;
  };
  useEffect(() => {
    // console.log(paramsToObject(searchParams));
    setParams(paramsToObject(searchParams));
    // return () => {
    //   setParams("");
    // };
  }, [searchParams]);

  const cambiarPaginacion = (e, params) => {
    e.preventDefault();
    // console.log(params);
    navigate(`${params}`);
    // console.log(paramsToObject(searchParams));
    // window.history.replaceState(null, null, params);
    // handleSubmit(null, true);
  };

  return (
    <div className={`d-flex justify-content-${props.justify} p-0`}>
      {/* {result_header?.total_results !== "NaN" &&
        result_header?.total_results > 0 && (
          <div className="d-flex flex-column justify-content-center ms-2">
            {result_header?.total_results !== "NaN"
              ? result_header?.total_results_parser
              : "0"}{" "}
            results
          </div>
        )} */}
      {result_header?.total_results !== "NaN" &&
        result_header?.total_results > 0 && (
          <nav>
            <ul className={`pagination m-0 ${styles.pagination}`}>
              {result_header?.pagination?.previous_page &&
                result_header?.pagination?.previous_page !== "null" && (
                  <>
                    <li className="page-item">
                      <div
                        className="page-link"
                        aria-label="First"
                        onClick={(e) => {
                          cambiarPaginacion(
                            e,
                            `?${params}&page=1`
                          );
                        }}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </div>
                    </li>

                    <li className="page-item">
                      <div
                        className="page-link"
                        aria-label="Previous"
                        onClick={(e) => {
                          cambiarPaginacion(
                            e,
                            `?${params}&page=${result_header?.pagination?.previous_page}`
                          );
                        }}
                      >
                        <span aria-hidden="true">	&lt;</span>
                      </div>
                    </li>
                  </>
                )}
              {result_header?.pagination?.page_list &&
                Array.isArray(result_header?.pagination?.page_list) &&
                result_header?.pagination?.page_list?.map((page, i) => (
                  <li
                    className={`page-item ${page?.active === "false" ? `active ${styles.active}` : ""
                      }`}
                    key={i}
                  >
                    <div
                      className="page-link"
                      key={i}
                      disabled
                      onClick={(e) => {
                        cambiarPaginacion(
                          e,
                          page?.active === "false"
                            ? "#"
                            : `?${params}&page=${page?.page_number}`
                        );
                      }}
                    >
                      {page?.page_number}
                    </div>
                  </li>
                ))}
              {result_header?.pagination?.next_page !== "null" && (
                <>
                  <li className="page-item">
                    <div
                      className="page-link"
                      onClick={(e) => {
                        cambiarPaginacion(
                          e,
                          `?${params}&page=${result_header?.pagination?.next_page}`
                        );
                      }}
                      aria-label="Next"
                    >
                      <span aria-hidden="true">&gt;</span>
                    </div>
                  </li>

                  <li className="page-item">
                    <div
                      className="page-link"
                      aria-label="Last"
                      onClick={(e) => {
                        cambiarPaginacion(
                          e,
                          `?${params}&page=${result_header?.pagination?.total_pages}`
                        );
                      }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </nav>
        )}
    </div>
  );
};

export default Pagination;
